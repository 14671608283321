import { memo, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import CheckCircleFilledIcon from 'src/_shared/components/_icons/CheckCircleFilledIcon'
import CheckCircleOutlinedGradientIcon from 'src/_shared/components/_icons/CheckCircleOutlinedGradientIcon'
import ErrorFilledIcon from 'src/_shared/components/_icons/ErrorFilledIcon'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'

interface ExtendSuccessModalProps {
	variant: 'success'
}

interface ExtendErrorModalProps {
	variant: 'error'
	errorTitle?: ReactNode
	errorDescription?: ReactNode
}

export type ExtendModalProps = {
	open?: boolean
} & (ExtendSuccessModalProps | ExtendErrorModalProps)

const ExtendModal = ({ open: isExtendModalOpen, ...props }: ExtendModalProps): JSX.Element => {
	return (
		<Modal open={isExtendModalOpen}>
			<ModalCard className="flex flex-col items-center">
				{props.variant === 'success' ? (
					<>
						{((): JSX.Element => {
							switch (BRAND) {
								case Brand.Evme:
									return (
										<CheckCircleOutlinedGradientIcon
											className="mb-4 h-[60px] w-[60px]"
											gradientFrom="#CBFF31"
											gradientTo="#80FFCA"
										/>
									)
								default:
									return <CheckCircleFilledIcon className="mb-4 h-12 w-12 text-success-400" />
							}
						})()}
						<h1
							data-testid="res-cm-header-success-remove-payment-method"
							className="mb-2 text-center text-modal-typography-primary"
						>
							<FormattedMessage
								id="ExtendModal.ModalTitleExtendSuccessful"
								defaultMessage="Your Booking Has Been Extended"
							/>
						</h1>
					</>
				) : (
					<>
						<ErrorFilledIcon className="mb-4 h-12 w-12 text-error-300" />
						<h1
							data-testid="res-cm-header-error"
							className="mb-2 text-center text-modal-typography-primary"
						>
							{props.errorTitle}
						</h1>
						<p
							data-testid="res-cm-description-error"
							className="body-2-light text-center text-modal-typography-primary"
						>
							{props.errorDescription}
						</p>
					</>
				)}
			</ModalCard>
		</Modal>
	)
}

const MemoisedExtendModal = memo(ExtendModal)

export default MemoisedExtendModal
