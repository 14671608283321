import { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { NoticeProps } from 'src/_shared/components/Notice'
import PaymentMethodsSelectionList from 'src/_shared/components/PaymentMethodsSelectionList'
import { OmniError } from 'src/_shared/enums/omni'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'
import { useStartChargingSessionMutation } from 'src/_shared/mutations/sessions'
import { PaymentMethod } from 'src/_shared/types/payments'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import Footer from './Footer'
import TermsAndConditionsBottomRender from './TermsAndConditionsBottomRender'

type PaymentMethodsViewProps = ChargerScreenCommonViewProps

const PaymentMethodsView = ({
	routeParams,
	onNext: handleNext,
	updateView
}: PaymentMethodsViewProps): JSX.Element => {
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod | null>(null)

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null)

	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession, resetChargerSessionDetailsQueries } =
		useChargerSessionDetails(routeParams)

	const {
		isPending: isStartChargingSessionPending,
		isSuccess: isStartChargingSessionSuccess,
		mutateAsync: startChargingSession
	} = useStartChargingSessionMutation()

	const isStartChargingSessionLoading =
		isStartChargingSessionPending || isStartChargingSessionSuccess

	const handleNextClick = useCallback((): void => {
		if (selectedPaymentMethod) {
			void startChargingSession(
				{
					...routeParams,
					paymentMethodUid: selectedPaymentMethod._id ?? ''
				},
				{
					onError: ({ response }): void => {
						const errorViewProps = ((): NoticeProps => {
							const errorCode = response?.data.error?.code
							switch (errorCode) {
								case OmniError.PaymentInsufficientFunds:
									return {
										type: 'warning',
										header: intl.formatMessage({
											id: 'PaymentMethodsView.TitleInsufficientFunds',
											defaultMessage: 'Your Account Has Insufficient Balance'
										})
									}
								default:
									return {
										type: 'error',
										header: intl.formatMessage({
											id: 'PaymentMethodsView.TitleStartChargeFailure',
											defaultMessage: 'Failed to Start Charge'
										}),
										description: intl.formatMessage({
											id: 'PaymentMethodsView.DescriptionStartChargeFailure',
											defaultMessage: 'Please try again later'
										})
									}
							}
						})()
						updateView?.(ChargerScreenViewItemKey.ErrorView, errorViewProps)
					},
					onSuccess: (): void => {
						const handleStartChargingSuccess = async (): Promise<void> => {
							await resetChargerSessionDetailsQueries()
							handleNext?.()
						}
						void handleStartChargingSuccess()
					}
				}
			)
		}
	}, [
		intl,
		routeParams,
		selectedPaymentMethod,
		handleNext,
		resetChargerSessionDetailsQueries,
		startChargingSession,
		updateView
	])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.PaymentMethodsView
	})

	return (
		<>
			<PaymentMethodsSelectionList
				dataTestIdPrefix="cs-pmv"
				defaultPaymentMethod={defaultPaymentMethod}
				selectedPaymentMethod={selectedPaymentMethod}
				isAddOrReplacePaymentMethodDisabled={isStartChargingSessionLoading}
				onDefaultPaymentMethodChange={setDefaultPaymentMethod}
				onSelectedPaymentMethodChange={setSelectedPaymentMethod}
			/>
			<Footer
				className="space-y-6"
				buttonProps={{
					children: intl.formatMessage({
						id: 'PaymentMethodsView.ButtonTextNext',
						defaultMessage: 'Next'
					}),
					className: 'w-full',
					disabled: isStartChargingSessionLoading || !selectedPaymentMethod,
					loading: isStartChargingSessionLoading,
					onClick: handleNextClick
				}}
				bottomRender={
					<TermsAndConditionsBottomRender
						prefixRender={
							<FormattedMessage
								id="PaymentMethodsView.FooterTextTermsAndConditionsPrefix"
								defaultMessage='By clicking "Next", you agree to our '
							/>
						}
					/>
				}
			/>
		</>
	)
}

export default PaymentMethodsView
