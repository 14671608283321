import { QueryStatus, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { APP_MODE } from 'src/_shared/constants/env'
import { AppMode } from 'src/_shared/enums/env'
import { OmniSessionStatus } from 'src/_shared/enums/omni'
import {
	LocationsQueryKey,
	ROOT_LOCATIONS_QUERY_KEY,
	useLocationEvseConnectorCurrentSessionQuery
} from 'src/_shared/queries/locations'
import { useSessionTransientQuery } from 'src/_shared/queries/sessions'
import {
	ROOT_USER_QUERY_KEY,
	UserQueryKey,
	useUserSessionLatestQuery
} from 'src/_shared/queries/user'
import { OmniSession } from 'src/_shared/types/omni'

import { ChargerScreenRouteParams } from '../types'

// 10 Seconds
const DEFAULT_REFETCH_INTERVAL = 10000

type UseChargerSessionDetailsArgs = ChargerScreenRouteParams

interface ChargerSessionDetails {
	/**
	 * If `isUserActiveChargingSession` is `true`, then this `session` is the active charging session on the charger.
	 */
	session: OmniSession | null
	/**
	 * Returns `true` if the charging session belongs to the user.
	 */
	isUserChargingSession: boolean
	/**
	 * Returns `true` if the charging session is active and belongs to the user.
	 */
	isUserActiveChargingSession: boolean
	/**
	 * Returns `true` the user has an active charging session on a different charger.
	 */
	isUserActiveChargingSessionElsewhere: boolean
	/**
	 * Returns `true` if the user has a reservation on the charger.
	 */
	isUserReservedChargingSession: boolean
	/**
	 * Returns `true` if the user has a reserved charging session on a different charger.
	 */
	isUserReservedChargingSessionElsewhere: boolean
	/**
	 * Overall status for the queries made in the hook.
	 */
	chargerSessionDetailsQueryStatus: QueryStatus
	/**
	 * Resets all involved queries to their initial state.
	 * This should be called after a charging session has been started successfully.
	 * @see https://tanstack.com/query/latest/docs/reference/QueryClient/#queryclientresetqueries
	 */
	resetChargerSessionDetailsQueries: () => Promise<void>
}

/**
 * Queries for the charger's session and the most up-to-date latest user session.
 * @param {UseChargerSessionDetailsArgs} UseChargerSessionDetailsArgs `{ locationUid, evseUid, connectorUid }`
 * @returns {ChargerSessionDetails} The `session` and other related query information.
 */
export const useChargerSessionDetails = ({
	locationUid,
	evseUid,
	connectorUid
}: UseChargerSessionDetailsArgs): ChargerSessionDetails => {
	const queryClient = useQueryClient()

	const isTransientFlow = APP_MODE === AppMode.Transient

	const userSessionLatestQuery = useUserSessionLatestQuery(
		{
			isRealTime: true
		},
		{
			enabled: !isTransientFlow,
			refetchInterval: DEFAULT_REFETCH_INTERVAL,
			refetchIntervalInBackground: true,
			refetchOnMount: 'always'
		}
	)

	const sessionTransientQuery = useSessionTransientQuery({
		enabled: isTransientFlow,
		refetchInterval: DEFAULT_REFETCH_INTERVAL,
		refetchIntervalInBackground: true,
		refetchOnMount: 'always'
	})

	const { data: session = null, status: sessionQueryStatus } = isTransientFlow
		? sessionTransientQuery
		: userSessionLatestQuery

	const { data: chargerCurrentSession = null, status: chargerCurrentSessionQueryStatus } =
		useLocationEvseConnectorCurrentSessionQuery(
			{
				locationUid,
				evseUid,
				connectorUid
			},
			{
				refetchInterval: DEFAULT_REFETCH_INTERVAL,
				refetchIntervalInBackground: true,
				refetchOnMount: 'always',
				enabled: sessionQueryStatus === 'success'
			}
		)

	const isUserChargingSession = useMemo((): boolean => {
		if (!!session?._id && !!chargerCurrentSession?._id) {
			return session._id === chargerCurrentSession._id
		}
		return false
	}, [chargerCurrentSession?._id, session?._id])

	const isUserActiveChargingSession = useMemo((): boolean => {
		return isUserChargingSession && session?.status === OmniSessionStatus.Active
	}, [isUserChargingSession, session?.status])

	const isUserActiveChargingSessionElsewhere = useMemo((): boolean => {
		return (
			!isTransientFlow && !isUserChargingSession && session?.status === OmniSessionStatus.Active
		)
	}, [isTransientFlow, isUserChargingSession, session?.status])

	const isUserReservedChargingSession = useMemo((): boolean => {
		// FUTURE TODO: Check for this
		return false
	}, [])

	const isUserReservedChargingSessionElsewhere = useMemo((): boolean => {
		// FUTURE TODO: Check for this
		return false
	}, [])

	const chargerSessionDetailsQueryStatus = useMemo((): QueryStatus => {
		const statuses = [chargerCurrentSessionQueryStatus, sessionQueryStatus]
		if (
			statuses.every((status): boolean => {
				return status === 'success'
			})
		) {
			return 'success'
		} else if (statuses.includes('error')) {
			return 'error'
		}
		return 'pending'
	}, [chargerCurrentSessionQueryStatus, sessionQueryStatus])

	const resetChargerSessionDetailsQueries = useCallback(async (): Promise<void> => {
		console.debug('[useChargerSessionDetails] Resetting Charger Session Details Queries')
		await queryClient.resetQueries({
			queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserSessionLatest]
		})
		await queryClient.resetQueries({
			queryKey: [
				ROOT_LOCATIONS_QUERY_KEY,
				LocationsQueryKey.LocationEvseConnectorCurrentSession,
				{
					locationUid,
					evseUid,
					connectorUid
				}
			]
		})
	}, [queryClient, locationUid, evseUid, connectorUid])

	return {
		session,
		isUserChargingSession,
		isUserActiveChargingSession,
		isUserActiveChargingSessionElsewhere,
		isUserReservedChargingSession,
		isUserReservedChargingSessionElsewhere,
		chargerSessionDetailsQueryStatus,
		resetChargerSessionDetailsQueries
	}
}
