import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import Notice from 'src/_shared/components/Notice'
import Spinner from 'src/_shared/components/Spinner'
import { OmniReservationType } from 'src/_shared/enums/omni'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'

import { ReservationChargerScreenViewItemKey } from '../enums'
import { ReservationChargerScreenCommonViewProps } from '../types'

type PreReservationViewProps = Omit<ReservationChargerScreenCommonViewProps, 'onNext'>

const PreReservationView = ({ routeParams, updateView }: PreReservationViewProps): JSX.Element => {
	const intl = useIntl()

	const { locationQueryStatus, locationError, ...chargerDetails } = useChargerDetails({
		...routeParams,
		enableRefetch: false
	})

	// FUTURE TODO: Check for user reservation and latest session
	const hasReservedChargingSession = false

	const hasActiveChargingSession = false

	/**
	 * Move to the Timebelt View if the charger supports Timebelt Reservation.
	 */
	useEffect((): void => {
		if (
			locationQueryStatus === 'success' &&
			chargerDetails.isReservationCapable &&
			chargerDetails.reservationType === OmniReservationType.Timebelt
		) {
			updateView(ReservationChargerScreenViewItemKey.ReservationView)
		}
	}, [chargerDetails, locationQueryStatus, updateView])

	// Error Displays
	// Note: Do not use `updateView` for these errors because the Back Button should
	// return the user to the last screen prior to the Reservation Charger Screen.
	if (locationError) {
		const statusCode = locationError.response?.status
		return (
			<Notice
				type="error"
				header={
					statusCode === 404
						? intl.formatMessage({
								id: 'PreReservationView.TitleChargerNotFound',
								defaultMessage: 'Charger Not Found'
							})
						: intl.formatMessage({
								id: 'PreReservationView.TitleOopsFailure',
								defaultMessage: 'Oops! Something Went Wrong'
							})
				}
			/>
		)
	}
	// Reservation Not Supported
	else if (
		locationQueryStatus === 'success' &&
		(!chargerDetails.isReservationCapable || !chargerDetails.reservationType)
	) {
		return (
			<Notice
				type="error"
				header={intl.formatMessage({
					id: 'PreReservationView.TitleChargerNotReservationCapable',
					defaultMessage: 'Charger Is Not Reservation Capable'
				})}
				description={intl.formatMessage({
					id: 'PreReservationView.DescriptionTryDifferentCharger',
					defaultMessage: 'Please try a different charger'
				})}
			/>
		)
	}
	// User Has Reservation
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	else if (hasReservedChargingSession) {
		return (
			<Notice
				type="error"
				header={intl.formatMessage({
					id: 'PreReservationView.TitleBookingNotAllowed',
					defaultMessage: 'Booking Not Allowed'
				})}
				description={intl.formatMessage({
					id: 'PreReservationView.DescriptionExistingBooking',
					defaultMessage:
						'You have an existing booking. Please cancel the booking before making a new one.'
				})}
			/>
		)
	}
	// User Has Ongoing Session
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	else if (hasActiveChargingSession) {
		return (
			<Notice
				type="error"
				header={intl.formatMessage({
					id: 'PreReservationView.TitleBookingNotAllowed',
					defaultMessage: 'Booking Not Allowed'
				})}
				description={intl.formatMessage({
					id: 'PreReservationView.DescriptionOngoingSession',
					defaultMessage:
						'You have an ongoing charge session. Please end the ongoing session before making a new booking.'
				})}
			/>
		)
	}
	return (
		<div className="flex flex-grow flex-col items-center justify-center pb-6">
			<Spinner />
		</div>
	)
}

export default PreReservationView
