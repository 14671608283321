import { useRouterNavigate } from 'src/App/router/hooks'
import { ScreenRoutePath } from 'src/App/router/types'
import useLiveSession from 'src/_shared/hooks/useLiveSession'
import { formatDataTestId } from 'src/_shared/utils/string'

import LightningBoltFilledIcon from '../_icons/LightningBoltFilledIcon'

interface LiveSessionProps {
	className?: string
	dataTestIdPrefix?: string
}

const LiveSession = ({
	className,
	dataTestIdPrefix = ''
}: LiveSessionProps): JSX.Element | null => {
	const navigate = useRouterNavigate()

	const { chargerDetails } = useLiveSession()

	const {
		cpoEntityCode = '',
		locationUid = '',
		evseUid = '',
		connectorUid = ''
	} = chargerDetails ?? {}

	const handleViewChargingSessionClick = (): void => {
		navigate([ScreenRoutePath.Charger, cpoEntityCode, locationUid, evseUid, connectorUid])
	}

	if (!chargerDetails) {
		return null
	}
	return (
		<div className={className}>
			<button
				className="animate-fade-in btn primary w-full justify-between rounded-full px-4"
				data-testid={formatDataTestId([dataTestIdPrefix, 'btn-live-session'])}
				onClick={handleViewChargingSessionClick}
			>
				{/* Live Session Title and Lightning Bolt */}
				<div className="flex items-center space-x-2">
					<LightningBoltFilledIcon className="h-4 w-4 text-button-primary-content" />
					<span className="body-1-semibold text-button-primary-content">Live Session</span>
				</div>
				{/* Live Session Text */}
				<span
					data-testid={formatDataTestId([dataTestIdPrefix, 'text-live-session'])}
					className="body-1-normal mr-2 text-button-primary-content"
				>
					View Charging Session
				</span>
			</button>
		</div>
	)
}

export default LiveSession
