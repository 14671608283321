import dayjs from 'dayjs'
import { memo, useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TIME_FORMAT } from 'src/_shared/constants/env'
import { OmniCountryCurrencyCode } from 'src/_shared/enums/omni'
import useTimeFormatter from 'src/_shared/hooks/useTimeFormatter'
import { OmniConnector, OmniEvse, OmniLocation } from 'src/_shared/types/omni'
import { shareLocationUrl } from 'src/_shared/utils/redirect'
import { formatCurrencyValue } from 'src/_shared/utils/string'

import Button from '../Button'
import CalendarIcon from '../_icons/CalendarIcon'
import DirectionsIcon from '../_icons/DirectionsIcon'
import CheckInTimeCountdown from './components/CheckInTimeCountdown'
import LabelValueList from './components/LabelValueList'
import { LabelValueListItemProps } from './components/LabelValueListItem'

// FUTURE TODO: Probably will adjust the prop typings for this component upon API integration
interface ReservationData {
	/**
	 * FUTURE TODO: Determine when and how to show/hide the countdown timer after a successful booking.
	 */
	bookingId?: string
	/**
	 * In Epoch Unix Timestamp (milliseconds)
	 */
	startTime: number
	/**
	 * In Epoch Unix Timestamp (milliseconds)
	 */
	endTime: number
	/**
	 * In Minutes
	 */
	duration: number
}

interface ReservationDetailsProps {
	reservationData: ReservationData
	location: OmniLocation
	evse: OmniEvse
	connector: OmniConnector
}

const ReservationDetails = ({
	reservationData,
	location,
	evse,
	connector
}: ReservationDetailsProps): JSX.Element => {
	const intl = useIntl()

	const { timeFormatMap, formatTimeToString } = useTimeFormatter()

	const { bookingId, startTime, endTime, duration: durationInMins } = reservationData

	const startDate = dayjs(startTime)

	const endDate = dayjs(endTime)

	const duration = useMemo((): string => {
		const dayjsDuration = dayjs.duration(durationInMins, 'minutes')
		const durationValue = formatTimeToString(
			{
				hours: dayjsDuration.get('hours'),
				minutes: dayjsDuration.get('minutes'),
				seconds: dayjsDuration.get('seconds')
			},
			{ isAbbreviated: false, isCapitalised: true }
		)
		return durationValue
	}, [durationInMins, formatTimeToString])

	const chargerDetailsLabelValueList = useMemo((): LabelValueListItemProps[] => {
		const bookingIdLabelValue: LabelValueListItemProps | null = bookingId
			? {
					label: intl.formatMessage({
						id: 'ReservationDetails.LabelBookingId',
						defaultMessage: 'Booking ID'
					}),
					value: bookingId
				}
			: null

		const locationLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage({
				id: 'ReservationDetails.LabelLocation',
				defaultMessage: 'Location'
			}),
			value: location.name
		}

		const evseIdLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage({
				id: 'ReservationDetails.LabelEvseId',
				defaultMessage: 'EVSE ID'
			}),
			// Display the physical reference or fallback to ID if it is not available.
			value: ((): string => {
				if (evse.physical_reference) {
					return evse.physical_reference
				} else if (evse.evse_id) {
					return evse.evse_id
				}
				return '-'
			})()
		}

		const connectorIdLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage({
				id: 'ReservationDetails.LabelConnectorId',
				defaultMessage: 'Connector ID'
			}),
			value: ((): string => {
				if (connector.qr_identifier) {
					return connector.qr_identifier
				} else if (connector.connector_id) {
					return connector.connector_id
				}
				return '-'
			})()
		}

		return [
			bookingIdLabelValue,
			locationLabelValue,
			evseIdLabelValue,
			connectorIdLabelValue
		].filter(Boolean) as LabelValueListItemProps[]
	}, [bookingId, connector, evse, location, intl])

	// FUTURE TODO: Get values from data
	const feeDetailsLabelValueList = useMemo((): LabelValueListItemProps[] => {
		const unitHour = timeFormatMap.hoursFormat.abbreviated

		const bookingFeeValue = formatCurrencyValue(5, OmniCountryCurrencyCode.Thailand, 2)

		const bookingFeeLabelValue = {
			label: intl.formatMessage({
				id: 'ReservationDetails.LabelBookingFee',
				defaultMessage: 'Booking Fee'
			}),
			value: `${bookingFeeValue}/${unitHour}`
		}

		const extensionFeeValue = formatCurrencyValue(5, OmniCountryCurrencyCode.Thailand, 2)

		const extensionFeeLabelValue = {
			label: intl.formatMessage({
				id: 'ReservationDetails.LabelExtensionFee',
				defaultMessage: 'Extension Fee'
			}),
			value: `${extensionFeeValue}/${unitHour}`
		}

		const overtimeFeeValue = formatCurrencyValue(100, OmniCountryCurrencyCode.Thailand, 2)

		const overtimeFeeLabelValue = {
			label: intl.formatMessage({
				id: 'ReservationDetails.LabelOvertimeFee',
				defaultMessage: 'Overtime Fee'
			}),
			value: `${overtimeFeeValue}/${unitHour}`
		}

		return [bookingFeeLabelValue, extensionFeeLabelValue, overtimeFeeLabelValue]
	}, [intl, timeFormatMap])

	// FUTURE TODO: Get values from data
	const chargingFeeLabelValueList = useMemo((): LabelValueListItemProps[] => {
		const chargingFeeLabelValue: LabelValueListItemProps = {
			label: [
				dayjs().startOf('day').format(TIME_FORMAT),
				'-',
				dayjs().endOf('day').format(TIME_FORMAT)
			].join(' '),
			value: `${formatCurrencyValue(8, OmniCountryCurrencyCode.Thailand)}/kWh`
		}

		return [chargingFeeLabelValue]
	}, [])

	const handleDirectionsClick = useCallback((): void => {
		shareLocationUrl(location)
	}, [location])

	return (
		<div className="space-y-4">
			{/* CPO Logo */}
			<div className="mt-4 flex justify-center">
				<img src={location.operator?.logo} className="h-10 min-w-10 max-w-36" />
			</div>
			{/* Check-In Time Countdown */}
			{!!bookingId && <CheckInTimeCountdown startTime={startTime} />}
			{/* Booking Time */}
			<div className="flex space-x-2.5 rounded-lg bg-grayscale-200 p-4">
				<div>
					<CalendarIcon className="mt-0.5 h-5 w-auto text-typography-secondary" />
				</div>
				<div>
					<div className="space-x-2.5">
						<span
							data-testid="rd-text-start-time-date"
							className="body-2-semibold text-typography-primary"
						>
							{startDate.format('dddd, MMM DD')}
						</span>
						<span className="text-typography-tertiary">&#x2022;</span>
						<span
							data-testid="rd-text-start-to-end-time"
							className="body-2-semibold text-typography-primary"
						>
							{startDate.format(TIME_FORMAT)} - {endDate.format(TIME_FORMAT)}
						</span>
					</div>
					<span className="caption-3-normal text-typography-tertiary">
						<FormattedMessage id="ReservationDetails.LabelDuration" defaultMessage="Duration:" />{' '}
						{duration}
					</span>
				</div>
			</div>
			{/* Charger Details */}
			<LabelValueList
				title={intl.formatMessage({
					id: 'ReservationDetails.TitleChargerDetails',
					defaultMessage: 'Charger Details'
				})}
				labelValueList={chargerDetailsLabelValueList}
				bottomRender={
					<Button className="mt-4 w-full bg-white" variant="ghost" onClick={handleDirectionsClick}>
						<DirectionsIcon className="mr-1 h-6 w-auto" />
						<FormattedMessage
							id="ReservationDetails.ButtonTextDirections"
							defaultMessage="Directions"
						/>
					</Button>
				}
			/>
			{/* Fee Details */}
			<LabelValueList
				title={intl.formatMessage({
					id: 'ReservationDetails.TitleFeeDetails',
					defaultMessage: 'Fee Details'
				})}
				labelValueList={feeDetailsLabelValueList}
			/>
			{/* Charging Fee */}
			<LabelValueList
				title={intl.formatMessage({
					id: 'ReservationDetails.TitleChargingFee',
					defaultMessage: 'Charging Fee'
				})}
				labelValueList={chargingFeeLabelValueList}
			/>
			{/* Total Booking Cost */}
			<div className="flex items-center justify-between space-x-5 rounded-lg bg-primary-100 p-4">
				{/* Left Label */}
				<div>
					<label
						data-testid="rd-label-total-booking-cost"
						className="body-2-semibold text-typography-primary"
					>
						<FormattedMessage
							id="ReservationDetails.LabelTotalBookingCost"
							defaultMessage="Total Booking Cost"
						/>
					</label>
				</div>
				{/* Right Value */}
				<div className="min-w-20 max-w-[50%]">
					<p
						data-testid="rd-text-value-total-booking-cost"
						className="body-3-bold break-words text-right leading-6 text-typography-primary"
					>
						{formatCurrencyValue(5, OmniCountryCurrencyCode.Thailand, 2)}
					</p>
				</div>
			</div>
		</div>
	)
}

const MemoisedReservationDetails = memo(ReservationDetails)

export default MemoisedReservationDetails
