import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'

import { CPO_BACKEND_URL } from '../constants/env'
import { TRANSIENT_SESSION_ID_KEY } from '../constants/storage'
import { useLocalStorageItem } from '../hooks/useStorageItem'
import { OmniSession } from '../types/omni'

export const ROOT_SESSIONS_QUERY_KEY = 'Sessions'

export enum SessionsQueryKey {
	Session = 'Session',
	SessionTransient = 'SessionTransient',
	SessionTransientWithToken = 'SessionTransientWithToken'
}

interface SessionResponse {
	data: {
		session: OmniSession | null
	} | null
	message: string
	status: number
}

interface SessionQueryParams {
	sessionId: string
}

export const useSessionQuery = <TData = OmniSession | null>(
	params: SessionQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniSession | null,
			AxiosError<SessionResponse>,
			TData,
			[string, SessionsQueryKey.Session, string | null, SessionQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	const { accessToken } = useAuthContext()
	return useQuery({
		...options,
		queryKey: [ROOT_SESSIONS_QUERY_KEY, SessionsQueryKey.Session, accessToken, params],
		enabled: (options?.enabled === undefined || options.enabled) && !!accessToken,
		queryFn: async (): Promise<OmniSession | null> => {
			try {
				const { sessionId } = params
				const response = await axios.get<SessionResponse>(
					`${CPO_BACKEND_URL}/v3/sessions/${sessionId}`,
					{
						headers: { Authorization: accessToken }
					}
				)
				return response.data.data?.session ?? null
			} catch (error) {
				const axiosError = error as AxiosError<SessionResponse>
				if (axiosError.response?.status === 404 && axiosError.response.data.data === null) {
					return null
				}
				return Promise.reject(axiosError)
			}
		}
	})
}

interface SessionTransientResponse {
	data: OmniSession | null
	message: string
	status: number
}

/**
 * Fetches a Transient session based on the `sessionID` cookie.
 */
export const useSessionTransientQuery = <TData = OmniSession | null>(
	options?: Omit<
		UseQueryOptions<
			OmniSession | null,
			AxiosError<SessionTransientResponse>,
			TData,
			[string, SessionsQueryKey.SessionTransient, string | null]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	const [transientSessionId, setTransientSessionId] = useLocalStorageItem(TRANSIENT_SESSION_ID_KEY)
	const queryClient = useQueryClient()
	return useQuery({
		...options,
		queryKey: [ROOT_SESSIONS_QUERY_KEY, SessionsQueryKey.SessionTransient, transientSessionId],
		enabled: (options?.enabled === undefined || options.enabled) && !!transientSessionId,
		queryFn: async (): Promise<OmniSession | null> => {
			try {
				const response = await axios.get<SessionTransientResponse>(
					`${CPO_BACKEND_URL}/v3/sessions/transient`,
					{ withCredentials: true } // Include cookies.
				)
				return response.data.data
			} catch (error) {
				const axiosError = error as AxiosError<SessionTransientResponse>
				if (axiosError.response?.status === 404 && axiosError.response.data.data === null) {
					return null
				}
				if (axiosError.response?.status === 401 && transientSessionId) {
					setTransientSessionId(null)
					await queryClient.invalidateQueries({
						queryKey: [ROOT_SESSIONS_QUERY_KEY, SessionsQueryKey.SessionTransient]
					})
				}
				return Promise.reject(axiosError)
			}
		}
	})
}

interface SessionTransientWithTokenQueryParams {
	sessionId: string
	transientToken: string | null
}

/**
 * Fetches a Transient session based on the `sessionId` and `token`.
 * If `session` is successfully returned, the `sessionID` cookie will be set.
 */
export const useSessionTransientWithTokenQuery = <TData = OmniSession | null>(
	params: SessionTransientWithTokenQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniSession | null,
			AxiosError<SessionTransientResponse>,
			TData,
			[string, SessionsQueryKey.SessionTransientWithToken, SessionTransientWithTokenQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_SESSIONS_QUERY_KEY, SessionsQueryKey.SessionTransientWithToken, params],
		queryFn: async (): Promise<OmniSession | null> => {
			try {
				const { sessionId, transientToken: transient_token } = params
				const response = await axios.get<SessionTransientResponse>(
					`${CPO_BACKEND_URL}/v3/sessions/transient/${sessionId}`,
					{
						params: {
							transient_token
						},
						withCredentials: true // Allow Set-Cookie
					}
				)
				return response.data.data ?? null
			} catch (error) {
				const axiosError = error as AxiosError<SessionTransientResponse>
				if (axiosError.response?.status === 404 && axiosError.response.data.data === null) {
					return null
				}
				return Promise.reject(axiosError)
			}
		}
	})
}
