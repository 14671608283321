import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

interface Time {
	hours: number
	minutes: number
	seconds: number
}

interface TimeFormat {
	singular: string
	plural: string
	abbreviated: string
}

interface TimeFormatMap {
	hoursFormat: TimeFormat
	minutesFormat: TimeFormat
	secondsFormat: TimeFormat
}

type FormatTimeToStringFunctionOptions = Partial<{
	/**
	 * Whether or not to abbreviate the units of time in the output string. Defaults to `true`.
	 */
	isAbbreviated: boolean
	/**
	 * Whether or not the first letter of each unit of time in the output string is capitalised. Defaults to `false`.
	 */
	isCapitalised: boolean
}>

/**
 * Formats a `Time` object into a readable string.
 * @param {Time} time The amount of `Time` to format.
 * @param {FormatTimeToStringFunctionOptions} options The time string formatting `options`.
 * @returns {string} A formatted time string based on the provided `options`.
 */
type FormatTimeToStringFunction = (
	time: Time,
	options?: FormatTimeToStringFunctionOptions
) => string

interface TimeFormatterData {
	timeFormatMap: TimeFormatMap
	formatTimeToString: FormatTimeToStringFunction
}

const useTimeFormatter = (): TimeFormatterData => {
	const intl = useIntl()

	const timeFormatMap = useMemo((): TimeFormatMap => {
		const hour = intl.formatMessage({
			id: 'formatTimeToString.LabelValueHour',
			defaultMessage: 'hour'
		})

		const minute = intl.formatMessage({
			id: 'formatTimeToString.LabelValueMinute',
			defaultMessage: 'minute'
		})

		const second = intl.formatMessage({
			id: 'formatTimeToString.LabelValueSecond',
			defaultMessage: 'second'
		})

		const hours = intl.formatMessage({
			id: 'formatTimeToString.LabelValueHours',
			defaultMessage: 'hours'
		})

		const minutes = intl.formatMessage({
			id: 'formatTimeToString.LabelValueMinutes',
			defaultMessage: 'minutes'
		})

		const seconds = intl.formatMessage({
			id: 'formatTimeToString.LabelValueSeconds',
			defaultMessage: 'seconds'
		})

		const hoursAbbreviated = intl.formatMessage({
			id: 'formatTimeToString.LabelValueHourAbbreviated',
			defaultMessage: 'h'
		})

		const minutesAbbreviated = intl.formatMessage({
			id: 'formatTimeToString.LabelValueMinuteAbbreviated',
			defaultMessage: 'min'
		})

		const secondsAbbreviated = intl.formatMessage({
			id: 'formatTimeToString.LabelValueSecondAbbreviated',
			defaultMessage: 's'
		})

		return {
			hoursFormat: { singular: hour, plural: hours, abbreviated: hoursAbbreviated },
			minutesFormat: { singular: minute, plural: minutes, abbreviated: minutesAbbreviated },
			secondsFormat: { singular: second, plural: seconds, abbreviated: secondsAbbreviated }
		}
	}, [intl])

	const formatTimeToString = useCallback(
		(time: Time, options?: FormatTimeToStringFunctionOptions): string => {
			const { hours, minutes, seconds } = time

			const { isAbbreviated = true, isCapitalised = false } = options ?? {}

			const { hoursFormat, minutesFormat, secondsFormat } = timeFormatMap

			const getUnitSuffix = (value: number, timeFormat: TimeFormat): string => {
				const unit = !isAbbreviated
					? value > 1
						? timeFormat.plural
						: timeFormat.singular
					: timeFormat.abbreviated
				if (isCapitalised) {
					return unit.charAt(0).toUpperCase() + unit.substring(1)
				}
				return unit
			}

			const unitHours = getUnitSuffix(hours, hoursFormat)

			const unitMinutes = getUnitSuffix(minutes, minutesFormat)

			const unitSeconds = getUnitSuffix(seconds, secondsFormat)

			const timeDurationArr = [
				hours > 0 ? `${hours} ${unitHours}` : null,
				minutes > 0 ? `${minutes} ${unitMinutes}` : null,
				seconds > 0 ? `${seconds} ${unitSeconds}` : null
			]

			return timeDurationArr.filter(Boolean).join(' ')
		},
		[timeFormatMap]
	)

	return { timeFormatMap, formatTimeToString }
}

export default useTimeFormatter
