import { extend as addDayjsPlugin } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import { createRoot } from 'react-dom/client'
import { ReportHandler } from 'web-vitals'

import App from './App'
import './animations.css'
import './style.css'

const rootElement = document.getElementById('root')

if (rootElement && rootElement.nodeName === 'DIV') {
	const root = createRoot(rootElement)

	/**
	 * Extend Day.js functionalities.
	 * @see https://day.js.org/docs/en/plugin/plugin
	 */
	const loadDayjsPlugins = (): void => {
		addDayjsPlugin(customParseFormat)
		addDayjsPlugin(duration)
		addDayjsPlugin(isBetween)
		addDayjsPlugin(isSameOrAfter)
		addDayjsPlugin(isSameOrBefore)
		addDayjsPlugin(isToday)
	}
	loadDayjsPlugins()

	/**
	 * Measure Core Web Vitals.
	 * @see https://web.dev/articles/vitals
	 */
	const reportWebVitals = (onPerfEntry?: ReportHandler) => {
		if (onPerfEntry && onPerfEntry instanceof Function) {
			void import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
				getCLS(onPerfEntry)
				getFID(onPerfEntry)
				getFCP(onPerfEntry)
				getLCP(onPerfEntry)
				getTTFB(onPerfEntry)
			})
		}
	}
	reportWebVitals()

	// strict mode removed for now because it causes issues with Google Maps Markers in React 18
	root.render(<App />)
} else {
	console.warn('Element div#root was not found in "index.html".')
}
