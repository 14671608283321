import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import Notice from 'src/_shared/components/Notice'
import { OmniSessionStatus } from 'src/_shared/enums/omni'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'
import { useSessionQuery } from 'src/_shared/queries/sessions'

import { ReservationExtendScreenViewItemKey } from '../enums'
import { ReservationExtendScreenCommonViewProps } from '../types'

type PreExtendViewProps = Omit<ReservationExtendScreenCommonViewProps, 'onNext'>

const PreExtendView = ({ routeParams, updateView }: PreExtendViewProps): JSX.Element => {
	const intl = useIntl()

	const { data: session = null, status: sessionQueryStatus } = useSessionQuery(routeParams)

	const { locationQueryStatus, locationError, ...chargerDetails } = useChargerDetails({
		cpoEntityCode: session?.location?.entity_code ?? '',
		locationUid: session?.location_uid ?? '',
		evseUid: session?.evse_uid ?? '',
		connectorUid: session?.connector_uid ?? '',
		enableRefetch: false
	})

	// FUTURE TODO: Check for user session and charger extension options
	const isReservationNotExtendable = false

	/**
	 * Move to the Extend View if the session can be extended.
	 */
	useEffect((): void => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (isReservationNotExtendable) {
			updateView(ReservationExtendScreenViewItemKey.ExtendView)
		}
	}, [isReservationNotExtendable, updateView])

	// Error Displays
	// Note: Do not use `updateView` for these errors because the Back Button should
	// return the user to the last screen prior to the Reservation Extend Screen.
	// Non-Active Session or Reservation Not Supported
	if (locationError) {
		const statusCode = locationError.response?.status
		return (
			<Notice
				type="error"
				header={
					statusCode === 404
						? intl.formatMessage({
								id: 'PreExtendView.TitleChargerNotFound',
								defaultMessage: 'Charger Not Found'
							})
						: intl.formatMessage({
								id: 'PreExtendView.TitleOopsFailure',
								defaultMessage: 'Oops! Something Went Wrong'
							})
				}
			/>
		)
	} else if (
		// Session Not Active
		(sessionQueryStatus === 'success' && session?.status !== OmniSessionStatus.Active) ||
		// Reservation Not Supported
		(locationQueryStatus === 'success' &&
			(!chargerDetails.isReservationCapable || !chargerDetails.reservationType))
	) {
		return (
			<Notice
				type="error"
				header={intl.formatMessage({
					id: 'PreExtendView.TitleSessionNotExtendable',
					defaultMessage: 'Session Cannot Be Extended'
				})}
			/>
		)
	}
	// No Reservation Slots Available
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	else if (isReservationNotExtendable) {
		return (
			<Notice
				type="error"
				header={intl.formatMessage({
					id: 'PreExtendView.TitleNoSlotsAvailable',
					defaultMessage: 'No Slots Available For Extension'
				})}
			/>
		)
	}
	return <></>
}

export default PreExtendView
