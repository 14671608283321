export enum PaymentMethodCardType {
	Amex = 'AMEX',
	Jcb = 'JCB',
	Mastercard = 'MASTERCARD',
	Stripe = 'STRIPE',
	UnionPay = 'UNIONPAY',
	Visa = 'VISA'
}

export enum PaymentMethodCode {
	Beep = 'BEEP',
	/**
	 * Europay, MasterCard, Visa.
	 */
	Emv = 'EMV',
	Evme = 'EVME',
	FreeOfCharge = 'FREE_OF_CHARGE',
	GrabPay = 'GRABPAY',
	Postpaid = 'POSTPAID',
	Stripe = 'STRIPE',
	Subscription = 'SUBSCRIPTION',
	TouchNGo = 'TNG',
	Unknown = 'UNKNOWN_PAYMENT_METHOD'
}

export enum PaymentTypeCode {
	Tokenized = 'TOKENIZED',
	FreeOfCharge = 'FREE_OF_CHARGE_TYPE',
	Subscription = 'SUBSCRIPTION_TYPE',
	Redirect = 'REDIRECT',
	UserBalance = 'USER_BALANCE_TYPE',
	Postpaid = 'POSTPAID_TYPE'
}
