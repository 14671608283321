import { MutationStatus } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useRouterNavigate } from 'src/App/router/hooks'
import { ScreenRoutePath } from 'src/App/router/types'
import ReservationDetails from 'src/_shared/components/ReservationDetails'
import Spinner from 'src/_shared/components/Spinner'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'
import { delay } from 'src/_shared/utils/time'

import { ReservationChargerScreenCommonViewProps } from '../types'
import ConfirmationModal from './ConfirmationModal'
import FooterButton from './FooterButton'

type ConfirmationViewProps = ReservationChargerScreenCommonViewProps

const ConfirmationView = ({
	routeParams,
	formValues,
	onNext: handleNext
}: ConfirmationViewProps): JSX.Element => {
	// FUTURE TODO: Use mutation status
	const [createReservationStatus, setCreateReservationStatus] = useState<MutationStatus>('idle')

	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

	const { location, evse, connector } = useChargerDetails({
		...routeParams,
		enableRefetch: false
	})

	const navigate = useRouterNavigate()

	const isCreateReservationPending = createReservationStatus === 'pending'

	const isCreateReservationSuccessful = createReservationStatus === 'success'

	const handleNextClick = useCallback((): void => {
		const isBrandExternalPayment = [Brand.Evme].includes(BRAND)
		if (isBrandExternalPayment) {
			setIsConfirmationModalOpen(true)
		} else {
			handleNext?.()
		}
	}, [handleNext])

	// FUTURE TODO: Handle redirect payment flow
	const handleBookNowClick = useCallback((): void => {
		const createReservation = async (): Promise<void> => {
			setCreateReservationStatus('pending')
			await delay(2500)
			setCreateReservationStatus('success')
			await delay(2500)
			navigate([ScreenRoutePath.ReservationDetail, ':sessionId'])
		}
		void createReservation()
	}, [navigate])

	return (
		<>
			{!!location && !!evse && !!connector ? (
				<div className="flex-grow">
					<ReservationDetails
						reservationData={{
							...formValues,
							endTime: dayjs(formValues.startTime).add(formValues.duration, 'minutes').valueOf()
						}}
						location={location}
						evse={evse}
						connector={connector}
					/>
				</div>
			) : (
				<div className="flex flex-grow flex-col items-center justify-center pb-6">
					<Spinner />
				</div>
			)}
			<FooterButton
				className="w-full"
				onClick={handleNextClick}
				disabled={isCreateReservationPending || isCreateReservationSuccessful}
			>
				<FormattedMessage id="ConfirmationView.ButtonTextNext" defaultMessage="Next" />
			</FooterButton>
			<ConfirmationModal
				open={isConfirmationModalOpen}
				isLoading={isCreateReservationPending}
				isSuccessful={isCreateReservationSuccessful}
				setModalOpen={setIsConfirmationModalOpen}
				onBookNowClick={handleBookNowClick}
			/>
		</>
	)
}

export default ConfirmationView
