import { memo, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import CheckCircleFilledIcon from 'src/_shared/components/_icons/CheckCircleFilledIcon'
import CheckCircleOutlinedGradientIcon from 'src/_shared/components/_icons/CheckCircleOutlinedGradientIcon'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'

interface ConfirmationModalProps {
	open?: boolean
	isLoading?: boolean
	isSuccessful?: boolean
	setModalOpen: (open: boolean) => void
	onBookNowClick: () => void
}

const ConfirmationModal = ({
	open: isConfirmationModalOpen,
	isLoading,
	isSuccessful,
	setModalOpen: setConfirmationModalOpen,
	onBookNowClick: handleBookNowClick
}: ConfirmationModalProps): JSX.Element => {
	const closeConfirmationModal = useCallback((): void => {
		if (!isLoading && !isSuccessful) {
			setConfirmationModalOpen(false)
		}
	}, [isLoading, isSuccessful, setConfirmationModalOpen])

	return (
		<Modal open={isConfirmationModalOpen} onClose={closeConfirmationModal}>
			<ModalCard className="flex flex-col items-center">
				{isSuccessful ? (
					<>
						{((): JSX.Element => {
							switch (BRAND) {
								case Brand.Evme:
									return (
										<CheckCircleOutlinedGradientIcon
											className="mb-4 h-[60px] w-[60px]"
											gradientFrom="#CBFF31"
											gradientTo="#80FFCA"
										/>
									)
								default:
									return <CheckCircleFilledIcon className="mb-4 h-12 w-12 text-success-400" />
							}
						})()}
						<h1
							data-testid="rcs-cm-header-booking-successful"
							className="mb-2 text-center text-modal-typography-primary"
						>
							<FormattedMessage
								id="ConfirmationModal.ModalTitleBookingSuccessful"
								defaultMessage="Booking Successful"
							/>
						</h1>
						<p
							data-testid="rcs-cm-description-booking-successful"
							className="body-2-light text-center text-modal-typography-primary"
						>
							<FormattedMessage
								id="ConfirmationModal.ModalDescriptionChargingSlotReserved"
								defaultMessage="Your charging slot has been reserved."
							/>
						</p>
					</>
				) : (
					<>
						{/* Content */}
						<h1 className="mb-2 text-center text-modal-typography-primary">
							<FormattedMessage
								id="ConfirmationModal.ModalTitleGetReady"
								defaultMessage="Get Ready"
							/>
						</h1>
						<p className="body-2-light mb-8 text-center text-modal-typography-primary">
							<FormattedMessage
								id="ConfirmationModal.ModalDescriptionBookingFeeReminder"
								defaultMessage="Your booking fee will be charged at the start of your charging session. Please ensure you start charging within the allotted time to avoid cancellation."
							/>
						</p>
						{/* Button Controls */}
						<div className="flex w-full flex-col space-y-4">
							<Button
								data-testid="rcs-cm-im-btn-book-now"
								variant="primary"
								loading={isLoading}
								disabled={isLoading}
								onClick={handleBookNowClick}
							>
								<FormattedMessage
									id="ConfirmationModal.ButtonTextBookNow"
									defaultMessage="Book Now"
								/>
							</Button>
							<Button
								data-testid="rcs-cm-im-btn-cancel"
								variant="secondary"
								disabled={isLoading}
								onClick={closeConfirmationModal}
							>
								<FormattedMessage id="ConfirmationModal.ButtonTextCancel" defaultMessage="Cancel" />
							</Button>
						</div>
					</>
				)}
			</ModalCard>
		</Modal>
	)
}

const MemoisedConfirmationModal = memo(ConfirmationModal)

export default MemoisedConfirmationModal
