import { createContext, useContext } from 'react'

import { MiniProgramApi } from '../types/miniProgram'

interface MiniProgramContextData {
	miniProgram: MiniProgramApi | null
	/**
	 * Requests for authentication permissions via the Mini Program.
	 * This should open up a permission prompt that the user can either accept or decline.
	 * If permissions were granted, the current page should navigate to the specified `url` with an `sso_token`.
	 * Otherwise, the user will continue to remain on the current screen (i.e. not logged-in).
	 * @returns {Function|null} Returns `null` if the `miniProgram` is not provided or the user
	 * is already authenticated. Otherwise, an authentication callback should be provided.
	 */
	authenticate: ((url?: string) => void) | null
}

export const MiniProgramContext = createContext<MiniProgramContextData>({
	miniProgram: null,
	authenticate: null
})

export const useMiniProgramContext = (): MiniProgramContextData => {
	const context = useContext(MiniProgramContext)
	return context
}
