import { useCallback } from 'react'
import { useSessionStorage } from 'src/_shared/hooks/useStorageItem'
import { OmniLocation } from 'src/_shared/types/omni'

const USER_SELECTED_LOCATION = 'userSelectedLocation'

export const useUserSelectedLocation = (): {
	selectedLocation: OmniLocation | null
	setSelectedLocation: (selectedLocation: OmniLocation | null) => void
} => {
	const [selectedLocation, setSelectedLocation] = useSessionStorage<OmniLocation | null>(
		USER_SELECTED_LOCATION
	)

	const handleSetSelectedLocation = useCallback(
		(location: OmniLocation | null) => {
			setSelectedLocation(location)
		},
		[setSelectedLocation]
	)

	return {
		selectedLocation,
		setSelectedLocation: handleSetSelectedLocation
	}
}
