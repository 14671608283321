import { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ButtonProps } from 'src/_shared/components/Button'
import Notice from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { BACK_LINK, BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useMiniProgramContext } from 'src/_shared/hooks/useMiniProgramContext'
import { useUserSsoLoginMutation } from 'src/_shared/mutations/auth'

const SSO_RETRY_COUNT = 3

const SSO_TOKEN_KEY = 'sso_token'

/**
 * Handles SSO Login using the provided `sso_token` query parameter.
 */
const AuthSsoWrapper = ({ children }: PropsWithChildren): ReactNode => {
	const intl = useIntl()

	const { isAuthenticated, isTokenRefreshLoading, setAccessToken } = useAuthContext()

	const { miniProgram } = useMiniProgramContext()

	const {
		error: userSsoLoginError,
		mutateAsync: ssoLogin,
		status: userSsoLoginMutationStatus
	} = useUserSsoLoginMutation({
		retry: SSO_RETRY_COUNT
	})

	const ssoTokenQueryParameter = new URL(window.location.href).searchParams.get(SSO_TOKEN_KEY)

	const errorMessage = userSsoLoginError?.response?.data.message

	const { isSsoError, isSsoLoading } = useMemo((): {
		isSsoError: boolean
		isSsoLoading: boolean
	} => {
		switch (BRAND) {
			// Optional SSO
			case Brand.TouchNGo:
				return {
					isSsoError: userSsoLoginMutationStatus === 'error',
					isSsoLoading: userSsoLoginMutationStatus === 'pending'
				}
			// Mandatory SSO
			default:
				return {
					isSsoError:
						(!isAuthenticated && !isTokenRefreshLoading && !ssoTokenQueryParameter) || // Not authenticated and no SSO token provided
						userSsoLoginMutationStatus === 'error',
					isSsoLoading:
						(!isAuthenticated && isTokenRefreshLoading) || // First token refresh
						(!!ssoTokenQueryParameter && userSsoLoginMutationStatus === 'idle') || // SSO token provided
						userSsoLoginMutationStatus === 'pending'
				}
		}
	}, [isAuthenticated, isTokenRefreshLoading, ssoTokenQueryParameter, userSsoLoginMutationStatus])

	/**
	 * Authenticate with provided SSO token query parameter.
	 */
	useEffect((): void => {
		if (ssoTokenQueryParameter) {
			// Clear away any existing token if `sso_token` is provided
			setAccessToken(null)
			void ssoLogin(
				{
					ssoToken: ssoTokenQueryParameter
				},
				{
					onSuccess: ({ token }): void => {
						const url = new URL(window.location.href)
						url.searchParams.delete(SSO_TOKEN_KEY)
						window.history.replaceState(null, '', url)
						setAccessToken(token)
					}
				}
			)
		}
	}, [ssoTokenQueryParameter, setAccessToken, ssoLogin])

	if (isSsoError || isSsoLoading) {
		return (
			<ScreenContainer
				contentViewProps={{
					className: 'px-5 py-6'
				}}
				hideTopBar
				hideBottomBar
				disableScrollRestoration
			>
				{isSsoError ? (
					<Notice
						type="error"
						header={intl.formatMessage({
							id: 'Common.GenericErrorTitle',
							defaultMessage: 'Oops! Something Went Wrong'
						})}
						description={
							errorMessage
								? errorMessage
								: intl.formatMessage({
										id: 'Common.GenericErrorDescription',
										defaultMessage: 'Please try again later'
									})
						}
						buttonProps={((): Omit<ButtonProps, 'ref'> | null => {
							switch (BRAND) {
								case Brand.TouchNGo:
									return {
										children: intl.formatMessage({
											id: 'Common.GenericButtonTextBack',
											defaultMessage: 'Back'
										}),
										onClick: miniProgram
											? (): void => {
													// Relaunch the Mini Program and returns to the original `web-view` screen.
													miniProgram.reLaunch({
														url: '/pages/web-view/index'
													})
												}
											: undefined
									}
								case Brand.Evme:
									return BACK_LINK
										? {
												children: intl.formatMessage({
													id: 'Common.GenericButtonTextBack',
													defaultMessage: 'Back'
												}),
												onClick: (): void => {
													window.location.href = BACK_LINK ?? ''
												}
											}
										: null
								default:
									return null
							}
						})()}
					/>
				) : (
					<div className="flex flex-grow flex-col items-center justify-center">
						<Spinner />
					</div>
				)}
			</ScreenContainer>
		)
	}
	return children
}

export default AuthSsoWrapper
