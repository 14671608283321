import { createContext, useContext } from 'react'

import { UserInfo } from '../types/user'

interface AuthContextData {
	accessToken: string | null
	isAuthenticated: boolean
	isTokenRefreshLoading: boolean
	user: UserInfo | null
	clearAuthTokens: () => void
	setAccessToken: (value: string | null) => void
}

export const AuthContext = createContext<AuthContextData>({
	accessToken: null,
	isAuthenticated: false,
	isTokenRefreshLoading: false,
	user: null,
	clearAuthTokens: (): void => {
		console.debug('[AuthWrapper] clearAuthTokens was not provided.')
	},
	setAccessToken: (): void => {
		console.debug('[AuthWrapper] setAccessToken was not provided.')
	}
})

export const useAuthContext = (): AuthContextData => {
	const context = useContext(AuthContext)
	return context
}
