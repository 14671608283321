import { useCallback, useMemo } from 'react'
import {
	Location,
	NavigateOptions,
	Path,
	SetURLSearchParams,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams
} from 'react-router-dom'

import { ScreenRoutePath, ScreenRoutePathWithParams } from './types'

/**
 * Returns the defined set of parameters attached in the `search` string and a function to modify the query string.
 * @returns {[T, SetURLSearchParams]} The parsed query parameters from the `search` string and the modify function.
 */
export const useQueryParams = <T = Record<string, string | undefined>>(): [
	T,
	SetURLSearchParams
] => {
	const [searchParams, setSearchParams] = useSearchParams()

	const queryParams = useMemo((): Record<string, string | undefined> => {
		return Array.from(searchParams.entries()).reduce(
			(keyValueMap, [key, value]) => ({ ...keyValueMap, [key]: value }),
			{}
		)
	}, [searchParams])

	return [queryParams as T, setSearchParams]
}

/**
 * Returns an object of key/value pairs of the dynamic params from the current URL that were matched by the route path.
 * @returns {Readonly<T>} The current route params object.
 * @see {@link https://reactrouter.com/hooks/use-params}
 */
export const useRouteParams = <T extends Partial<Record<string, string>>>(): Readonly<T> => {
	const routeParams = useParams() as unknown as Readonly<T>
	return routeParams
}

/**
 * Returns the current location object, which represents the current URL in web browsers.
 * @returns {Location<T>} The current location object.
 * @see https://reactrouter.com/hooks/use-location
 */
export const useRouterLocation = <T = null>(): Location<T> => {
	const location = useLocation() as Location<T>
	return location
}

type NavigateTo = ScreenRoutePath | ScreenRoutePathWithParams | ScreenRoutePathDetailed

type ScreenRoutePathDetailed = {
	pathname: ScreenRoutePath | ScreenRoutePathWithParams
} & Partial<Pick<Path, 'hash' | 'search'>>

/**
 * Determines whether the `navigateTo` argument used in useNavigate hook
 * is of type `ScreenRoutePathDetailed` (has either or both 'search' or 'hash' param)
 * @returns {navigateTo is ScreenRoutePathDetailed}
 */
const isDetailedScreenRoutePath = (
	navigateTo: NavigateTo
): navigateTo is ScreenRoutePathDetailed => {
	return typeof navigateTo === 'object' && 'pathname' in navigateTo
}

/**
 * Function Overload.
 */
interface RouterNavigateFunction {
	(
		to: ScreenRoutePath | ScreenRoutePathWithParams | ScreenRoutePathDetailed,
		options?: NavigateOptions
	): void
	(delta: number): void
}

/**
 * Returns an imperative function for changing the location. Its underlying behaviour is the same as `react-router-dom`,
 * the only difference is that the arguments are much more strictly-typed to account for `ScreenRoutePathKey`.
 * @returns {RouterNavigateFunction} The `navigate` function.
 * @see https://reactrouter.com/hooks/use-navigate
 */
export const useRouterNavigate = (): RouterNavigateFunction => {
	const navigate = useNavigate()

	const routerNavigate: RouterNavigateFunction = useCallback(
		(...args): void => {
			const navigateTo = args[0]
			if (typeof navigateTo === 'number') {
				navigate(navigateTo)
			} else {
				const options = args[1] as NavigateOptions | undefined
				let pathname: string | string[]
				let search: string | undefined
				let hash: string | undefined
				if (isDetailedScreenRoutePath(navigateTo)) {
					pathname = navigateTo.pathname
					search = navigateTo.search
					hash = navigateTo.hash
				} else {
					pathname = navigateTo
				}
				const formattedNavigateTo = Array.isArray(pathname) ? pathname.join('/') : pathname
				navigate(
					{
						pathname: formattedNavigateTo,
						search: search,
						hash: hash
					},
					options
				)
			}
		},
		[navigate]
	)

	return routerNavigate
}
