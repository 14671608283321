import { AppMode, Brand } from '../enums/env'

/**
 * Get a typed key-value from the `.env` file.
 * @param {string} key The name of variable.
 * @returns {string|null} The value of the variable. If the value is empty or not defined, `null` will be returned.
 */
const getEnvVariable = <T = string>(key: string): T | null => {
	const envVariable = import.meta.env[key] as T
	if (envVariable) {
		return envVariable
	}
	return null
}

/*
 * Branding and Strapi Data Souce
 */
export const BRAND = getEnvVariable<Brand>('VITE_BRAND') ?? Brand.Demo

export const STRAPI_URL = getEnvVariable('VITE_STRAPI_URL') ?? '/'

/*
 * BE API
 */
export const CPO_BACKEND_URL = getEnvVariable('VITE_CPO_BACKEND_URL') ?? '/'

/*
 * Map Screen
 */
export const GOOGLE_MAPS_API_KEY = getEnvVariable('VITE_GOOGLE_MAPS_API_KEY') ?? undefined

export const GOOGLE_MAPS_MAP_ID = getEnvVariable('VITE_GOOGLE_MAPS_MAP_ID') ?? undefined

export const MAP_PATH = getEnvVariable('VITE_MAP_PATH')

export const MAP_DEFAULT_CENTER_LAT = ((): number => {
	const defaultCenterLat = getEnvVariable('VITE_MAP_DEFAULT_CENTER_LAT')
	if (defaultCenterLat && !isNaN(Number(defaultCenterLat))) {
		return Number(defaultCenterLat)
	}
	return 1.3288 // Center of Singapore
})()

export const MAP_DEFAULT_CENTER_LNG = ((): number => {
	const defaultCenterLng = getEnvVariable('VITE_MAP_DEFAULT_CENTER_LNG')
	if (defaultCenterLng && !isNaN(Number(defaultCenterLng))) {
		return Number(defaultCenterLng)
	}
	return 103.8198 // Center of Singapore
})()

/**
 * Charger Screen
 */
export const TRANSIENT_USE_ACTIVE_TARIFF =
	getEnvVariable('VITE_TRANSIENT_USE_ACTIVE_TARIFF') === 'true'

/*
 * Contact Us Screen
 */
export const CONTACT_US_BUTTON_LINK = getEnvVariable('VITE_CONTACT_US_BUTTON_LINK') ?? ''

export const CONTACT_US_BUTTON_TEXT = getEnvVariable('VITE_CONTACT_US_BUTTON_TEXT') ?? ''

export const CONTACT_US_METHOD = getEnvVariable('VITE_CONTACT_US_METHOD') ?? undefined

export const CONTACT_US_NUMBER = getEnvVariable('VITE_CONTACT_US_NUMBER')

/*
 * Landing Screen
 */
export const LANDING_APP_STORE_LINK = getEnvVariable('VITE_LANDING_APP_STORE_LINK') ?? ''

export const LANDING_FALLBACK_LINK = getEnvVariable('VITE_LANDING_FALLBACK_LINK') ?? ''

export const LANDING_GOOGLE_PLAY_LINK = getEnvVariable('VITE_LANDING_GOOGLE_PLAY_LINK') ?? ''

/*
 * Miscellaneous
 */
export const APP_MODE = getEnvVariable<AppMode>('VITE_APP_MODE') ?? AppMode.Default

export const APP_VERSION = getEnvVariable('VITE_APP_VERSION')

/**
 * If provided, the user will be redirected to the link instead of going back to the
 * Map Screen by default. This is primarily used by EVme to redirect users back to
 * their own application.
 */
export const BACK_LINK = getEnvVariable('VITE_BACK_LINK')

export const DISABLE_SUBSCRIPTION = getEnvVariable('VITE_DISABLE_SUBSCRIPTION') === 'true'

export const ROLLBAR_ACCESS_TOKEN = getEnvVariable('VITE_ROLLBAR_ACCESS_TOKEN') ?? ''

export const STRIPE_PUBLIC_KEY = getEnvVariable('VITE_STRIPE_PUBLIC_KEY') ?? ''

/**
 * If not provided, defaults to 12 Hour Clock with AM/PM indicator.
 */
export const TIME_FORMAT = getEnvVariable('VITE_TIME_FORMAT') ?? 'hh:mm A'
