import { memo } from 'react'
import Button, { ButtonProps } from 'src/_shared/components/Button'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

type FooterButtonProps = {
	rootClassName?: string
} & Omit<ButtonProps, 'ref'>

const FooterButton = ({ rootClassName, ...buttonProps }: FooterButtonProps): JSX.Element => {
	return (
		<div
			className={classNames(
				'sticky bottom-0 flex flex-col items-center bg-gradient-to-b from-grayscale-100/90 to-grayscale-100 py-6',
				rootClassName
			)}
		>
			<Button
				data-testid={formatDataTestId(['rcs-footer-btn', buttonProps['data-testid']])}
				{...buttonProps}
			/>
		</div>
	)
}

const MemoisedFooterButton = memo(FooterButton)

export default MemoisedFooterButton
