export enum ScreenRoutePath {
	Account = '/account',
	AccountForgotPassword = '/forgot-password',
	AccountHelp = '/account/help',
	AccountLogin = '/login',
	AccountPaymentMethods = '/account/payment-methods',
	AccountPaymentMethodsNew = '/account/payment-methods/new',
	AccountProfile = '/account/profile',
	AccountProfileChangePassword = '/account/profile/change-password',
	AccountProfileEdit = '/account/profile/edit',
	AccountSelectLanaugage = '/account/select-language',
	AccountRegistration = '/register',
	Charger = '/charger',
	CheckIn = '/check-in',
	CheckInManual = '/check-in/manual',
	CheckInLocationScreen = '/check-in/location',
	CheckInQrScanner = '/check-in/qr-scanner',
	CheckInRedirect = '/check-in/redirect',
	/**
	 * Transient Help Screen
	 */
	Help = '/help',
	History = '/history',
	HistoryFilter = '/history/filter',
	ReservationCharger = '/reservation/charger',
	ReservationDetail = '/reservation/detail',
	ReservationExtend = '/reservation/extend',
	Session = '/session',
	SessionCancel = '/session/cancel',
	/**
	 * SSO/Transient Landing Screen
	 */
	Landing = '/landing',
	Root = '/',
	TermsAndConditions = '/terms-and-conditions',
	HistoryDetail = '/history/detail',
	Map = '/map',
	Location = '/location',
	Locations = '/locations',
	Favourites = '/favourites'
}

/**
 * Format: ['/charger', cpoEntityCode, locationUid, evseUid, connectorUid]
 */
export type ChargerScreenRoutePath = [ScreenRoutePath.Charger, string, string, string, string]

/**
 * Format: ['/check-in/location', locationUid]
 */
export type CheckInLocationScreenRoutePath = [ScreenRoutePath.CheckInLocationScreen, string]

/**
 * Format: ['/history/detail', sessionId]
 */
export type HistoryDetailScreenRoutePath = [ScreenRoutePath.HistoryDetail, string]

/**
 * Format: ['/location', locationUid]
 */
export type LocationDetailsScreenRoutePath = [ScreenRoutePath.Location, string]

/**
 * Format: ['/reservation/charger', cpoEntityCode, locationUid, evseUid, connectorUid]
 */
export type ReservationChargerScreenRoutePath = [
	ScreenRoutePath.ReservationCharger,
	string,
	string,
	string,
	string
]

/**
 * Format: ['/reservation/detail', sessionId]
 */
export type ReservationDetailScreenRoutePath = [ScreenRoutePath.ReservationDetail, string]

/**
 * Format: ['/reservation/extend', sessionId]
 */
export type ReservationExtendScreenRoutePath = [ScreenRoutePath.ReservationExtend, string]

/**
 * Format: ['/session', sessionId]
 */
export type SessionScreenRoutePath = [ScreenRoutePath.Session, string]

export type ScreenRoutePathWithParams =
	| ChargerScreenRoutePath
	| CheckInLocationScreenRoutePath
	| HistoryDetailScreenRoutePath
	| LocationDetailsScreenRoutePath
	| ReservationChargerScreenRoutePath
	| ReservationDetailScreenRoutePath
	| ReservationExtendScreenRoutePath
	| SessionScreenRoutePath
