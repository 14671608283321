import {
	InfiniteData,
	UseInfiniteQueryResult,
	UseQueryOptions,
	useInfiniteQuery,
	useQuery
} from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { CPO_BACKEND_URL } from '../constants/env'
import { OmniSessionStatus } from '../enums/omni'
import { OmniConnector, OmniEvse, OmniLocation, OmniLocationNearby } from '../types/omni'

const MAX_LOCATIONS_LIMIT = 500

const DEFAULT_LOCATIONS_LIMIT = 20

export const ROOT_LOCATIONS_QUERY_KEY = 'Locations'

export enum LocationsQueryKey {
	Location = 'Location',
	LocationEvse = 'LocationEvse',
	LocationEvseConnector = 'LocationEvseConnector',
	LocationEvseConnectorCurrentSession = 'LocationEvseConnectorCurrentSession',
	Locations = 'Locations',
	LocationsInfinite = 'LocationsInfinite',
	LocationsNearby = 'LocationsNearby',
	LocationsNearbyInfinite = 'LocationsNearbyInfinite'
}

interface LocationQueryParams {
	locationUid: string
}

export const useLocationQuery = <TData = OmniLocation>(
	params: LocationQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniLocation,
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.Location, LocationQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.Location, params],
		queryFn: async (): Promise<OmniLocation> => {
			try {
				const { locationUid } = params
				const response = await axios.get<OmniLocation>(
					`${CPO_BACKEND_URL}/v3/locations/${locationUid}`
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface LocationEvseQueryParams extends LocationQueryParams {
	evseUid: string
}

export const useLocationEvseQuery = <TData = OmniEvse>(
	params: LocationEvseQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniEvse,
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.LocationEvse, LocationEvseQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.LocationEvse, params],
		queryFn: async (): Promise<OmniEvse> => {
			try {
				const { locationUid, evseUid } = params
				const response = await axios.get<OmniEvse>(
					`${CPO_BACKEND_URL}/v3/locations/${locationUid}/${evseUid}`
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface LocationEvseConnectorQueryParams extends LocationEvseQueryParams {
	connectorUid: string
}

export const useLocationEvseConnectorQuery = <TData = OmniConnector>(
	params: LocationEvseConnectorQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniConnector,
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.LocationEvseConnector, LocationEvseConnectorQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.LocationEvseConnector, params],
		queryFn: async (): Promise<OmniConnector> => {
			try {
				const { locationUid, evseUid, connectorUid } = params
				const response = await axios.get<OmniConnector>(
					`${CPO_BACKEND_URL}/v3/locations/${locationUid}/${evseUid}/${connectorUid}`
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface LocationEvseConnectorCurrentSessionQueryParams {
	locationUid: string
	evseUid: string
	connectorUid: string
}

interface LocationEvseConnectorCurrentSessionData {
	_id: string
	uid: string
	status: OmniSessionStatus
	/**
	 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`
	 */
	start_date_time: string
	/**
	 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`
	 */
	end_date_time: string
}

export const useLocationEvseConnectorCurrentSessionQuery = <
	TData = LocationEvseConnectorCurrentSessionData | null
>(
	params: LocationEvseConnectorCurrentSessionQueryParams,
	options?: Omit<
		UseQueryOptions<
			LocationEvseConnectorCurrentSessionData | null,
			AxiosError<{ message: string }>,
			TData,
			[
				string,
				LocationsQueryKey.LocationEvseConnectorCurrentSession,
				LocationEvseConnectorCurrentSessionQueryParams
			]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [
			ROOT_LOCATIONS_QUERY_KEY,
			LocationsQueryKey.LocationEvseConnectorCurrentSession,
			params
		],
		queryFn: async (): Promise<LocationEvseConnectorCurrentSessionData | null> => {
			try {
				const { locationUid, evseUid, connectorUid } = params
				const response = await axios.get<LocationEvseConnectorCurrentSessionData>(
					`${CPO_BACKEND_URL}/v3/locations/${locationUid}/${evseUid}/${connectorUid}/current-session`
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string } | null>
				if (axiosError.response?.status === 404 && axiosError.response.data === null) {
					return null
				}
				return Promise.reject(axiosError)
			}
		}
	})
}

interface FetchAllParams {
	fetchAll: true
}

interface PaginationParams {
	limit?: number
	offset?: number
}

interface LocationsData {
	locations: OmniLocation[]
}

type LocationsQueryParams = {
	publish?: boolean
} & (FetchAllParams | PaginationParams)

export const useLocationsQuery = <TData = OmniLocation[]>(
	params: LocationsQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniLocation[],
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.Locations, LocationsQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.Locations, params],
		queryFn: async (): Promise<OmniLocation[]> => {
			try {
				const url = `${CPO_BACKEND_URL}/v3/locations`

				// Get all locations over several calls.
				if ('fetchAll' in params) {
					const locations: OmniLocation[] = []
					let response: AxiosResponse<LocationsData> | null = null
					let offset = 0
					do {
						response = await axios.get<LocationsData>(url, {
							params: {
								publish: params.publish,
								limit: MAX_LOCATIONS_LIMIT,
								offset
							}
						})
						offset += MAX_LOCATIONS_LIMIT
						locations.push(...response.data.locations)
					} while (response.data.locations.length >= MAX_LOCATIONS_LIMIT)
					return locations
				}
				// Get locations within the specified limit and offset.
				else {
					const response = await axios.get<LocationsData>(url, {
						params
					})
					return response.data.locations
				}
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface LocationsPage {
	data: OmniLocation[]
	currentPage: number
	nextPage: number | null
}

interface LocationsInfiniteQueryParams {
	publish?: boolean
	limit?: number
	pageNumber?: number
	entityCodes?: string | null
	powerType?: string | null
}

const fetchLocationsPage = async (params: LocationsInfiniteQueryParams): Promise<LocationsPage> => {
	try {
		const {
			publish,
			pageNumber = 0,
			limit = DEFAULT_LOCATIONS_LIMIT,
			entityCodes: entity_codes,
			powerType: power_type
		} = params

		const commonParams: LocationsQueryParams = {
			publish,
			limit,
			offset: pageNumber * limit
		}

		const response = await axios.get<LocationsData>(`${CPO_BACKEND_URL}/v3/locations`, {
			params: {
				...commonParams,
				entity_codes,
				power_type
			}
		})

		// If the data returned from the backend is lesser than the number of items
		// to be specified in one page, then there is no next page.
		const hasNextPage = response.data.locations.length === limit

		const locationsPage: LocationsPage = {
			data: response.data.locations,
			currentPage: pageNumber,
			nextPage: hasNextPage ? pageNumber + 1 : null
		}

		return locationsPage
	} catch (error) {
		const axiosError = error as AxiosError<{ message: string }>
		return Promise.reject(axiosError)
	}
}

export const useLocationsInfiniteQuery = (
	params: LocationsInfiniteQueryParams
): UseInfiniteQueryResult<
	InfiniteData<LocationsPage, number>,
	AxiosError<LocationsQueryParams>
> => {
	const queryResult: UseInfiniteQueryResult<
		InfiniteData<LocationsPage, number>,
		AxiosError<LocationsQueryParams>
	> = useInfiniteQuery<
		LocationsPage,
		AxiosError<LocationsQueryParams>,
		InfiniteData<LocationsPage, number>,
		[LocationsQueryKey.LocationsInfinite, LocationsQueryParams],
		number
	>({
		queryKey: [LocationsQueryKey.LocationsInfinite, params],
		queryFn: async ({ pageParam }): Promise<LocationsPage> =>
			await fetchLocationsPage({
				...params,
				pageNumber: pageParam
			}),
		getNextPageParam: (lastPage): number | null => lastPage.nextPage,
		initialPageParam: 0,
		maxPages: 0,
		refetchOnWindowFocus: false,
		staleTime: 60000 // 1 Minute
	})

	return queryResult
}

interface LocationsNearbyData {
	locations: OmniLocationNearby[]
}

interface LocationsNearbyPage {
	data: OmniLocationNearby[]
	currentPage: number
	nextPage: number | null
}

type LocationsNearbyQueryParams = {
	latitude: number
	longitude: number
	publish?: boolean
	/**
	 * In Metres; maximum value of `50000`.
	 */
	radius: number
} & (FetchAllParams | PaginationParams)

export const useLocationsNearbyQuery = <TData = OmniLocationNearby[]>(
	params: LocationsNearbyQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniLocationNearby[],
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.LocationsNearby, LocationsNearbyQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.LocationsNearby, params],
		queryFn: async (): Promise<OmniLocationNearby[]> => {
			try {
				const { latitude, longitude, publish = true, radius } = params

				const commonParams: LocationsNearbyQueryParams = { latitude, longitude, publish, radius }

				const url = `${CPO_BACKEND_URL}/v3/locations/nearby`

				// Get all locations over several calls.
				if ('fetchAll' in params) {
					const locations: OmniLocationNearby[] = []
					let response: AxiosResponse<{
						locations: OmniLocationNearby[]
					}> | null = null
					let offset = 0
					do {
						response = await axios.get<{ locations: OmniLocationNearby[] }>(url, {
							params: {
								...commonParams,
								limit: MAX_LOCATIONS_LIMIT,
								offset
							}
						})
						offset += MAX_LOCATIONS_LIMIT
						locations.push(...response.data.locations)
					} while (response.data.locations.length >= MAX_LOCATIONS_LIMIT)
					return locations
				}
				// Get locations within the specified limit and offset.
				else {
					const response = await axios.get<LocationsNearbyData>(url, {
						params: commonParams
					})
					return response.data.locations
				}
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface LocationsNearbyInfiniteQueryParams {
	latitude: number
	longitude: number
	publish?: boolean
	/**
	 * In Metres; maximum value of `50000`.
	 */
	radius: number
	pageNumber?: number
	limit?: number
	entityCodes?: string | null
	powerType?: string | null
}

const fetchLocationsNearbyPage = async (
	params: LocationsNearbyInfiniteQueryParams
): Promise<LocationsNearbyPage> => {
	try {
		const {
			latitude,
			longitude,
			publish,
			radius,
			pageNumber = 0,
			limit = DEFAULT_LOCATIONS_LIMIT,
			entityCodes: entity_codes,
			powerType: power_type
		} = params

		const commonParams: LocationsNearbyQueryParams = {
			latitude,
			longitude,
			publish,
			radius,
			limit,
			offset: pageNumber * limit
		}

		const response = await axios.get<LocationsNearbyData>(
			`${CPO_BACKEND_URL}/v3/locations/nearby`,
			{
				params: {
					...commonParams,
					entity_codes,
					power_type
				}
			}
		)

		// Check if there's a next page based on the number of items returned
		const hasNextPage: boolean = response.data.locations.length === limit

		return {
			data: response.data.locations,
			currentPage: pageNumber,
			nextPage: hasNextPage ? pageNumber + 1 : null
		}
	} catch (error) {
		const axiosError = error as AxiosError<{ message: string }>
		return Promise.reject(axiosError)
	}
}

export const useLocationsNearbyInfiniteQuery = (
	params: LocationsNearbyInfiniteQueryParams
): UseInfiniteQueryResult<
	InfiniteData<LocationsNearbyPage, number>,
	AxiosError<LocationsNearbyQueryParams>
> => {
	const queryResult: UseInfiniteQueryResult<
		InfiniteData<LocationsNearbyPage, number>,
		AxiosError<LocationsNearbyQueryParams>
	> = useInfiniteQuery<
		LocationsNearbyPage,
		AxiosError<LocationsNearbyQueryParams>,
		InfiniteData<LocationsNearbyPage, number>,
		[LocationsQueryKey.LocationsNearbyInfinite, LocationsNearbyInfiniteQueryParams],
		number
	>({
		queryKey: [LocationsQueryKey.LocationsNearbyInfinite, params],
		queryFn: async ({ pageParam }): Promise<LocationsNearbyPage> =>
			await fetchLocationsNearbyPage({
				...params,
				pageNumber: pageParam
			}),
		getNextPageParam: (lastPage): number | null => lastPage.nextPage,
		initialPageParam: 0,
		maxPages: 0,
		refetchOnWindowFocus: false,
		staleTime: 60000 // 1 Minute
	})

	return queryResult
}
