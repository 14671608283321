import { useCallback, useMemo, useState } from 'react'
import { useRouteParams } from 'src/App/router/hooks'
import Notice, { NoticeProps } from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import ArrowLeftIcon from 'src/_shared/components/_icons/ArrowLeftIcon'

import ExtendView from './components/ExtendView'
import PreExtendView from './components/PreExtendView'
import { ReservationExtendScreenViewItemKey } from './enums'
import {
	ReservationExtendScreenRouteParams,
	ReservationExtendScreenUpdateViewFunction,
	ReservationExtendScreenViewItem
} from './types'

// FUTURE TODO: Implement Time-Belt Reservation Flow
const ReservationExtendScreen = (): JSX.Element => {
	const [viewItemKey, setViewItemKey] = useState<ReservationExtendScreenViewItemKey>(
		ReservationExtendScreenViewItemKey.PreExtendView
	)

	const [errorViewProps, setErrorViewProps] = useState<NoticeProps>({})

	const params = useRouteParams<ReservationExtendScreenRouteParams>()

	const updateView: ReservationExtendScreenUpdateViewFunction = useCallback((...args): void => {
		const nextKey = args[0]
		if (nextKey === ReservationExtendScreenViewItemKey.ErrorView) {
			const nextErrorViewProps = args[1]
			if (nextErrorViewProps) {
				setErrorViewProps(nextErrorViewProps)
			}
		}
		setViewItemKey((previousKey): ReservationExtendScreenViewItemKey => {
			console.debug(`[ReservationExtendScreen] Update View: "${previousKey}" > "${nextKey}"`)
			return nextKey
		})
	}, [])

	const viewItemsMap = useMemo((): Record<
		ReservationExtendScreenViewItemKey,
		ReservationExtendScreenViewItem
	> => {
		return {
			[ReservationExtendScreenViewItemKey.PreExtendView]: {
				view: <PreExtendView routeParams={params} updateView={updateView} />
			},
			[ReservationExtendScreenViewItemKey.ExtendView]: {
				view: <ExtendView routeParams={params} updateView={updateView} />
			},
			[ReservationExtendScreenViewItemKey.ErrorView]: {
				view: <Notice {...errorViewProps} />,
				onBack: (): void => {
					updateView(ReservationExtendScreenViewItemKey.PreExtendView)
					setErrorViewProps({})
				}
			}
		}
	}, [errorViewProps, params, updateView])

	const currentViewItem = viewItemsMap[viewItemKey]

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 pt-6'
			}}
			topBarProps={{
				leftRender: currentViewItem.onBack && (
					<TopBarButton data-testid="res-btn-back" onClick={currentViewItem.onBack}>
						<ArrowLeftIcon className="h-4" />
					</TopBarButton>
				)
			}}
			hideBottomBar
		>
			{currentViewItem.view}
		</ScreenContainer>
	)
}

export default ReservationExtendScreen
