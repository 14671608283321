import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'src/_shared/components/Button'

interface ContactCardProps {
	buttonText: string
	buttonLink: string
	buttonPrefixIcon?: JSX.Element | null
	className?: string
}

const ContactCard = ({
	buttonText,
	buttonLink,
	buttonPrefixIcon,
	className = ''
}: ContactCardProps): JSX.Element | null => {
	return (
		<div className={['flex flex-col rounded-lg p-5', className].join(' ').trim()}>
			<div className="mb-3">
				<p className="body-3-semibold mb-2 text-center text-typography-primary">
					<FormattedMessage
						id="ContactCard.TitleUnableToFindAnswer"
						defaultMessage="Unable To Find An Answer?"
					/>
				</p>
				<p className="body-1-normal text-center text-typography-tertiary">
					<FormattedMessage
						id="ContactCard.DescriptionContactUsHere"
						defaultMessage="Contact us here:"
					/>
				</p>
			</div>
			<Button
				data-testid="ahs-btn-contact-us"
				variant="primary"
				className="w-full"
				linkProps={{ rel: 'noreferrer', target: '_blank', to: buttonLink }}
			>
				{buttonPrefixIcon}
				{buttonText}
			</Button>
		</div>
	)
}

const MemoisedContactCard = memo(ContactCard)

export default MemoisedContactCard
