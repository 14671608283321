import { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { classNames } from 'src/_shared/utils/elements'

interface CarouselProps {
	className?: string
	items: ReactNode[]
}

const Carousel = ({ className, items }: CarouselProps): JSX.Element => {
	const [itemIndex, setItemIndex] = useState<number>(0)

	const carouselRef = useRef<HTMLDivElement | null>(null)

	const handleIndicatorClick = useCallback(
		(index: number) => (): void => {
			setItemIndex(index)
		},
		[]
	)

	/**
	 * Event Listeners for Carousel Swipe
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Touch_events}
	 */
	useEffect((): (() => void) => {
		const carouselDivElement = carouselRef.current

		let touchStartX = 0

		const handleTouchStart = (event: TouchEvent): void => {
			touchStartX = event.changedTouches[0].screenX
		}

		const handleTouchEnd = (event: TouchEvent): void => {
			const touchEndX = event.changedTouches[0].screenX
			// Swiped Left
			if (touchEndX < touchStartX) {
				setItemIndex((previousIndex): number => {
					// Ensure that index is within range
					return Math.min(previousIndex + 1, items.length - 1)
				})
			}
			// Swiped Right
			else {
				setItemIndex((previousIndex): number => {
					// Ensure that index is within range
					return Math.max(0, previousIndex - 1)
				})
			}
		}

		carouselDivElement?.addEventListener('touchstart', handleTouchStart)
		carouselDivElement?.addEventListener('touchend', handleTouchEnd)
		return (): void => {
			carouselDivElement?.removeEventListener('touchstart', handleTouchStart)
			carouselDivElement?.removeEventListener('touchend', handleTouchEnd)
		}
	}, [items.length])

	return (
		<div ref={carouselRef} className={classNames('relative', className)}>
			{/* Item View */}
			{items.map((item, index): JSX.Element => {
				const isCurrentItem = index === itemIndex
				return (
					<div
						key={index}
						className={classNames(
							'transition duration-300 ease-in-out',
							isCurrentItem ? 'opacity-100' : 'absolute top-0 w-full opacity-0'
						)}
					>
						{item}
					</div>
				)
			})}
			{/* Item Indicators */}
			{items.length > 0 && (
				<div className="absolute bottom-0 flex w-full items-center justify-center space-x-1">
					{items.map((_item, index): JSX.Element => {
						const isCurrentItem = index === itemIndex
						return (
							<div
								key={index}
								className={classNames(
									'h-2 cursor-pointer rounded-full transition duration-300 ease-in-out',
									isCurrentItem ? 'w-6 bg-primary-800' : 'w-2 bg-grayscale-400'
								)}
								onClick={handleIndicatorClick(index)}
							/>
						)
					})}
				</div>
			)}
		</div>
	)
}

const MemoisedCarousel = memo(Carousel)

export default MemoisedCarousel
