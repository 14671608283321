import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { AuthContext } from 'src/_shared/hooks/useAuthContext'
import { useSessionStorageItem } from 'src/_shared/hooks/useStorageItem'
import { useUserTokenRefreshMutation } from 'src/_shared/mutations/auth'
import { useUserInfoQuery } from 'src/_shared/queries/user'

const ACCESS_TOKEN_KEY = 'accessToken'

/**
 * Manages the storage and renewal of the `accessToken` and `refreshToken`.
 * This wrapper is only used in `default` and `sso` App Modes.
 */
const AuthWrapper = ({ children }: PropsWithChildren): JSX.Element | null => {
	const [accessToken, setAccessToken] = useSessionStorageItem(ACCESS_TOKEN_KEY)

	const [isFirstRefreshEnabled, setIsFirstRefreshEnabled] = useState<boolean>(!accessToken)

	const queryClient = useQueryClient()

	const clearAuthTokens = useCallback((): void => {
		setAccessToken(null)
	}, [setAccessToken])

	const { isPending: isTokenRefreshLoading, mutateAsync: refresh } = useUserTokenRefreshMutation({
		onError: (): void => {
			clearAuthTokens()
		},
		retry: false
	})

	/**
	 * Attempts to get a new `accessToken` with the `refreshToken` cookie.
	 * @returns {Promise<boolean>} If `true`, then the refresh was successful and the new `accessToken` has been stored.
	 */
	const refreshAuthTokens = useCallback(async (): Promise<boolean> => {
		try {
			const response = await refresh()
			if (response.data.token) {
				setAccessToken(response.data.token)
				return true
			}
		} catch (error) {
			console.warn('[AuthWrapper] Unable to Refresh Authentication Tokens:', error)
		}
		return false
	}, [refresh, setAccessToken])

	/**
	 * Common retry handler for queries and mutations that has errors raised.
	 */
	const handleRetryOnError = useCallback(
		(failureLimit = 3) =>
			(failureCount: number, error: Error): boolean => {
				const axiosError = error as AxiosError<{ message: string }>
				switch (axiosError.response?.status) {
					// Unauthorised; Attempt to refresh token.
					case 401: {
						void refreshAuthTokens()
						return false
					}
					// Retry depending on configured `failureLimit`.
					default:
						return failureCount < failureLimit
				}
			},
		[refreshAuthTokens]
	)

	const { data: user = null } = useUserInfoQuery(
		{ accessToken: accessToken ?? '' },
		{
			enabled: !!accessToken,
			placeholderData: keepPreviousData,
			staleTime: Infinity,
			retry: handleRetryOnError(1)
		}
	)
	/**
	 * Attempt to automatically refresh the authentication state on first load if there is no `accessToken`
	 * or when the window is brought back into view (e.g. user opens a separate tab and returns).
	 * This is done because it's not possible to check for the presence of the `refreshToken` cookie and
	 * to also ensure the user state is correct across different windows/tabs that is accessing the application.
	 */
	useEffect((): (() => void) => {
		const handleRefresh = (): void => {
			// Prevent refresh when the `AccountPaymentMethodsNewScreen` is rendered
			// as the `iframe` will cause the window to lose focus.
			const hasStripeIframeElement = !!document.querySelector('div#root div.StripeElement')
			if (document.visibilityState === 'visible' && !hasStripeIframeElement) {
				void refreshAuthTokens()
			}
		}
		if (isFirstRefreshEnabled) {
			setIsFirstRefreshEnabled(false)
			handleRefresh()
		}
		window.addEventListener('focus', handleRefresh)
		window.addEventListener('visibilitychange', handleRefresh)
		return (): void => {
			window.removeEventListener('focus', handleRefresh)
			window.removeEventListener('visibilitychange', handleRefresh)
		}
	}, [isFirstRefreshEnabled, refreshAuthTokens])

	/**
	 * Add default auth error retry handler to all mutations and queries.
	 */
	useEffect((): void => {
		const { mutations: mutationsOptions, queries: queriesOptions } = queryClient.getDefaultOptions()
		queryClient.setDefaultOptions({
			mutations: {
				...mutationsOptions,
				retry: accessToken ? handleRetryOnError(0) : undefined
			},
			queries: {
				...queriesOptions,
				retry: accessToken ? handleRetryOnError() : undefined
			}
		})
	}, [accessToken, queryClient, handleRetryOnError])

	return (
		<AuthContext.Provider
			value={{
				accessToken,
				isAuthenticated: !!accessToken,
				isTokenRefreshLoading,
				user,
				clearAuthTokens,
				setAccessToken
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthWrapper
