import { QueryStatus } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { OmniEvseCapability, OmniReservationType } from 'src/_shared/enums/omni'
import { useLocationEvseConnectorQuery, useLocationQuery } from 'src/_shared/queries/locations'
import { useTariffQuery } from 'src/_shared/queries/tariff'
import { OmniConnector, OmniEvse, OmniTariff, OmniLocation } from 'src/_shared/types/omni'

// 10 Seconds
const DEFAULT_REFETCH_INTERVAL = 10000

interface UseChargerDetailsArgs {
	cpoEntityCode: string
	locationUid: string
	evseUid: string
	connectorUid: string
	/**
	 * If `true`, the `activeTariff` will be queried and polled.
	 */
	enableActiveTariffQuery?: boolean
	/**
	 * If `true`, relevant queries will refetch based on the `DEFAULT_REFETCH_INTERVAL`.
	 */
	enableRefetch?: boolean
}

interface ChargerNonReservableDetails {
	isReservationCapable: false
}

interface ChargerReservableDetails {
	isReservationCapable: true
	/**
	 * If `true`, then walk-in charging is not allowed.
	 */
	isReservationRequired: boolean
	/**
	 * If `null`, then the reservation flow is not possible (i.e. Reserve Now).
	 */
	reservationType: OmniReservationType.Timebelt | null
}

type ChargerDetails = {
	location: OmniLocation | null
	/**
	 * The target `evse` that is obtained from `location`.
	 */
	evse: OmniEvse | null
	/**
	 * The target `connector` that is either obtained via query or from `location`.
	 */
	connector: OmniConnector | null
	/**
	 * The `activeTariff` that is either obtained via query or from `connector`.
	 */
	activeTariff: OmniTariff | null
	locationError: AxiosError<{ message: string }> | null
	connectorError: AxiosError<{ message: string }> | null
	locationQueryStatus: QueryStatus
	connectorQueryStatus: QueryStatus
	activeTariffQueryStatus: QueryStatus
} & (ChargerNonReservableDetails | ChargerReservableDetails)

/**
 * Queries for the most up-to-date information of the specified charger.
 * @param {UseChargerDetailsArgs} UseChargerDetailsArgs `{ locationUid, evseUid, connectorUid, enableActiveTariffQuery }`
 * @returns {ChargerDetails} The `location`, `evse`, `connector`, and other related query information.
 */
export const useChargerDetails = ({
	locationUid,
	evseUid,
	connectorUid,
	enableActiveTariffQuery = false,
	enableRefetch = true
}: UseChargerDetailsArgs): ChargerDetails => {
	const {
		data: location = null,
		error: locationError,
		status: locationQueryStatus
	} = useLocationQuery(
		{ locationUid },
		{
			enabled: !!locationUid,
			staleTime: Infinity
		}
	)

	const evseFromLocation = useMemo((): OmniEvse | null => {
		const evse = location?.evses?.find((evse): boolean => {
			return evse.uid === evseUid
		})
		return evse ?? null
	}, [evseUid, location])

	const connectorFromLocationEvse = useMemo((): OmniConnector | null => {
		const connector = evseFromLocation?.connectors?.find((connector): boolean => {
			return connector.uid === connectorUid
		})
		return connector ?? null
	}, [connectorUid, evseFromLocation])

	// The `connector` from this query should be more up-to-date than the one returned via `location`.
	const {
		data: connectorFromQuery = null,
		error: connectorError,
		status: connectorQueryStatus
	} = useLocationEvseConnectorQuery(
		{ locationUid, evseUid, connectorUid },
		{
			enabled: locationQueryStatus === 'success',
			retry: false,
			refetchInterval: (query): false | number => {
				// Disable refetching if it was disabled or if there is still no data after repeated errors
				if (!enableRefetch || (!query.state.data && query.state.errorUpdateCount > 0)) {
					return false
				}
				return DEFAULT_REFETCH_INTERVAL
			},
			refetchOnWindowFocus: (query): boolean => {
				// Disable refetching if it was disabled or if there is still no data after repeated errors
				if (!enableRefetch || (!query.state.data && query.state.errorUpdateCount > 0)) {
					return false
				}
				return true
			},
			refetchIntervalInBackground: enableRefetch
		}
	)

	const connector = useMemo((): OmniConnector | null => {
		return connectorFromQuery ?? connectorFromLocationEvse
	}, [connectorFromQuery, connectorFromLocationEvse])

	// The `activeTariff` from this query should be more up-to-date than the one returned via `connector`.
	const { data: activeTariffFromQuery = null, status: activeTariffQueryStatus } = useTariffQuery(
		{
			applyPriceModifier: true,
			tariffUid: connector?.active_tariff?.uid ?? ''
		},
		{
			enabled: !!connector?.active_tariff?.uid && enableActiveTariffQuery,
			refetchOnMount: enableRefetch ? 'always' : false,
			refetchInterval: enableRefetch ? DEFAULT_REFETCH_INTERVAL : false,
			refetchIntervalInBackground: enableRefetch
		}
	)

	const activeTariff = useMemo((): OmniTariff | null => {
		return activeTariffFromQuery ?? connector?.active_tariff ?? null
	}, [activeTariffFromQuery, connector?.active_tariff])

	const reservableDetails = useMemo((): ChargerReservableDetails | null => {
		const reservationCapabilities = evseFromLocation?.capabilities?.filter(
			(capability): boolean => {
				return [
					OmniEvseCapability.Reservable,
					OmniEvseCapability.ReservationRequired,
					OmniEvseCapability.TimebeltReservable
				].includes(capability)
			}
		)
		if (reservationCapabilities?.length) {
			const isReservationRequired = reservationCapabilities.includes(
				OmniEvseCapability.ReservationRequired
			)
			const isTimebeltReservable = reservationCapabilities.includes(
				OmniEvseCapability.TimebeltReservable
			)
			return {
				isReservationCapable: true,
				isReservationRequired,
				reservationType: isTimebeltReservable ? OmniReservationType.Timebelt : null
			}
		}
		return null
	}, [evseFromLocation?.capabilities])

	if (reservableDetails) {
		return {
			location,
			evse: evseFromLocation,
			connector,
			activeTariff,
			locationError,
			connectorError,
			locationQueryStatus,
			connectorQueryStatus,
			activeTariffQueryStatus,
			...reservableDetails
		}
	}
	return {
		location,
		evse: evseFromLocation,
		connector,
		activeTariff,
		locationError,
		connectorError,
		locationQueryStatus,
		connectorQueryStatus,
		activeTariffQueryStatus,
		isReservationCapable: false
	}
}
