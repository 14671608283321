import {
	InfiniteData,
	UseInfiniteQueryResult,
	UseQueryOptions,
	useInfiniteQuery,
	useQuery
} from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { OmniLocation, OmniSession } from 'src/_shared/types/omni'

import { CPO_BACKEND_URL } from '../constants/env'
import { useAuthContext } from '../hooks/useAuthContext'
import { UserInfo } from '../types/user'

export const ROOT_USER_QUERY_KEY = 'User'

const DEFAULT_LOCATIONS_LIMIT = 20

export enum UserQueryKey {
	UserInfo = 'UserInfo',
	UserSessionLatest = 'UserSessionLatest',
	UserFavouritesInfiniteLocations = 'UserFavouritesInfiniteLocations',
	UserFavouritesLocationSummary = 'UserFavouritesLocationSummary'
}

interface UserInfoQueryParams {
	accessToken: string
}

export const useUserInfoQuery = <TData = UserInfo>(
	params: UserInfoQueryParams,
	options?: Omit<
		UseQueryOptions<
			UserInfo,
			AxiosError<{ message: string }>,
			TData,
			[string, UserQueryKey.UserInfo, UserInfoQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserInfo, params],
		queryFn: async (): Promise<UserInfo> => {
			try {
				const response = await axios.get<UserInfo>(`${CPO_BACKEND_URL}/v2/me`, {
					headers: {
						Authorization: params.accessToken
					}
				})
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserSessionLatestQueryParams {
	/**
	 * If `true`, this forces a refresh of the current charger's status from the CPO CMS.
	 * This would cause the request to be slower, but it should guarantee the return the latest data.
	 */
	isRealTime?: boolean
}

interface UserSessionLatestResponse {
	data: {
		session: OmniSession | null
	} | null
	message: string
	status: number
}

export const useUserSessionLatestQuery = <TData = OmniSession | null>(
	params: UserSessionLatestQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniSession | null,
			AxiosError<UserSessionLatestResponse>,
			TData,
			[string, UserQueryKey.UserSessionLatest, string | null]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	const { accessToken, isAuthenticated } = useAuthContext()
	return useQuery({
		...options,
		queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserSessionLatest, accessToken],
		enabled: (options?.enabled === undefined || options.enabled) && isAuthenticated,
		queryFn: async (): Promise<OmniSession | null> => {
			try {
				const { isRealTime = false } = params
				const response = await axios.get<UserSessionLatestResponse>(
					`${CPO_BACKEND_URL}/v2/me/sessions/latest`,
					{
						headers: { Authorization: accessToken },
						params: {
							is_real_time: isRealTime
						}
					}
				)
				return response.data.data?.session ?? null
			} catch (error) {
				const axiosError = error as AxiosError<UserSessionLatestResponse>
				if (axiosError.response?.status === 404 && axiosError.response.data.data === null) {
					return null
				}
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserFavouriteLocationSummary {
	countryCode: string
	latitude: string
	longitude: string
	locationUid: string
}

interface UserFavouriteLocationsQueryParams {
	country_code?: string
	limit?: number
	pageNumber?: number
}

export const useUserFavouriteLocationsSummaryQuery = <TData = UserFavouriteLocationSummary[]>(
	params: UserFavouriteLocationsQueryParams,
	options?: Omit<
		UseQueryOptions<
			UserFavouriteLocationSummary[],
			AxiosError<{ message: string }>,
			TData,
			[
				string,
				UserQueryKey.UserFavouritesLocationSummary,
				string | null,
				UserFavouriteLocationsQueryParams
			]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	const { accessToken, isAuthenticated } = useAuthContext()
	return useQuery({
		...options,
		queryKey: [
			ROOT_USER_QUERY_KEY,
			UserQueryKey.UserFavouritesLocationSummary,
			accessToken,
			params
		],
		enabled: (options?.enabled === undefined || options.enabled) && isAuthenticated,
		queryFn: async (): Promise<UserFavouriteLocationSummary[]> => {
			try {
				const response = await axios.get<UserFavouriteLocationSummary[]>(
					`${CPO_BACKEND_URL}/v2/me/favourites/summary`,
					{
						params,
						headers: {
							Authorization: accessToken
						}
					}
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

export interface FavouriteLocationsPage {
	data: OmniLocation[]
	currentPage: number
	nextPage: number | null
}

const fetchFavouritesLocationsPage = async (
	{
		country_code,
		pageNumber = 0,
		limit = DEFAULT_LOCATIONS_LIMIT
	}: UserFavouriteLocationsQueryParams,
	accessToken: string | null
): Promise<FavouriteLocationsPage> => {
	try {
		const response = await axios.get<OmniLocation[]>(`${CPO_BACKEND_URL}/v2/me/favourites`, {
			params: {
				country_code,
				limit,
				offset: pageNumber * limit
			},
			headers: {
				Authorization: accessToken
			}
		})

		const locations = response.data

		// if the data returned from the backend is lesser than the number of items to be specified in one page, there is no next page
		const hasNextPage = locations.length === limit

		const favouriteLocationsPage: FavouriteLocationsPage = {
			data: locations,
			currentPage: pageNumber,
			nextPage: hasNextPage ? pageNumber + 1 : null
		}

		return favouriteLocationsPage
	} catch (error) {
		const axiosError = error as AxiosError<{ message: string }>
		return Promise.reject(axiosError)
	}
}

export const useUserFavouriteLocationsInfiniteQuery = (
	params: UserFavouriteLocationsQueryParams
): UseInfiniteQueryResult<
	InfiniteData<FavouriteLocationsPage, number>,
	AxiosError<UserFavouriteLocationsQueryParams>
> => {
	const { accessToken, isAuthenticated } = useAuthContext()

	const queryResult: UseInfiniteQueryResult<
		InfiniteData<FavouriteLocationsPage, number>,
		AxiosError<UserFavouriteLocationsQueryParams>
	> = useInfiniteQuery<
		FavouriteLocationsPage,
		AxiosError<UserFavouriteLocationsQueryParams>,
		InfiniteData<FavouriteLocationsPage, number>,
		[
			string,
			UserQueryKey.UserFavouritesInfiniteLocations,
			string | null,
			UserFavouriteLocationsQueryParams
		],
		number
	>({
		queryKey: [
			ROOT_USER_QUERY_KEY,
			UserQueryKey.UserFavouritesInfiniteLocations,
			accessToken,
			params
		],
		queryFn: async ({ pageParam }): Promise<FavouriteLocationsPage> =>
			await fetchFavouritesLocationsPage(
				{
					...params,
					pageNumber: pageParam
				},
				accessToken
			),
		getNextPageParam: (lastPage): number | null => lastPage.nextPage,
		enabled: isAuthenticated,
		initialPageParam: 0,
		maxPages: 0,
		refetchOnWindowFocus: false
	})

	return queryResult
}
