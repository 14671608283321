import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo } from 'react'
import Button from 'src/_shared/components/Button'
import Input from 'src/_shared/components/Input'
import SelectCountry from 'src/_shared/components/SelectCountry'
import TelephoneIcon from 'src/_shared/components/_icons/TelephoneIcon'
import {
	useUserMobileNumberOtpRequestMutation,
	useUserVerifyMobileNumberMutation
} from 'src/_shared/mutations/auth'
import isMobilePhone from 'validator/lib/isMobilePhone'

import AccountScreenHeader from '../../../_shared/components/AccountScreenHeader'
import { FormFieldKey } from '../enums'
import { AccountForgotPasswordScreenCommonViewProps } from '../types'

type SelectOtpMethodViewProps = AccountForgotPasswordScreenCommonViewProps

const DEBOUNCE_CHECK_DELAY = 375

// FUTURE TODO: Handle email method.
const SelectOtpMethodView = ({
	formValues,
	setFormValues,
	onNext: handleNext
}: SelectOtpMethodViewProps): JSX.Element => {
	const formattedMobileNumber =
		formValues[FormFieldKey.CountryCode] + formValues[FormFieldKey.MobileNumber]

	const {
		data: verifyMobileNumberResponse,
		error: userVerifyMobileNumberError,
		isError: isUserVerifyMobileNumberError,
		isPending: isUserVerifyMobileNumberPending,
		isSuccess: isUserVerifyMobileNumberSuccess,
		mutateAsync: checkUserVerifyMobileNumber,
		reset: resetUserVerifyMobileNumberMutation
	} = useUserVerifyMobileNumberMutation()

	const {
		error: requestOtpError,
		isError: isRequestOtpError,
		isPending: isRequestOtpPending,
		mutateAsync: requestOtp,
		reset: resetUserOtpRequestMutation
	} = useUserMobileNumberOtpRequestMutation()

	const isNotRegisteredMobileNumber =
		isUserVerifyMobileNumberSuccess && !verifyMobileNumberResponse.hasUser

	const errorMessage = useMemo((): string | undefined => {
		if (!!formValues[FormFieldKey.MobileNumber] && !isMobilePhone(formattedMobileNumber)) {
			return `${formattedMobileNumber} is not a valid mobile number`
		} else if (isNotRegisteredMobileNumber) {
			return 'Mobile number has not been whitelisted by fleet'
		} else if (isUserVerifyMobileNumberError || isRequestOtpError) {
			const errorMessage = (userVerifyMobileNumberError ?? requestOtpError)?.response?.data.message
			return errorMessage ? errorMessage : 'Oops! Something went wrong'
		}
	}, [
		formValues,
		formattedMobileNumber,
		isNotRegisteredMobileNumber,
		isRequestOtpError,
		isUserVerifyMobileNumberError,
		requestOtpError,
		userVerifyMobileNumberError
	])

	const isFormValid = useMemo((): boolean => {
		const isRegisteredMobileNumber =
			isUserVerifyMobileNumberSuccess && verifyMobileNumberResponse.hasUser
		return isMobilePhone(formattedMobileNumber) && isRegisteredMobileNumber && !errorMessage
	}, [
		errorMessage,
		formattedMobileNumber,
		isUserVerifyMobileNumberSuccess,
		verifyMobileNumberResponse
	])

	const handleFormChange = useCallback(
		(key: FormFieldKey) =>
			(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>): void => {
				const formattedValue = ((): string => {
					const formValue = event.currentTarget.value
					switch (key) {
						case FormFieldKey.MobileNumber:
							return formValue.replace(/[^0-9]/, '')
						default:
							return formValue.replace(/\s/g, '')
					}
				})()
				setFormValues(
					(values): Record<FormFieldKey, string> => ({
						...values,
						[key]: formattedValue
					})
				)
				if (key === FormFieldKey.MobileNumber) {
					resetUserVerifyMobileNumberMutation()
				}
				if (isRequestOtpError) {
					resetUserOtpRequestMutation()
				}
			},
		[
			isRequestOtpError,
			resetUserOtpRequestMutation,
			resetUserVerifyMobileNumberMutation,
			setFormValues
		]
	)

	const handleNextClick = useCallback(
		(event: FormEvent<HTMLFormElement>): void => {
			// Prevent default form submission behaviour.
			event.preventDefault()

			if (isFormValid) {
				void requestOtp(
					{
						mobileNumber: formattedMobileNumber
					},
					{
						onSuccess: (): void => {
							resetUserOtpRequestMutation()
							handleNext?.()
						}
					}
				)
			}
		},
		[formattedMobileNumber, isFormValid, handleNext, requestOtp, resetUserOtpRequestMutation]
	)

	/**
	 * Mobile Phone Eligibility Check
	 */
	useEffect((): (() => void) | undefined => {
		if (isMobilePhone(formattedMobileNumber)) {
			const timeoutId = setTimeout((): void => {
				void checkUserVerifyMobileNumber({
					mobileNumber: formattedMobileNumber
				})
			}, DEBOUNCE_CHECK_DELAY)
			return (): void => {
				clearTimeout(timeoutId)
			}
		}
	}, [formattedMobileNumber, checkUserVerifyMobileNumber])

	return (
		<form className="flex flex-grow flex-col" onSubmit={handleNextClick}>
			<div className="flex flex-grow flex-col space-y-5">
				<AccountScreenHeader
					data-testid="afps-somv-label-forgot-password"
					className="mb-2"
					title="Forgot password"
					subTitle="No worries, we'll send you the reset instructions."
				/>
				<Input
					dataTestIdPrefix="afps-somv-mobile-number"
					autoComplete="on"
					autoFocus={true}
					type="tel"
					placeholder="Enter your mobile number"
					id={FormFieldKey.MobileNumber}
					name={FormFieldKey.MobileNumber}
					value={formValues[FormFieldKey.MobileNumber]}
					onChange={handleFormChange(FormFieldKey.MobileNumber)}
					description={errorMessage}
					error={!!errorMessage}
					disabled={isRequestOtpPending}
					startAdornment={
						<div className="flex flex-row items-center space-x-3 py-1 pl-1">
							<TelephoneIcon className="h-5 w-5 text-typography-secondary" />
							<SelectCountry
								variant="none"
								id={FormFieldKey.CountryCode}
								name={FormFieldKey.CountryCode}
								value={formValues[FormFieldKey.CountryCode]}
								onChange={handleFormChange(FormFieldKey.CountryCode)}
								disabled={isRequestOtpPending}
							/>
						</div>
					}
				/>
			</div>
			<div className="flex flex-col">
				<Button
					data-testid="afps-somv-btn-next"
					variant="primary"
					disabled={!isFormValid || isRequestOtpPending}
					loading={isUserVerifyMobileNumberPending || isRequestOtpPending}
				>
					Next
				</Button>
			</div>
		</form>
	)
}

export default SelectOtpMethodView
