import { memo, ReactNode } from 'react'

const TngCarouselItem = ({
	title,
	description,
	imageUrl
}: {
	title: ReactNode
	description: ReactNode
	imageUrl: string
}): JSX.Element => {
	return (
		<div className="flex flex-col justify-center">
			<img src={imageUrl} className="mb-[-24px] h-64 w-auto" />
			<div className="min-h-44">
				<h4 className="mb-4 text-center text-typography-primary">{title}</h4>
				<div className="body-1-normal text-center text-typography-primary">{description}</div>
			</div>
		</div>
	)
}

const MemoisedTngCarouselItem = memo(TngCarouselItem)

export default MemoisedTngCarouselItem
