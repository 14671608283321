import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { CPO_BACKEND_URL } from '../constants/env'
import { OmniTariff } from '../types/omni'

export const ROOT_TARIFF_QUERY_KEY = 'Tariff'

export enum TariffQueryKey {
	Tariff = 'Tariff'
}

interface TariffQueryParams {
	applyPriceModifier?: boolean
	tariffUid: string
}

export const useTariffQuery = <TData = OmniTariff>(
	params: TariffQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniTariff,
			AxiosError<{ message: string }>,
			TData,
			[string, TariffQueryKey.Tariff, TariffQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_TARIFF_QUERY_KEY, TariffQueryKey.Tariff, params],
		queryFn: async (): Promise<OmniTariff> => {
			try {
				const { applyPriceModifier = false, tariffUid } = params
				const response = await axios.get<OmniTariff>(`${CPO_BACKEND_URL}/v3/tariffs/${tariffUid}`, {
					params: { apply_price_modifier: applyPriceModifier }
				})
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}
