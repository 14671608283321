import { ReactNode, memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { ScreenRoutePath } from 'src/App/router/types'
import { classNames } from 'src/_shared/utils/elements'

interface TermsAndConditionsBottomRenderProps {
	className?: string
	prefixRender?: ReactNode
}

const TermsAndConditionsBottomRender = ({
	className,
	prefixRender
}: TermsAndConditionsBottomRenderProps): JSX.Element => {
	return (
		<p className={classNames('body-1-normal text-center text-typography-tertiary', className)}>
			{prefixRender}
			<Link data-testid="cs-tcbr-link-terms-and-conditions" to={ScreenRoutePath.TermsAndConditions}>
				<FormattedMessage
					id="TermsAndConditionsBottomRender.FooterLinkTermsAndConditions"
					defaultMessage="Terms & Conditions"
				/>
			</Link>
		</p>
	)
}

const MemoisedTermsAndConditionsBottomRender = memo(TermsAndConditionsBottomRender)

export default MemoisedTermsAndConditionsBottomRender
