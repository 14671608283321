import { memo, useCallback, useMemo, useState } from 'react'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import CloseIcon from 'src/_shared/components/_icons/CloseIcon'
import { DISABLE_SUBSCRIPTION } from 'src/_shared/constants/env'
import {
	ConnectorPowerType,
	LocationFilterModalType,
	SubscriptionStatus
} from 'src/_shared/enums/filter'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { useLocationFiltersModalContext } from 'src/_shared/hooks/useLocationFiltersModalContext'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { classNames } from 'src/_shared/utils/elements'

interface LocationFilterModalProps {
	open: boolean
	filterModalType: LocationFilterModalType | null
}

const IS_SUBSCRIPTION_ENABLED = !DISABLE_SUBSCRIPTION

/**
 * Colours for Filter Button
 */
const SELECTED_COLOUR = 'border-primary-800 bg-primary-800 text-grayscale-100'

/**
 * Colours for Filter Button
 */
const UNSELECTED_COLOUR = 'border-divider-primary bg-white text-modal-typography-primary'

const LocationFilterModal = ({ open, filterModalType }: LocationFilterModalProps): JSX.Element => {
	const { hideModal } = useLocationFiltersModalContext()

	const { locationFilters, setFilters } = useLocationFilters()

	const [selectedCpoEntityCodes, setSelectedCpoEntityCodes] = useState<string[] | null>(
		locationFilters.cpoEntityCodes
	)

	const [isFavouritesOnly, setIsFavouritesOnly] = useState<boolean>(locationFilters.favouritesOnly)

	const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | null>(
		locationFilters.subscriptionStatus
	)

	const [selectedPowerType, setSelectedPowerType] = useState<ConnectorPowerType | null>(
		locationFilters.powerType
	)

	const { brandData } = useStrapiContext()

	const { isAuthenticated } = useAuthContext()

	const cpoOptionsList = useMemo(() => {
		return brandData?.attributes.configuration?.cpoOptions ?? []
	}, [brandData?.attributes.configuration?.cpoOptions])

	const handleClose = useCallback((): void => {
		hideModal()
	}, [hideModal])

	const handleClearFiltersClick = useCallback((): void => {
		// Reset the filters and close the modal when the clear button is clicked
		setFilters(null)
		handleClose()
	}, [handleClose, setFilters])

	const handleCpoClick = useCallback(
		(cpo = '') =>
			(): void => {
				setSelectedCpoEntityCodes((previousCpos): string[] | null => {
					if (previousCpos === null) {
						return [cpo]
					}
					// If the cpo is already selected, remove it
					if (previousCpos.includes(cpo)) {
						const filtered = previousCpos.filter((previousCpo): boolean => previousCpo !== cpo)
						return filtered.length === 0 ? null : filtered
					}
					// else add it
					return [...previousCpos, cpo]
				})
			},
		[]
	)

	const handleFavouritesOnlyToggleClick = useCallback((): void => {
		setIsFavouritesOnly((value): boolean => !value)
	}, [])

	const handleSubscriptionStatusClick = useCallback(
		(subscriptionStatus: SubscriptionStatus) => (): void => {
			setSubscriptionStatus((previousSubscriptionStatus): SubscriptionStatus | null => {
				if (previousSubscriptionStatus === subscriptionStatus) {
					return null
				}
				return subscriptionStatus
			})
		},
		[]
	)

	const handlePowerTypeFilterClick = useCallback(
		(powerType: ConnectorPowerType) => (): void => {
			setSelectedPowerType((prev): ConnectorPowerType | null => {
				if (prev === powerType) {
					return null
				}
				return powerType
			})
		},
		[]
	)

	const handleApplyFiltersClick = useCallback((): void => {
		setFilters({
			cpoEntityCodes: selectedCpoEntityCodes,
			favouritesOnly: isFavouritesOnly,
			powerType: selectedPowerType,
			subscriptionStatus
		})
		handleClose()
	}, [
		isFavouritesOnly,
		selectedCpoEntityCodes,
		selectedPowerType,
		subscriptionStatus,
		handleClose,
		setFilters
	])

	return (
		<Modal open={open} onClose={handleClose}>
			<ModalCard className="relative">
				<button
					className="absolute right-4 top-4 flex h-8 w-8 items-center justify-center"
					onClick={handleClose}
				>
					<CloseIcon className="h-4 w-4 text-grayscale-600" />
				</button>
				<div className="flex max-h-[70vh] flex-col space-y-5 px-1">
					<div className="flex w-full items-center justify-between">
						<h1 className="text-modal-typography-primary">Filter</h1>
					</div>
					<div className="w-full space-y-5 overflow-scroll">
						{/* Charge Point Operators */}
						<div className="flex w-full flex-col space-y-1">
							<p className="body-1-medium text-modal-typography-secondary">Charge Point Operator</p>
							<div className="flex w-full flex-row flex-wrap justify-start pt-1">
								{cpoOptionsList.map(
									(cpo, index): JSX.Element => (
										<button
											key={index}
											className={classNames(
												'mb-2 mr-2 rounded-md border px-2 py-0.5',
												selectedCpoEntityCodes !== null &&
													cpo.entityCode &&
													selectedCpoEntityCodes.includes(cpo.entityCode)
													? SELECTED_COLOUR
													: UNSELECTED_COLOUR
											)}
											onClick={handleCpoClick(cpo.entityCode)}
										>
											<p className="body-1-medium">{cpo.name ?? '-'}</p>
										</button>
									)
								)}
							</div>
						</div>
						{filterModalType === LocationFilterModalType.All && (
							<>
								{/* Favourites */}
								{isAuthenticated && (
									<div className="flex flex-col space-y-1">
										<p className="body-1-medium text-modal-typography-secondary">Favourites</p>
										<div className="flex flex-row justify-start space-x-2 pt-1">
											<button
												className={classNames(
													'rounded-md border px-2 py-0.5',
													isFavouritesOnly ? SELECTED_COLOUR : UNSELECTED_COLOUR
												)}
												onClick={handleFavouritesOnlyToggleClick}
											>
												<p className="body-1-medium">Favourites Only</p>
											</button>
										</div>
									</div>
								)}
								{/* Subscription */}
								{isAuthenticated && IS_SUBSCRIPTION_ENABLED && (
									<div className="flex flex-col space-y-1">
										<p className="body-1-medium text-modal-typography-secondary">User Type</p>
										<div className="flex flex-row justify-start space-x-2 pt-1">
											<button
												className={classNames(
													'rounded-md border px-2 py-0.5',
													subscriptionStatus === SubscriptionStatus.Subscribed
														? SELECTED_COLOUR
														: UNSELECTED_COLOUR
												)}
												onClick={handleSubscriptionStatusClick(SubscriptionStatus.Subscribed)}
											>
												<p className="body-1-medium">Subscribed</p>
											</button>
											<button
												className={classNames(
													'rounded-md border px-2 py-0.5',
													subscriptionStatus === SubscriptionStatus.NotSubscribed
														? SELECTED_COLOUR
														: UNSELECTED_COLOUR
												)}
												onClick={handleSubscriptionStatusClick(SubscriptionStatus.NotSubscribed)}
											>
												<p className="body-1-medium">Non-Subscribed</p>
											</button>
										</div>
									</div>
								)}
								{/* Power Type */}
								<div className="mb-3 flex w-full flex-col space-y-1 pb-2 pt-3">
									<p className="body-1-medium text-modal-typography-secondary">Power Type</p>
									<div className="flex w-full flex-row justify-start space-x-2 pt-1">
										<button
											className={classNames(
												'rounded-md border px-2 py-0.5',
												selectedPowerType === ConnectorPowerType.Ac
													? SELECTED_COLOUR
													: UNSELECTED_COLOUR
											)}
											onClick={handlePowerTypeFilterClick(ConnectorPowerType.Ac)}
										>
											<p className="body-1-medium">AC Only</p>
										</button>
										<button
											className={classNames(
												'rounded-md border px-2 py-0.5',
												selectedPowerType === ConnectorPowerType.Dc
													? SELECTED_COLOUR
													: UNSELECTED_COLOUR
											)}
											onClick={handlePowerTypeFilterClick(ConnectorPowerType.Dc)}
										>
											<p className="body-1-medium">DC Only</p>
										</button>
									</div>
								</div>
							</>
						)}
					</div>
					<div className="flex flex-row space-x-2">
						<Button
							data-testid="fm-btn-apply-filter"
							className="w-full"
							onClick={handleApplyFiltersClick}
						>
							Apply
						</Button>
						<Button
							variant="secondary"
							data-testid="fm-btn-clear-filter"
							className="w-full"
							onClick={handleClearFiltersClick}
						>
							Clear
						</Button>
					</div>
				</div>
			</ModalCard>
		</Modal>
	)
}

const MemoisedLocationFilterModal = memo(LocationFilterModal)

export default MemoisedLocationFilterModal
