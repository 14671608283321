export enum ReservationChargerScreenViewItemKey {
	ConfirmationView = 'ConfirmationView',
	PaymentMethodsView = 'PaymentMethodsView',
	PreReservationView = 'PreReservationView',
	ReservationView = 'ReservationView',
	ErrorView = 'ErrorView'
}

export enum FormFieldKey {
	/**
	 * In minutes
	 */
	Duration = 'duration',
	/**
	 * In Epoch Unix Timestamp (milliseconds)
	 */
	StartTime = 'startTime'
}
