import { useIntl } from 'react-intl'
import ReceiptDetails from 'src/_shared/components/ReceiptDetails'
import Spinner from 'src/_shared/components/Spinner'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'
import { classNames } from 'src/_shared/utils/elements'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import Footer from './Footer'

type ReceiptViewProps = ChargerScreenCommonViewProps

const ReceiptView = ({ routeParams, onNext: handleNext }: ReceiptViewProps): JSX.Element => {
	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession, chargerSessionDetailsQueryStatus } =
		useChargerSessionDetails(routeParams)

	const isChargerSessionDetailsPending = chargerSessionDetailsQueryStatus === 'pending'

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.ReceiptView
	})

	return (
		<>
			<div
				className={classNames(
					'flex-grow',
					isChargerSessionDetailsPending ? 'flex flex-col items-center justify-center' : null
				)}
			>
				{session && <ReceiptDetails chargingSession={session} />}
				{isChargerSessionDetailsPending && <Spinner />}
			</div>
			<Footer
				className="print:hidden"
				buttonProps={{
					children: intl.formatMessage({
						id: 'ReceiptView.ButtonTextClose',
						defaultMessage: 'Close'
					}),
					className: 'w-full',
					disabled: isChargerSessionDetailsPending,
					variant: 'ghost',
					onClick: handleNext
				}}
			/>
		</>
	)
}

export default ReceiptView
