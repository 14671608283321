import { ReactNode, memo } from 'react'
import Button, { ButtonProps } from 'src/_shared/components/Button'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import SwipeableButton, { SwipeableButtonProps } from './SwipeableButton'

interface FooterButtonProps {
	buttonType?: undefined
	buttonProps: Omit<ButtonProps, 'ref'>
}

interface FooterSwipeableButtonProps {
	buttonType: 'swipeable'
	buttonProps: Omit<SwipeableButtonProps, 'ref'>
}

type FooterProps = {
	className?: string
	/**
	 * Primarily used for the charger status text.
	 */
	topRender?: ReactNode
	/**
	 * Primarily used for the footer elements below the main `Button`.
	 */
	bottomRender?: ReactNode
} & (FooterButtonProps | FooterSwipeableButtonProps)

const Footer = ({
	buttonProps,
	buttonType,
	className,
	topRender,
	bottomRender
}: FooterProps): JSX.Element => {
	return (
		<div
			className={classNames(
				'sticky bottom-0 flex flex-col items-center bg-gradient-to-b from-grayscale-100/90 to-grayscale-100 py-6',
				className
			)}
		>
			{topRender}
			{buttonType === 'swipeable' ? (
				<SwipeableButton dataTestIdPrefix="cs-footer" {...buttonProps} />
			) : (
				<Button
					data-testid={formatDataTestId(['cs-footer-btn', buttonProps['data-testid']])}
					{...buttonProps}
				/>
			)}
			{bottomRender}
		</div>
	)
}

const MemoisedFooter = memo(Footer)

export default MemoisedFooter
