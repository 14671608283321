import { LocationFilters } from 'src/_shared/types/filter'
import { OmniConnector, OmniLocation } from 'src/_shared/types/omni'
import { isConnectorAvailable } from 'src/_shared/utils/charging'
import { filterEvsesByPowerType } from 'src/_shared/utils/filter'
import { ClusterProperties, PointFeature } from 'supercluster'

import { LocationPointDetails } from './types'

export const isPointFeatureCluster = (
	pointFeature: PointFeature<LocationPointDetails> | PointFeature<ClusterProperties>
): pointFeature is PointFeature<ClusterProperties> => {
	return pointFeature.properties.cluster
}

/**
 * Merges two separate sets of Locations data, overwriting the older data with newer data
 * when there are clashing Location `uid`s.
 * @param {OmniLocation[]} oldData The Locations data fetched prior to `newData`.
 * @param {OmniLocation[]} newData The most recent Locations data.
 * @returns {OmniLocation[]} Merged Locations data.
 */
export const mergeLocationsData = (
	oldData: OmniLocation[],
	newData: OmniLocation[]
): OmniLocation[] => {
	const mergedLocationsMap = [...oldData, ...newData].reduce<Record<string, OmniLocation>>(
		(locationsMap, location): Record<string, OmniLocation> => {
			// Locations from `newData` will override the previous `oldData` if
			// there is an existing `uid` key-value in the map.
			if (location.uid) {
				locationsMap[location.uid] = location
			}
			return locationsMap
		},
		{}
	)
	const mergedLocations = Object.values(mergedLocationsMap)
	return mergedLocations
}

/**
 * Checks if there are any available connectors in the provided list.
 * @param {OmniConnector[]} connectors The list of connectors to check.
 * @returns {boolean} Returns `true` if there is at least one available connector, otherwise `false`.
 */
export const hasAvailableConnector = (connectors: OmniConnector[] = []): boolean => {
	return connectors.some((connector): boolean => isConnectorAvailable(connector))
}

/**
 * Checks if there are any available Connectors in the provided Location based on the applied filters.
 * @param {OmniLocation} location The Location to check.
 * @param {LocationFilters} locationFilters The filters to apply on the location's EVSEs.
 * @returns {boolean} Returns `true` if there is at least one available connector, otherwise false.
 */
export const hasAvailableConnectorsWithAppliedFilters = (
	location: OmniLocation,
	locationFilters: LocationFilters
): boolean => {
	const filteredEvses = filterEvsesByPowerType(location.evses, locationFilters.powerType)
	return filteredEvses.some((evse): boolean => hasAvailableConnector(evse.connectors))
}
