import dayjs from 'dayjs'
import { ReactNode, useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TIME_FORMAT } from 'src/_shared/constants/env'
import { OmniSessionStatus } from 'src/_shared/enums/omni'
import { PaymentMethodCode } from 'src/_shared/enums/payments'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { ReceiptDetailsFeatureToggles } from 'src/_shared/queries/strapi'
import { OmniSession } from 'src/_shared/types/omni'
import { getTaxAcronym } from 'src/_shared/utils/currency'
import { classNames } from 'src/_shared/utils/elements'
import { getPaymentBreakdown, PaymentBreakdown } from 'src/_shared/utils/receipt'
import { formatCurrencyValue } from 'src/_shared/utils/string'

import Button from '../Button'
import GrabPayStackedLogoImage from '../_images/GrabPayStackedLogoImage'
import TouchNGoLogoImage from '../_images/TouchNGoLogoImage'
import VisaAndMastercardStackedLogosImage from '../_images/VisaAndMastercardStackedLogosImage'
import LabelValueList from './components/LabelValueList'
import { LabelValueListItemProps } from './components/LabelValueListItem'

interface ReceiptDetailsProps {
	chargingSession?: OmniSession
}

const ReceiptDetails = ({ chargingSession }: ReceiptDetailsProps): JSX.Element => {
	const intl = useIntl()

	const { brandData, getFeatureToggles } = useStrapiContext()

	const { companyNameTo, companyNameOf, footer } =
		brandData?.attributes.configuration?.receipt ?? {}

	const { showDownloadButton } = useMemo((): ReceiptDetailsFeatureToggles => {
		return getFeatureToggles('receiptDetails') ?? {}
	}, [getFeatureToggles])

	const paymentBreakdown = useMemo((): PaymentBreakdown => {
		return getPaymentBreakdown(chargingSession)
	}, [chargingSession])

	const taxAcronym = getTaxAcronym(chargingSession?.tariff?.country_code)

	const sessionDetailsLabelValueList = useMemo((): LabelValueListItemProps[] => {
		const formatDateValue = (dateStr: string): string => {
			return dayjs(dateStr).format(`MMM DD YYYY ${TIME_FORMAT}`)
		}
		return [
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelSessionStatus',
					defaultMessage: 'Status'
				}),
				value: ((): JSX.Element => {
					const formattedStatus = ((): string => {
						switch (chargingSession?.status) {
							case OmniSessionStatus.Active:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusActive',
									defaultMessage: 'Active'
								})
							case OmniSessionStatus.Completed:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusCompleted',
									defaultMessage: 'Completed'
								})
							case OmniSessionStatus.Pending:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusPending',
									defaultMessage: 'Pending'
								})
							case OmniSessionStatus.PendingPayment:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusPendingPayment',
									defaultMessage: 'Pending Payment'
								})
							case OmniSessionStatus.StartFailure:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusStartFailure',
									defaultMessage: 'Start Failure'
								})
							case OmniSessionStatus.StopFailure:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusStopFailure',
									defaultMessage: 'Stop Failure'
								})
							case OmniSessionStatus.Stopped:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusStopped',
									defaultMessage: 'Stopped'
								})
							default:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusUnknown',
									defaultMessage: 'Unknown'
								})
						}
					})()
					return (
						<span
							className={
								chargingSession?.status === OmniSessionStatus.Completed
									? 'text-success-400'
									: 'text-alert-300'
							}
						>
							{formattedStatus}
						</span>
					)
				})()
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelTransactionNumber',
					defaultMessage: 'Transaction No.'
				}),
				value: chargingSession?._id ?? '-'
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelSessionStart',
					defaultMessage: 'Session Start'
				}),
				value: chargingSession?.start_date_time
					? formatDateValue(chargingSession.start_date_time)
					: '-'
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelSessionEnd',
					defaultMessage: 'Session End'
				}),
				value: ((): string => {
					if (
						!chargingSession?.start_date_time ||
						!chargingSession.end_date_time ||
						dayjs(chargingSession.start_date_time).isSameOrAfter(chargingSession.end_date_time)
					) {
						return '-'
					}
					return formatDateValue(chargingSession.end_date_time)
				})()
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelDuration',
					defaultMessage: 'Duration'
				}),
				value: ((): string => {
					if (
						!chargingSession?.start_date_time ||
						!chargingSession.end_date_time ||
						dayjs(chargingSession.start_date_time).isSameOrAfter(chargingSession.end_date_time)
					) {
						return '-'
					}

					// Note: Deliberately round down seconds to `0` for calculating difference.
					const minutesElapsed = dayjs(chargingSession.end_date_time)
						.set('seconds', 0)
						.diff(dayjs(chargingSession.start_date_time).set('seconds', 0), 'minutes')

					const totalChargingTimeHr = Math.floor(minutesElapsed / 60)

					const totalChargingTimeMin = minutesElapsed % 60
					return intl.formatMessage(
						{
							id: 'ReceiptDetails.LabelValueDuration',
							defaultMessage: '{hours}h {minutes}min'
						},
						{
							hours: totalChargingTimeHr,
							minutes: totalChargingTimeMin
						}
					)
				})()
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelCpo',
					defaultMessage: 'CPO'
				}),
				value: chargingSession?.operator?.name ?? '-'
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelChargerId',
					defaultMessage: 'Charger ID'
				}),
				// Display the physical reference or fallback to ID if it is not available.
				value: ((): string => {
					const evseId = chargingSession?.evse_id
					const evseUid = chargingSession?.evse_uid
					if (evseId) {
						const evse = chargingSession.location?.evses?.find((evse): boolean => {
							return evse.evse_id === evseId || evse.uid === evseUid
						})
						if (evse?.physical_reference) {
							return evse.physical_reference
						} else if (evse?.evse_id) {
							return evse.evse_id
						}
						return evseId
					}
					return '-'
				})()
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelLocation',
					defaultMessage: 'Charger Location'
				}),
				value: chargingSession?.location_name ?? '-'
			}
		]
	}, [chargingSession, intl])

	const paymentDetailsLabelValueList = useMemo((): LabelValueListItemProps[] => {
		// Payment Method
		const paymentMethodValue = ((): ReactNode => {
			switch (chargingSession?.payment_method_code) {
				case PaymentMethodCode.Emv:
					return (
						<div className="flex flex-row items-center space-x-2">
							<VisaAndMastercardStackedLogosImage className="h-6 w-6" />
							<p>
								<FormattedMessage id="ReceiptDetails.LabelValueCard" defaultMessage="Card" />
							</p>
						</div>
					)
				case PaymentMethodCode.Subscription:
					return (
						<span className="text-orange-400">
							<FormattedMessage
								id="ReceiptDetails.LabelValueSubscription"
								defaultMessage="Subscription"
							/>
						</span>
					)
				case PaymentMethodCode.TouchNGo:
					return (
						<div className="flex flex-row items-center space-x-2">
							<TouchNGoLogoImage className="h-6 w-6" />
							<p>
								<FormattedMessage
									id="ReceiptDetails.LabelValueTngWallet"
									defaultMessage="TnG Wallet"
								/>
							</p>
						</div>
					)
				// Beep & Stripe Shared Value
				case PaymentMethodCode.Beep:
				case PaymentMethodCode.Stripe: {
					return chargingSession.payment_method_token
						? `${chargingSession.payment_method_token.cardType} [xxxx ${chargingSession.payment_method_token.lastFourDigits}]`
						: null
				}
				case PaymentMethodCode.FreeOfCharge:
					return <FormattedMessage id="ReceiptDetails.LabelValueFree" defaultMessage="Free" />
				case PaymentMethodCode.GrabPay:
					return (
						<div className="flex flex-row items-center space-x-2">
							<GrabPayStackedLogoImage className="h-6 w-6" />
							<p>
								<FormattedMessage id="ReceiptDetails.LabelValueGrabPay" defaultMessage="GrabPay" />
							</p>
						</div>
					)
				case PaymentMethodCode.Postpaid:
					return (
						<FormattedMessage id="ReceiptDetails.LabelValuePostpaid" defaultMessage="Postpaid" />
					)
				case PaymentMethodCode.Unknown:
					return <FormattedMessage id="ReceiptDetails.LabelValueUnknown" defaultMessage="Unknown" />
				default:
					return null
			}
		})()

		const paymentMethodLabelValue: LabelValueListItemProps | null = paymentMethodValue
			? {
					label: intl.formatMessage({
						id: 'ReceiptDetails.LabelPaymentMethod',
						defaultMessage: 'Payment Method'
					}),
					value: paymentMethodValue
				}
			: null

		// Session Fee (Flat Price)
		const sessionFeeLabelValue: LabelValueListItemProps | null =
			paymentBreakdown.flatPrice > 0
				? {
						label: intl.formatMessage({
							id: 'ReceiptDetails.LabelSessionFee',
							defaultMessage: 'Session Fee'
						}),
						value: formatCurrencyValue(paymentBreakdown.flatPrice, chargingSession?.currency, 2)
					}
				: null

		// 1. Energy Costs (Including Taxes)
		const totalEnergyChargedLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage({
				id: 'ReceiptDetails.LabelEnergyDelivered',
				defaultMessage: 'Energy Delivered'
			}),
			value: intl.formatMessage(
				{
					id: 'ReceiptDetails.LabelValueEnergyDelivered',
					defaultMessage: '{totalKwh} kWh @ {pricePerKwh}/kWh'
				},
				{
					totalKwh: paymentBreakdown.totalKwh.toFixed(3),
					pricePerKwh: formatCurrencyValue(paymentBreakdown.pricePerKwh, chargingSession?.currency)
				}
			)
		}

		const energyCostLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage({
				id: 'ReceiptDetails.LabelEnergyCost',
				defaultMessage: 'Energy Cost'
			}),
			value: formatCurrencyValue(paymentBreakdown.energyCost, chargingSession?.currency, 2)
		}

		// 2. Price Per Min Costs (Including Taxes)
		const pricePerMinLabelValue: LabelValueListItemProps | null =
			paymentBreakdown.hasPricePerMinCost
				? {
						label: intl.formatMessage({
							id: 'ReceiptDetails.LabelPricePerMin',
							defaultMessage: 'Price per Min'
						}),
						value: intl.formatMessage(
							{
								id: 'ReceiptDetails.LabelValueDurationRate',
								defaultMessage: '{minutes} min @ {pricePerMin}/min'
							},
							{
								minutes: paymentBreakdown.totalChargingTime,
								pricePerMin: formatCurrencyValue(
									paymentBreakdown.pricePerMinTime,
									chargingSession?.currency
								)
							}
						)
					}
				: null

		const pricePerMinCostLabelValue: LabelValueListItemProps | null =
			paymentBreakdown.hasPricePerMinCost
				? {
						label: intl.formatMessage({
							id: 'ReceiptDetails.LabelPricePerMinCost',
							defaultMessage: 'Price per Min Cost'
						}),
						value: formatCurrencyValue(
							paymentBreakdown.pricePerMinCost,
							chargingSession?.currency,
							2
						)
					}
				: null

		// 3. Sum of Energy Costs and Price Per Min Costs (Including Taxes)
		const subTotalLabelValue: LabelValueListItemProps = {
			containerClassName: 'border-t border-grayscale-900/10 pt-3',
			label: intl.formatMessage(
				{
					id: 'ReceiptDetails.LabelSubTotal',
					defaultMessage: 'Subtotal {suffix}'
				},
				{
					suffix: `(${intl.formatMessage(
						{
							id: 'ReceiptDetails.TotalCostAbbreviatedInclusiveTax',
							defaultMessage: 'Incl. of {taxAcronym}'
						},
						{
							taxAcronym
						}
					)})`
				}
			),
			value: formatCurrencyValue(paymentBreakdown.subTotalCost, chargingSession?.currency, 2)
		}

		// 4. Idle Costs (Including Taxes)
		const timeIdledLabelValue: LabelValueListItemProps | null = paymentBreakdown.hasIdleCost
			? {
					label: intl.formatMessage({
						id: 'ReceiptDetails.LabelTimeIdled',
						defaultMessage: 'Time Idled'
					}),
					value: intl.formatMessage(
						{
							id: 'ReceiptDetails.LabelValueDurationRate',
							defaultMessage: '{minutes} min @ {pricePerMin}/min'
						},
						{
							minutes: paymentBreakdown.totalIdleTime,
							pricePerMin: formatCurrencyValue(
								paymentBreakdown.pricePerIdleTime,
								chargingSession?.currency
							)
						}
					)
				}
			: null

		const idleCostLabelValue: LabelValueListItemProps | null = paymentBreakdown.hasIdleCost
			? {
					label: intl.formatMessage({
						id: 'ReceiptDetails.LabelIdleCost',
						defaultMessage: 'Idle Cost'
					}),
					value: formatCurrencyValue(paymentBreakdown.idleCost, chargingSession?.currency, 2)
				}
			: null

		return [
			paymentMethodLabelValue,
			// FUTURE TODO: Reservation Fee
			sessionFeeLabelValue,
			totalEnergyChargedLabelValue,
			// Price Per Min Fees
			energyCostLabelValue,
			pricePerMinLabelValue,
			pricePerMinCostLabelValue,
			subTotalLabelValue,
			// Idling Fees
			timeIdledLabelValue,
			idleCostLabelValue
		].filter(Boolean) as LabelValueListItemProps[]
	}, [chargingSession, paymentBreakdown, intl, taxAcronym])

	const minimumFee = chargingSession?.tariff?.min_price?.incl_vat

	/**
	 * Uses the native `print` method alongside Tailwind variants to handle printing.
	 * @see {@link https://tailwindcss.com/docs/hover-focus-and-other-states#print}
	 */
	const handleDownloadReceipt = useCallback((): void => {
		if (showDownloadButton) {
			window.print()
		}
	}, [showDownloadButton])

	return (
		<div className="space-y-4">
			{/* Transaction Details */}
			<LabelValueList
				title={intl.formatMessage({
					id: 'ReceiptDetails.TitleTransactionDetails',
					defaultMessage: 'Transaction Details'
				})}
				labelValueList={sessionDetailsLabelValueList}
			/>
			{/* Payment Details */}
			<LabelValueList
				title={intl.formatMessage({
					id: 'ReceiptDetails.TitlePaymentDetails',
					defaultMessage: 'Payment Details'
				})}
				labelValueList={paymentDetailsLabelValueList}
			/>
			{/* Total Cost */}
			<div className="flex items-center justify-between space-x-5 rounded-lg bg-primary-100 p-4">
				{/* Left Label */}
				<div>
					<label
						data-testid="rd-label-total-cost-min-fee"
						className="body-2-semibold text-typography-primary"
					>
						{minimumFee ? (
							<FormattedMessage
								id="ReceiptDetails.LabelTotalCostMinFee"
								defaultMessage="Total Cost (Min. Fee {minimumFee})"
								values={{
									minimumFee: formatCurrencyValue(minimumFee, chargingSession.currency, 2)
								}}
							/>
						) : (
							<FormattedMessage id="ReceiptDetails.LabelTotalCost" defaultMessage="Total Cost" />
						)}
					</label>
					<p className="caption-3-medium text-typography-primary">
						(
						<FormattedMessage
							id="ReceiptDetails.TotalCostInclusiveTax"
							defaultMessage="Inclusive of {taxAcronym}"
							values={{
								minimumFee: formatCurrencyValue(minimumFee, chargingSession?.currency, 2),
								taxAcronym
							}}
						/>
						)
					</p>
				</div>
				{/* Right Value */}
				<div className="min-w-20 max-w-[50%]">
					<p
						data-testid="rd-label-total-cost-value"
						className={classNames(
							'body-3-bold break-words text-right leading-6 text-typography-primary',
							paymentBreakdown.isCostFree ? 'text-typography-tertiary line-through' : null
						)}
					>
						{formatCurrencyValue(paymentBreakdown.totalCost, chargingSession?.currency, 2)}
					</p>
				</div>
			</div>
			{/* Conditional Download Button */}
			{showDownloadButton && (
				<Button
					className="w-full print:hidden"
					variant="ghost"
					data-testid="rd-btn-download-receipt"
					onClick={handleDownloadReceipt}
				>
					<FormattedMessage
						id="ReceiptDetails.ButtonDownloadReceipt"
						defaultMessage="Download Receipt"
					/>
				</Button>
			)}
			{/* Conditional Footer */}
			{(!!companyNameTo || !!footer) && (
				<div>
					{companyNameTo && (
						<p className="caption-3-normal text-center text-typography-tertiary [&:not(:last-child)]:mb-4">
							<FormattedMessage
								id="ReceiptDetails.FooterTextPayment"
								defaultMessage="The payment will appear in your statement as payment to {companyNameTo}<p1> on behalf of {companyNameOf}</p1>."
								values={{
									companyNameTo,
									companyNameOf,
									p1: (chunks): JSX.Element | null => {
										if (companyNameOf) {
											return <>{chunks}</>
										}
										return null
									}
								}}
							/>
						</p>
					)}
					{footer?.map((str, index): JSX.Element => {
						return (
							<p key={index} className="caption-3-normal text-center text-typography-tertiary">
								{str}
							</p>
						)
					})}
				</div>
			)}
		</div>
	)
}

export default ReceiptDetails
