import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { DateRange, DayPicker, getDefaultClassNames } from 'react-day-picker'
import 'react-day-picker/style.css'
import { useRouterLocation, useRouterNavigate } from 'src/App/router/hooks'
import { ScreenRoutePath } from 'src/App/router/types'
import Button from 'src/_shared/components/Button'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import ArrowLeftIcon from 'src/_shared/components/_icons/ArrowLeftIcon'

/**
 * The `dateTo` and `dateFrom` are represented in milliseconds.
 * This is to ensure the date range can be passed as a state to the History screen
 */
interface HistoryFilterScreenRouteState {
	dateFrom?: number | null
	dateTo?: number | null
}

interface SelectedDateRange {
	dateFrom: dayjs.Dayjs | null
	dateTo: dayjs.Dayjs | null
}

const HistoryFilterScreen = (): JSX.Element => {
	/**
	 * Retrieve the default class names for the DayPicker component
	 */
	const defaultClassNames = getDefaultClassNames()

	const navigate = useRouterNavigate()

	/**
	 * This component relies on the location state to determine the initial date range, likewise for the HistoryScreen
	 */
	const locationState = useRouterLocation<HistoryFilterScreenRouteState | null>().state ?? {}
	const startDateFromProps = locationState.dateFrom ?? null
	const endDateFromProps = locationState.dateTo ?? null

	const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange | null>(
		startDateFromProps !== null || endDateFromProps !== null
			? {
					dateFrom: startDateFromProps !== null ? dayjs(startDateFromProps) : null,
					dateTo: endDateFromProps !== null ? dayjs(endDateFromProps) : null
				}
			: null
	)

	/**
	 * Handle date range selection
	 * @param {DateRange | undefined} dateRange - The selected date range
	 */
	const handleDateRangeSelect = (dateRange?: DateRange) => {
		if (dateRange === undefined) {
			setSelectedDateRange(null)
			return
		}

		/**
		 * Eliminate side effects by creating a new date object with dayjs
		 * Set the time to the beginning of the day and the end of the day
		 */
		const dateFrom = dayjs(dateRange.from).startOf('day')
		const dateTo = dayjs(dateRange.to).endOf('day')

		setSelectedDateRange({
			dateFrom,
			dateTo
		})
	}

	const dateNow = useMemo(() => dayjs(), [])

	const isSelectedRangeChanged =
		selectedDateRange?.dateFrom?.valueOf() !== startDateFromProps ||
		selectedDateRange.dateTo?.valueOf() !== endDateFromProps

	/**
	 * Handle apply filter button click.
	 * Navigate to the History screen with the current selectedDateRange
	 */
	const handleApplyFilterClick = () => {
		navigate(ScreenRoutePath.History, {
			replace: true,
			state: {
				dateFrom: selectedDateRange?.dateFrom?.valueOf(),
				dateTo: selectedDateRange?.dateTo?.valueOf()
			}
		})
	}

	/**
	 * Handle back button click.
	 * Navigate to the History screen with the previously supplied props (if any)
	 */
	const handleBackClick = () => {
		navigate(ScreenRoutePath.History, {
			replace: true,
			state: {
				dateFrom: startDateFromProps,
				dateTo: endDateFromProps
			}
		})
	}

	/**
	 * Handle clear filter button click.
	 * Clear the selected date range
	 */
	const handleClearFilter = () => {
		setSelectedDateRange(null)
	}

	return (
		<ScreenContainer
			topBarProps={{
				centerRender: <h1>Filter</h1>,
				leftRender: (
					<TopBarButton data-testid="hfs-top-bar-btn-back" onClick={handleBackClick}>
						<ArrowLeftIcon className="h-4" />
					</TopBarButton>
				)
			}}
		>
			<div className="my-6 flex h-full w-full flex-grow flex-col px-4">
				<div className="flex flex-1 flex-col">
					<p className="body-1-normal text-start text-typography-tertiary">
						Select dates to filter charging history
					</p>

					{/* Inject the accent color to the DayPicker component */}
					<div className="rdp-root mt-2">
						<DayPicker
							data-testid="hfs-component-day-picker"
							classNames={{
								// Combine default and custom classes
								months: `${defaultClassNames.months} max-w-full`,
								month: `${defaultClassNames.month} w-full`,
								month_grid: `${defaultClassNames.month_grid} w-full mt-8`,
								day_button: `${defaultClassNames.day_button} inline`,
								today: `${defaultClassNames.today} font-bold`,
								weekday: `${defaultClassNames.weekdays} font-semibold`
							}}
							mode="range"
							selected={
								selectedDateRange !== null
									? {
											from: selectedDateRange.dateFrom?.toDate(),
											to: selectedDateRange.dateTo?.toDate()
										}
									: undefined
							}
							onSelect={handleDateRangeSelect}
							showOutsideDays={true}
							disabled={{ after: dateNow.toDate() }}
							defaultMonth={
								startDateFromProps !== null ? dayjs(startDateFromProps).toDate() : undefined
							}
							endMonth={dateNow.toDate()}
						/>
					</div>
				</div>

				<div className="flex flex-col space-y-2">
					<Button
						data-testid="hfs-btn-apply-filter"
						disabled={!isSelectedRangeChanged}
						className="w-full"
						variant="primary"
						onClick={handleApplyFilterClick}
					>
						Apply Filter
					</Button>

					<Button
						data-testid="hfs-btn-clear-filter"
						disabled={!selectedDateRange}
						variant="ghost"
						className="w-full border-none"
						onClick={handleClearFilter}
					>
						Clear
					</Button>
				</div>
			</div>
		</ScreenContainer>
	)
}

export default HistoryFilterScreen
