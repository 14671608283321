import { useCallback, useEffect, useRef } from 'react'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { useRouterNavigate } from 'src/App/router/hooks'
import { ScreenRoutePath } from 'src/App/router/types'
import LocationListItem from 'src/_shared/components/LocationListItem'
import BookmarkEmptyIcon from 'src/_shared/components/_icons/BookmarkEmptyIcon'
import CheckRadiusFilledIcon from 'src/_shared/components/_icons/CheckRadiusFilledIcon'
import CheckRadiusIcon from 'src/_shared/components/_icons/CheckRadiusIcon'
import { OmniLocation, OmniLocationNearby } from 'src/_shared/types/omni'

const BOTTOM_THRESHOLD = 650 // Pixels

interface FavouriteLocationsListControllerProps {
	locations: OmniLocationNearby[]
	toggledLocations: string[]
	isEditing: boolean
	onFetchNextPageCallback?: () => void
	onLocationToggleClick: (locationUid: string) => void
}

const FavouriteLocationListController = ({
	locations,
	toggledLocations,
	isEditing,
	onFetchNextPageCallback: handleFetchNextPageCallback,
	onLocationToggleClick: handleLocationToggleClick
}: FavouriteLocationsListControllerProps): JSX.Element => {
	const virtuoso = useRef<VirtuosoHandle | null>(null)

	const navigate = useRouterNavigate()

	const renderRow = useCallback(
		(index: number): JSX.Element => {
			const locationUid = locations[index].location?.uid ?? ''

			const isLocationChecked = toggledLocations.some((uid): boolean => uid === locationUid)

			const handleLocationListItemClick = (location: OmniLocation): void => {
				if (location.uid && !isEditing) {
					window.location.hash = location.uid // For scroll-back
					navigate([ScreenRoutePath.Location, location.uid])
				}
			}

			const handleLocationListItemToggleClick = (): void => {
				handleLocationToggleClick(locationUid)
			}

			return (
				<LocationListItem
					key={index}
					locationNearby={locations[index]}
					onLocationListItemClick={handleLocationListItemClick}
					startAdornment={
						isEditing ? (
							<button className="ml-4" onClick={handleLocationListItemToggleClick}>
								{isLocationChecked ? (
									<CheckRadiusFilledIcon className="h-5 w-5 text-error-300" />
								) : (
									<CheckRadiusIcon className="h-5 w-5 text-typography-primary" />
								)}
							</button>
						) : null
					}
				/>
			)
		},
		[toggledLocations, locations, isEditing, navigate, handleLocationToggleClick]
	)

	/**
	 * Scrolls the user into the last entered Location List Item.
	 */
	useEffect(
		(): void => {
			if (window.location.hash) {
				const locationUid = window.location.hash.slice(1)
				const index = locations.findIndex(({ location }): boolean => {
					return location?.uid === locationUid
				})
				if (index >= 0) {
					virtuoso.current?.scrollToIndex(index)
				}
				// Remove previous hash value
				window.history.replaceState(null, '', window.location.href.split('#')[0])
			}
		},
		// To only trigger when component is mounted and without listening to changes in `locations`.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return (
		<div className="w-full">
			{locations.length === 0 ? (
				<div className="flex h-full flex-grow flex-col items-center justify-center space-y-3">
					<BookmarkEmptyIcon className="h-28 w-28 text-grayscale-500" />
					<div className="flex flex-col space-y-1 text-center">
						<p className="body-1-semibold text-typography-primary">
							No Favourite Charger Locations Found
						</p>
						<p className="body-1-normal text-typography-secondary">
							Please find a location and make it a favourite
						</p>
					</div>
				</div>
			) : (
				<Virtuoso
					atBottomThreshold={BOTTOM_THRESHOLD}
					data={locations}
					ref={virtuoso}
					endReached={handleFetchNextPageCallback}
					itemContent={renderRow}
					components={{ Footer: (): JSX.Element => <div className="h-12" /> }}
				/>
			)}
		</div>
	)
}

export default FavouriteLocationListController
