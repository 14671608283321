import dayjs from 'dayjs'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Select, { SelectOption } from 'src/_shared/components/Select'
import CheckCircleIcon from 'src/_shared/components/_icons/CheckCircleIcon'
import { TIME_FORMAT } from 'src/_shared/constants/env'
import { OmniReservationType } from 'src/_shared/enums/omni'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'
import useTimeFormatter from 'src/_shared/hooks/useTimeFormatter'
import { classNames } from 'src/_shared/utils/elements'

import { FormFieldKey } from '../enums'
import { ReservationChargerScreenCommonViewProps } from '../types'
import FooterButton from './FooterButton'

type ReservationViewProps = ReservationChargerScreenCommonViewProps

const ReservationView = ({
	routeParams,
	formValues,
	setFormValues,
	onNext: handleNext
}: ReservationViewProps): JSX.Element => {
	const intl = useIntl()

	const { formatTimeToString } = useTimeFormatter()

	const chargerDetails = useChargerDetails({
		...routeParams,
		enableRefetch: false
	})

	const reservationType = chargerDetails.isReservationCapable
		? chargerDetails.reservationType
		: null

	// FUTURE TODO: Get duration options from CPO BE
	const durationOptions = useMemo((): SelectOption<number>[] => {
		return [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180].map(
			(value): SelectOption<number> => {
				const dayjsDuration = dayjs.duration(value, 'minutes')
				const label = formatTimeToString(
					{
						hours: dayjsDuration.get('hours'),
						minutes: dayjsDuration.get('minutes'),
						seconds: dayjsDuration.get('seconds')
					},
					{ isAbbreviated: false, isCapitalised: true }
				)
				return {
					label,
					dropdownLabel: label,
					value
				}
			}
		)
	}, [formatTimeToString])

	const selectedDurationValue =
		formValues[FormFieldKey.Duration] > 0 ? formValues[FormFieldKey.Duration] : undefined

	const selectedStartTimeValue =
		formValues[FormFieldKey.StartTime] > 0 ? formValues[FormFieldKey.StartTime] : undefined

	const isNextButtonDisabled = !selectedDurationValue || !selectedStartTimeValue

	// FUTURE TODO: Get start time options from CPO BE
	const startTimeOptionsMap = useMemo((): Record<string, dayjs.Dayjs[]> => {
		const options: Record<string, dayjs.Dayjs[]> = {}
		if (selectedDurationValue) {
			const startOfDay = dayjs().startOf('day')
			const spanOfDays = 2
			const dayInMinutes = 1440
			for (let dayOffset = 0; dayOffset < spanOfDays; dayOffset++) {
				const dayKey = startOfDay.add(dayOffset, 'days').format('MMM DD')
				options[dayKey] = []
				for (let j = 0; j < dayInMinutes / selectedDurationValue; j++) {
					const timeOffset = j * selectedDurationValue
					const startTime = startOfDay.add(dayOffset, 'days').add(timeOffset, 'minutes')
					if (startTime.isAfter(dayjs())) {
						options[dayKey].push(startTime)
					}
				}
			}
		}
		return options
	}, [selectedDurationValue])

	const handleSelectDurationChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>): void => {
			const value = !isNaN(Number(event.currentTarget.value))
				? Number(event.currentTarget.value)
				: 0
			setFormValues({
				[FormFieldKey.Duration]: value,
				[FormFieldKey.StartTime]: 0 // Reset selected start time
			})
		},
		[setFormValues]
	)

	const handleStartTimeClick = useCallback(
		(value: dayjs.Dayjs) => (): void => {
			const formattedValue = value.valueOf()
			setFormValues((formValues): Record<FormFieldKey, number> => {
				return {
					...formValues,
					[FormFieldKey.StartTime]: formattedValue
				}
			})
		},
		[setFormValues]
	)

	if (reservationType === OmniReservationType.Timebelt) {
		return (
			<>
				<div className="flex-grow">
					{/* Select Duration */}
					<p className="body-3-semibold mb-5 text-typography-primary">
						<FormattedMessage
							id="ReservationView.DescriptionSelectDuration"
							defaultMessage="How much time would you like to reserve your charging slot for?"
						/>
					</p>
					<Select
						variant="outlined"
						mainClassName="w-full mb-5"
						data-testid="rcs-rv-select-duration"
						placeholder={intl.formatMessage({
							id: 'ReservationView.InputPlaceholderSelectDuration',
							defaultMessage: 'Please select a duration'
						})}
						value={selectedDurationValue}
						options={durationOptions}
						onChange={handleSelectDurationChange}
					/>
					{/* Select Start Time */}
					{!!selectedDurationValue && (
						<>
							<p className="body-3-semibold mb-2 text-typography-primary">
								<FormattedMessage
									id="ReservationView.DescriptionAvailableStartTimes"
									defaultMessage="Here are the available start times:"
								/>
							</p>
							{Object.entries(startTimeOptionsMap).map(([date, options], i): JSX.Element => {
								return (
									<div key={i} className="mb-5">
										<div className="sticky top-10 z-10 mx-[-20px] flex h-10 items-center bg-gradient-to-b from-grayscale-100 to-grayscale-100/90 px-5">
											<span className="body-2-semibold text-typography-primary">{date}</span>
										</div>
										<div
											className={classNames(
												'mt-2 grid gap-x-2 gap-y-2',
												// For the 24-hour clock, show 5 columns. Otherwise, always show 4 columns.
												TIME_FORMAT === 'HH:mm' ? 'grid-cols-5' : 'grid-cols-4'
											)}
										>
											{options.map((value, j): JSX.Element => {
												const label = value.format(TIME_FORMAT)
												const isSelected = selectedStartTimeValue === value.valueOf()
												return (
													<div key={j} className="relative">
														<button
															className={classNames(
																'body-1-bold h-8 w-full rounded-button border-2 text-typography-primary',
																isSelected ? 'border-success-400' : 'border-primary-400/30'
															)}
															onClick={handleStartTimeClick(value)}
														>
															{label}
														</button>
														{isSelected && (
															<div className="absolute right-[-5px] top-[-5px] rounded-full bg-grayscale-100">
																<CheckCircleIcon className="h-5 w-5 text-success-400" />
															</div>
														)}
													</div>
												)
											})}
										</div>
									</div>
								)
							})}
						</>
					)}
				</div>
				<FooterButton
					rootClassName="z-20"
					className="w-full"
					onClick={handleNext}
					disabled={isNextButtonDisabled}
				>
					<FormattedMessage id="ReservationView.ButtonTextNext" defaultMessage="Next" />
				</FooterButton>
			</>
		)
	}
	return <></>
}

export default ReservationView
