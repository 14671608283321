import { ReactNode, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router-dom'
import { ScreenRoutePath } from 'src/App/router/types'
import Button from 'src/_shared/components/Button'
import Carousel from 'src/_shared/components/Carousel'
import Spinner from 'src/_shared/components/Spinner'
import { Brand } from 'src/_shared/enums/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useMiniProgramContext } from 'src/_shared/hooks/useMiniProgramContext'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'

import TngCarouselItem from './TngCarouselItem'

const renderLine = (chunks: ReactNode[]): JSX.Element => {
	return <p>{chunks}</p>
}

const TngLandingView = (): JSX.Element => {
	const { isAuthenticated, isTokenRefreshLoading } = useAuthContext()

	const { authenticate: authenticateViaMiniProgram } = useMiniProgramContext()

	const { getFeatureToggles } = useStrapiContext()

	const { carouselBackgroundImageUrl = '', carouselImageUrls = ['', '', ''] } = useMemo(() => {
		return getFeatureToggles('landingScreen')?.[Brand.TouchNGo] ?? {}
	}, [getFeatureToggles])

	const handleGetStartedClick = useCallback((): void => {
		authenticateViaMiniProgram?.([window.location.origin, ScreenRoutePath.Map].join(''))
	}, [authenticateViaMiniProgram])

	if (isTokenRefreshLoading) {
		return (
			<div className="flex h-full flex-col items-center justify-center">
				<Spinner />
			</div>
		)
	} else if (isAuthenticated) {
		return <Navigate to={ScreenRoutePath.Map} />
	}
	return (
		<div
			className="flex h-full flex-col space-y-6"
			style={{
				paddingBottom: 'calc(env(safe-area-inset-bottom))'
			}}
		>
			<div
				className="flex flex-grow flex-col justify-center bg-center bg-no-repeat px-5 pt-6"
				style={{
					backgroundImage: `url(${carouselBackgroundImageUrl})`
				}}
			>
				<Carousel
					items={[
						<TngCarouselItem
							key="1"
							title={
								<FormattedMessage
									id="TngLandingView.TitleChargeAnywhereOnOnePlatform"
									defaultMessage="<p>Charge Anywhere</p><p>On One Platform</p>"
									values={{
										p: renderLine
									}}
								/>
							}
							description={
								<FormattedMessage
									id="TngLandingView.DescriptionStartStopPay"
									defaultMessage="<p>Start, Stop & Pay for EV Charging across</p><p>Singapore & Malaysia at all stations that</p><p>are on our Network</p>"
									values={{
										p: renderLine
									}}
								/>
							}
							imageUrl={carouselImageUrls[0]}
						/>,
						<TngCarouselItem
							key="2"
							title={
								<FormattedMessage
									id="TngLandingView.TitleSimpleAndSecureCrossBorderPayments"
									defaultMessage="<p>Simple & Secure</p><p>Cross Border Payments</p>"
									values={{
										p: renderLine
									}}
								/>
							}
							description={
								<FormattedMessage
									id="TngLandingView.DescriptionPayWithTng"
									defaultMessage="<p>Pay with Touch 'n Go Wallet for fast</p><p>hassle-free transactions with Seamless FX</p><p>Conversions across Singapore & Malaysia</p>"
									values={{
										p: renderLine
									}}
								/>
							}
							imageUrl={carouselImageUrls[1]}
						/>,
						<TngCarouselItem
							key="3"
							title={
								<FormattedMessage
									id="TngLandingView.ChargeWithConfidence"
									defaultMessage="<p>Charge With</p><p>Confidence</p>"
									values={{
										p: renderLine
									}}
								/>
							}
							description={
								<FormattedMessage
									id="TngLandingView.FindAndUseEvChargers"
									defaultMessage="<p>Find & use EV Chargers instantly with real-time</p><p>availability updates for all Chargers on our</p><p>Network across Singapore & Malaysia</p>"
									values={{
										p: renderLine
									}}
								/>
							}
							imageUrl={carouselImageUrls[2]}
						/>
					]}
				/>
			</div>
			<div className="px-5 pb-6">
				<Button className="w-full" onClick={handleGetStartedClick}>
					<FormattedMessage id="TngLandingView.GetStarted" defaultMessage="Get Started" />
				</Button>
			</div>
		</div>
	)
}

export default TngLandingView
