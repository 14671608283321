import {
	ChangeEvent,
	DetailedHTMLProps,
	SelectHTMLAttributes,
	memo,
	useCallback,
	useMemo
} from 'react'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import { classNames } from 'src/_shared/utils/elements'

import ChevronDownIcon from '../_icons/ChevronDownIcon'

export interface SelectOption<T> {
	label: string
	dropdownLabel?: string
	value: T
	icon?: React.ReactNode
}

export type SelectProps<T> = {
	variant?: 'outlined' | 'underlined' | 'none'
	options: SelectOption<T>[]
	placeholder?: string
	icon?: React.ReactNode
	mainClassName?: string
} & Omit<DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'children'>

const Select = <T extends number | string>({
	variant = ((): 'outlined' | 'none' => {
		switch (BRAND) {
			case Brand.Evme:
				return 'outlined'
			default:
				return 'none'
		}
	})(),
	value,
	options,
	placeholder,
	disabled,
	icon,
	mainClassName = '',
	...selectProps
}: SelectProps<T>): JSX.Element => {
	const selectedOption = useMemo((): SelectOption<T> | null => {
		const option = options.find((option): boolean => {
			return option.value === value
		})
		return option ?? null
	}, [options, value])

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>): void => {
			// Manually remove focus on `select` element
			event.currentTarget.blur()
			selectProps.onChange?.(event)
		},
		[selectProps]
	)

	return (
		<div className={classNames('relative', mainClassName)}>
			{/* Hidden Select */}
			{/* This will get clicked on and show the default select dropdown. */}
			<select
				{...selectProps}
				className={classNames(
					// Base Classes
					'peer absolute z-10 h-full w-full opacity-0',
					// `disabled` Classes
					disabled ? 'cursor-not-allowed' : 'cursor-pointer'
				)}
				value={selectedOption?.value}
				disabled={disabled}
				onChange={handleChange}
			>
				<option label={placeholder} value="" />
				{options.map(({ dropdownLabel, label, value }, index): JSX.Element => {
					return <option key={index} label={dropdownLabel ? dropdownLabel : label} value={value} />
				})}
			</select>
			{/* Select Display */}
			<div
				className={classNames(
					// Base Classes
					'flex flex-row items-center justify-between space-x-1.5 duration-150 ease-in-out',
					// `variant` Classes
					((): string | null => {
						switch (variant) {
							case 'outlined':
								return 'rounded-md border-2 border-input-boundary px-4 peer-focus:border-input-boundary-focus'
							case 'underlined':
								return 'border-b border-input-boundary px-1 peer-focus:border-input-boundary-focus'
							case 'none':
								return 'h-full'
						}
					})(),
					selectProps.className
				)}
			>
				<p
					className={classNames(
						// Base Classes
						'body-2-normal text-nowrap',
						// `variant` Classes
						((): string | null => {
							switch (variant) {
								case 'outlined':
								case 'underlined':
									return 'flex h-12 items-center'
								case 'none':
									return null
							}
						})(),
						// `disabled` Classes
						disabled ? 'text-typography-tertiary' : 'text-typography-primary',
						// Placeholder Class
						!selectedOption ? '!text-input-empty' : null
					)}
				>
					{selectedOption ? selectedOption.label : placeholder}
				</p>
				{icon ?? <ChevronDownIcon className="mt-0.5 w-3 text-primary-800" />}
			</div>
		</div>
	)
}

const MemoisedSelect = memo(Select)

export default MemoisedSelect
