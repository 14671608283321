import dayjs from 'dayjs'
import {
	OmniDayNumberOfWeek,
	OmniSessionChargingPeriodDimensionType,
	OmniTariffPriceModifierType
} from 'src/_shared/enums/omni'
import {
	OmniSession,
	OmniSessionChargingPeriod,
	OmniSessionChargingPeriodDimension,
	OmniTariff,
	OmniTariffPriceModifier,
	OmniLocationOpeningTimes,
	OmniLocationOpeningTimesPeriodValues
} from 'src/_shared/types/omni'

import { PERIOD_TIME_FORMAT } from './constants'

/**
 * Pads the time number value with a 0-prefix if required,
 * e.g. `9` -> `09` (padded), `11` -> `11` (unchanged)
 * @param value The time amount.
 * @returns {string} The padded time amount.
 */
export const formatPaddedTimeNumber = (value: number): string => {
	return value.toString().padStart(2, '0')
}

/**
 * Derives the actual hours, minutes, and seconds from the total number of seconds provided.
 * @param {number} value The amount in seconds.
 * @returns {object} `{ hours, minutes, seconds }`
 */
export const getTimeFromSeconds = (
	value: number
): { hours: number; minutes: number; seconds: number } => {
	let seconds = value

	let minutes = seconds >= 60 ? Math.floor(seconds / 60) : 0
	if (minutes > 0) {
		seconds %= 60
	}

	const hours = minutes >= 60 ? Math.floor(minutes / 60) : 0
	if (hours > 0) {
		minutes %= 60
	}

	return { hours, minutes, seconds }
}

/**
 * Finds a specified Price Modifier inside of a Tariff.
 * @param {OmniTariff | null} tariff The Tariff to search in.
 * @param {OmniTariffPriceModifierType} priceModifierType The Price Modifier type to search for.
 * @returns {OmniTariffPriceModifier | null} If found, return the specified Price Modifier. Else, returns `null`.
 */
export const getTariffPriceModifier = (
	tariff: OmniTariff | null,
	priceModifierType: OmniTariffPriceModifierType
): OmniTariffPriceModifier | null => {
	const priceModifier = tariff?.price_modifiers?.find(
		(priceModifier): boolean => priceModifier.type === priceModifierType
	)
	return priceModifier ?? null
}

/**
 * Finds the State of Charge Charing Period Dimension inside of a Session's Charging Periods.
 * @param {OmniSession | null} session The Session to search in.
 * @returns {OmniSessionChargingPeriodDimension | null} If found, return the State of Charge Charing Period Dimension. Else, returns `null`.
 */
export const getSessionStateOfCharge = (
	session: OmniSession | null
): OmniSessionChargingPeriodDimension | null => {
	const isStateOfChargeDimension = (dimension: OmniSessionChargingPeriodDimension): boolean => {
		return dimension.type === OmniSessionChargingPeriodDimensionType.StateOfCharge
	}

	const stateOfChargeDimension = session?.charging_periods
		?.findLast((chargingPeriod): boolean => {
			return chargingPeriod.dimensions?.some(isStateOfChargeDimension) ?? false
		})
		?.dimensions?.find(isStateOfChargeDimension)

	if (stateOfChargeDimension) {
		return stateOfChargeDimension
	}
	return null
}

/**
 * Finds the specified `dimension` inside of the latest charging period of a `session`.
 * @param {OmniSessionChargingPeriod[]} chargingPeriods The session's charging periods.
 * @param {OmniSessionChargingPeriodDimensionType} dimensionType The type of the `dimension`.
 * @returns {OmniSessionChargingPeriodDimension | null} If found, returns the `dimension` inside of the latest charging period.  Else, returns `null`.
 */
export const getLatestChargingPeriodDimension = (
	chargingPeriods: OmniSessionChargingPeriod[] = [],
	dimensionType: OmniSessionChargingPeriodDimensionType
): OmniSessionChargingPeriodDimension | null => {
	if (chargingPeriods.length > 0) {
		const latestChargingPeriod = chargingPeriods[chargingPeriods.length - 1]
		const dimension = latestChargingPeriod.dimensions?.find((dimension): boolean => {
			return dimension.type === dimensionType
		})
		if (dimension) {
			return dimension
		}
	}
	return null
}

/**
 * Determines if the `location` is currently open for charging.
 * @param {OmniLocationOpeningTimes} openingTimes The opening times of the `location`.
 * @returns {boolean} A boolean indicating whether or not the location is currently open.
 */
export const isLocationOpen = (openingTimes: OmniLocationOpeningTimes): boolean | null => {
	const currentTime = dayjs()

	/**
	 * For checking of Exceptional Openings/Closings
	 * Time Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`
	 */
	const isCurrentTimeBetweenExceptionalPeriod = ({
		period_begin: periodBegin,
		period_end: periodEnd
	}: OmniLocationOpeningTimesPeriodValues): boolean => {
		const startTime = dayjs(periodBegin)
		const endTime = dayjs(periodEnd)
		return currentTime.isBetween(
			startTime,
			endTime,
			'seconds',
			'[)' // Exclude `endTime` (i.e. once end time is reached, consider it to be closed)
		)
	}

	const isBetweenExceptionalClosingPeriod =
		openingTimes.exceptional_closings?.some(isCurrentTimeBetweenExceptionalPeriod) ?? false

	// 24 Hours; only have to check for exceptional closings
	if (openingTimes.twentyfourseven) {
		return !isBetweenExceptionalClosingPeriod // Not between any closing period
	}
	// Not 24 Hours; check against regular hours and exceptional openings/closings
	else {
		if (isBetweenExceptionalClosingPeriod) {
			return false // Closed
		}

		const isBetweenRegularHours =
			openingTimes.regular_hours
				?.filter(({ weekday }): boolean => {
					return weekday === (currentTime.day() as OmniDayNumberOfWeek)
				})
				.some(
					/**
					 * Time Format: `HH:mm` (24 Hour Clock)
					 */
					({ period_begin: periodBegin, period_end: periodEnd }): boolean => {
						const startTime = dayjs(periodBegin, PERIOD_TIME_FORMAT)
						let endTime = dayjs(periodEnd, PERIOD_TIME_FORMAT)
						// Account for rollover `endTime` value, e.g. 22:00 to 10:00 (rollover).
						if (endTime.isBefore(startTime)) {
							endTime = endTime.add(1, 'day')
						}
						return currentTime.isBetween(
							startTime,
							endTime,
							'seconds',
							'[)' // Exclude `endTime` (i.e. once end time is reached, consider it to be closed)
						)
					}
				) ?? false

		const isBetweenExceptionalOpeningPeriod =
			openingTimes.exceptional_openings?.some(isCurrentTimeBetweenExceptionalPeriod) ?? false

		return isBetweenRegularHours || isBetweenExceptionalOpeningPeriod
	}
}
