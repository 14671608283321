import dayjs from 'dayjs'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRouterNavigate } from 'src/App/router/hooks'
import { ScreenRoutePath } from 'src/App/router/types'
import Alert from 'src/_shared/components/Alert'
import Button from 'src/_shared/components/Button'
import Select, { SelectOption } from 'src/_shared/components/Select'
import { TIME_FORMAT } from 'src/_shared/constants/env'
import { OmniCountryCurrencyCode, OmniReservationType } from 'src/_shared/enums/omni'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'
import useTimeFormatter from 'src/_shared/hooks/useTimeFormatter'
import { useSessionQuery } from 'src/_shared/queries/sessions'
import { classNames } from 'src/_shared/utils/elements'
import { formatCurrencyValue } from 'src/_shared/utils/string'
import { delay } from 'src/_shared/utils/time'

import { ReservationExtendScreenCommonViewProps } from '../types'
import ExtendModal, { ExtendModalProps } from './ExtendModal'

type ExtendViewProps = Omit<ReservationExtendScreenCommonViewProps, 'onNext'>

const ExtendView = ({ routeParams }: ExtendViewProps): JSX.Element => {
	// TODO: Use Extend Query Status
	const [isExtendLoading, setIsExtendLoading] = useState<boolean>(false)

	const [extendModalProps, setExtendModalProps] = useState<ExtendModalProps | null>(null)

	const [selectedDurationValue, setSelectedDurationValue] = useState<number>(0)

	const intl = useIntl()

	const navigate = useRouterNavigate()

	const { timeFormatMap, formatTimeToString } = useTimeFormatter()

	const { data: session = null } = useSessionQuery(routeParams)

	const chargerDetails = useChargerDetails({
		cpoEntityCode: session?.location?.entity_code ?? '',
		locationUid: session?.location_uid ?? '',
		evseUid: session?.evse_uid ?? '',
		connectorUid: session?.connector_uid ?? '',
		enableRefetch: false
	})

	const reservationType = chargerDetails.isReservationCapable
		? chargerDetails.reservationType
		: null

	const hasNextReservation = true

	const unitHour = timeFormatMap.hoursFormat.abbreviated

	const extensionFeeValue = formatCurrencyValue(5, OmniCountryCurrencyCode.Thailand, 2)

	const totalExtensionCostValue = formatCurrencyValue(
		(5 / 60) * selectedDurationValue,
		OmniCountryCurrencyCode.Thailand,
		2
	)

	// FUTURE TODO: Get duration options from CPO BE
	const durationOptions = useMemo((): SelectOption<number>[] => {
		return [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180].map(
			(value): SelectOption<number> => {
				const dayjsDuration = dayjs.duration(value, 'minutes')
				const label = formatTimeToString(
					{
						hours: dayjsDuration.get('hours'),
						minutes: dayjsDuration.get('minutes'),
						seconds: dayjsDuration.get('seconds')
					},
					{ isAbbreviated: false, isCapitalised: true }
				)
				return {
					label,
					dropdownLabel: label,
					value
				}
			}
		)
	}, [formatTimeToString])

	const handleSelectDurationChange = useCallback((event: ChangeEvent<HTMLSelectElement>): void => {
		const value = !isNaN(Number(event.currentTarget.value)) ? Number(event.currentTarget.value) : 0
		setSelectedDurationValue(value)
	}, [])

	// FUTURE TODO: Handle redirect payment flow
	const handleExtendBookingClick = useCallback((): void => {
		const extendBooking = async (): Promise<void> => {
			setIsExtendLoading(true)
			await delay(2500)
			setIsExtendLoading(false)
			setExtendModalProps({
				variant: 'success',
				open: true
			})
			await delay(2500)
			navigate([
				ScreenRoutePath.Charger,
				session?.location?.entity_code ?? '',
				session?.location_uid ?? '',
				session?.evse_uid ?? '',
				session?.connector_uid ?? ''
			])
		}
		void extendBooking()
	}, [session, navigate])

	if (reservationType === OmniReservationType.Timebelt) {
		return (
			<>
				{/* Content */}
				<div className="flex-grow">
					{/* Select Duration */}
					<p className="body-3-semibold mb-5 text-typography-primary">
						<FormattedMessage
							id="ExtendView.DescriptionSelectDuration"
							defaultMessage="Select Booking Extension Duration"
						/>
					</p>
					<Select
						variant="outlined"
						mainClassName="w-full mb-5"
						data-testid="res-ev-select-duration"
						placeholder={intl.formatMessage({
							id: 'ExtendView.InputPlaceholderSelectDuration',
							defaultMessage: 'Please select a duration'
						})}
						value={selectedDurationValue}
						options={durationOptions}
						onChange={handleSelectDurationChange}
					/>
					{/* Next Reservation */}
					{/* FUTURE TODO: Use times provided by BE */}
					{
						// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
						hasNextReservation && (
							<Alert
								data-testid="res-ev-alert-next-reservation"
								className="mb-5 [&>div>p]:flex [&>div>p]:flex-col"
							>
								<span className="mb-1">
									<FormattedMessage
										id="ExtendView.AlertNextReservation"
										defaultMessage="Next reserved time slot at this station:"
									/>
								</span>
								<span className="body-1-semibold">
									{dayjs().endOf('day').subtract(1, 'hour').format(`MMM DD, ${TIME_FORMAT}`)} -{' '}
									{dayjs().endOf('day').format(TIME_FORMAT)}
								</span>
							</Alert>
						)
					}
					{/* Extension Details */}
					{!!selectedDurationValue && (
						<>
							{/* Extension Fee Details */}
							<div className="mb-4 rounded-lg bg-grayscale-200 p-4">
								<div className="mb-4">
									<span className="body-2-semibold text-typography-primary">
										<FormattedMessage
											id="ExtendView.TitleExtensionFeeDetails"
											defaultMessage="Extension Fee Details"
										/>
									</span>
								</div>
								{/* Extension Duration */}
								<div className="mb-3 flex justify-between space-x-5">
									<div>
										<label
											data-testid="res-ev-label-extension-duration"
											className={classNames('body-2-normal text-typography-tertiary')}
										>
											<FormattedMessage
												id="ExtendView.LabelExtensionDuration"
												defaultMessage="Extension Duration"
											/>
										</label>
									</div>
									<div className="min-w-20 max-w-[57.5%] text-right">
										<span
											data-testid="res-ev-text-value-extension-duration"
											className={classNames('body-2-semibold break-words text-typography-primary')}
										>
											{
												durationOptions.find((option): boolean => {
													return option.value === selectedDurationValue
												})?.label
											}
										</span>
									</div>
								</div>
								{/* Extension Fee */}
								<div className="flex justify-between space-x-5">
									<div>
										<label
											data-testid="res-ev-label-extension-fee"
											className={classNames('body-2-normal text-typography-tertiary')}
										>
											<FormattedMessage
												id="ExtendView.LabelExtensionFee"
												defaultMessage="Extension Fee"
											/>
										</label>
									</div>
									<div className="min-w-20 max-w-[57.5%] text-right">
										<span
											data-testid="res-ev-text-value-extension-fee"
											className={classNames('body-2-semibold break-words text-typography-primary')}
										>
											{extensionFeeValue}/{unitHour}
										</span>
									</div>
								</div>
							</div>
							{/* Total Extension Cost */}
							<div className="flex items-center justify-between space-x-5 rounded-lg bg-primary-100 p-4">
								{/* Left Label */}
								<div>
									<label
										data-testid="res-ev-label-total-extension-cost"
										className="body-2-semibold text-typography-primary"
									>
										<FormattedMessage
											id="ExtendView.LabelTotalExtensionCost"
											defaultMessage="Total Extension Cost"
										/>
									</label>
								</div>
								{/* Right Value */}
								<div className="min-w-20 max-w-[50%]">
									<p
										data-testid="res-ev-text-value-total-extension-cost"
										className="body-3-bold break-words text-right leading-6 text-typography-primary"
									>
										{totalExtensionCostValue}
									</p>
								</div>
							</div>
						</>
					)}
				</div>
				{/* Footer Button */}
				<div
					className={classNames(
						'sticky bottom-0 flex flex-col items-center bg-gradient-to-b from-grayscale-100/90 to-grayscale-100 py-6'
					)}
				>
					<Button
						data-testid="res-ev-footer-btn-extend-booking"
						className="w-full"
						disabled={!selectedDurationValue || isExtendLoading || !!extendModalProps}
						loading={isExtendLoading}
						onClick={handleExtendBookingClick}
					>
						<FormattedMessage
							id="ExtendView.ButtonTextExtendBooking"
							defaultMessage="Extend Booking"
						/>
					</Button>
				</div>
				{/* Feedback Modal */}
				{extendModalProps && <ExtendModal {...extendModalProps} />}
			</>
		)
	}
	return <></>
}

export default ExtendView
