import { SVGProps, memo } from 'react'

const PhoneIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 19 20',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M17.8333 14.1V16.6C17.8343 16.8321 17.7867 17.0618 17.6937 17.2745C17.6008 17.4871 17.4644 17.678 17.2934 17.8349C17.1224 17.9918 16.9205 18.1113 16.7006 18.1856C16.4808 18.26 16.2478 18.2876 16.0167 18.2667C13.4523 17.9881 10.9892 17.1118 8.82498 15.7084C6.81151 14.4289 5.10443 12.7219 3.82499 10.7084C2.41663 8.53438 1.54019 6.0592 1.26665 3.48337C1.24583 3.25293 1.27321 3.02067 1.34707 2.80139C1.42092 2.58211 1.53963 2.38061 1.69562 2.20972C1.85162 2.03883 2.04149 1.9023 2.25314 1.80881C2.4648 1.71532 2.6936 1.66692 2.92499 1.66671H5.42499C5.82941 1.66273 6.22148 1.80594 6.52812 2.06965C6.83476 2.33336 7.03505 2.69958 7.09165 3.10004C7.19717 3.9001 7.39286 4.68565 7.67499 5.44171C7.7871 5.73998 7.81137 6.06414 7.74491 6.37577C7.67844 6.68741 7.52404 6.97347 7.29998 7.20004L6.24165 8.25837C7.42795 10.3447 9.15536 12.0721 11.2417 13.2584L12.3 12.2C12.5266 11.976 12.8126 11.8216 13.1243 11.7551C13.4359 11.6887 13.76 11.7129 14.0583 11.825C14.8144 12.1072 15.5999 12.3029 16.4 12.4084C16.8048 12.4655 17.1745 12.6694 17.4388 12.9813C17.703 13.2932 17.8435 13.6914 17.8333 14.1Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const MemoisedPhoneIcon = memo(PhoneIcon)

export default MemoisedPhoneIcon
