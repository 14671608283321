import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useRouterNavigate } from 'src/App/router/hooks'
import { ScreenRoutePath } from 'src/App/router/types'

import { FormattedSession } from '../types'
import HistoryItem from './HistoryItem'

interface HistoryListControllerProps {
	formattedChargingSessions: FormattedSession[]
	onFetchNextPageCallback?: () => void
}

/**
 * Toggle this literal if you would like the refetch of the next page at a said index
 * e.g. if THRESHOLD_INDEX = 2, the second last index of the page when inview would trigger the next page fetch
 * if THRESHOLD_INDEX = 3, the third last index of the page when inview would trigger the next page fetch etc etc
 */
const THRESHOLD_INDEX = 3

const HistoryListController = ({
	formattedChargingSessions,
	onFetchNextPageCallback: handleFetchNextPageCallback
}: HistoryListControllerProps): JSX.Element[] => {
	const navigate = useRouterNavigate()

	const { ref, inView } = useInView({
		threshold: 0.2,
		trackVisibility: true,
		delay: 100
	})

	let sessionAccumulator = 0

	const totalSessions = formattedChargingSessions.reduce(
		(acc, group) => acc + group.sessions.length,
		0
	)

	useEffect((): void => {
		if (inView) {
			handleFetchNextPageCallback?.()
		}
	}, [inView, ref, handleFetchNextPageCallback])

	return formattedChargingSessions.map((formatChargingSession, groupIndex) => {
		if (groupIndex > 0)
			sessionAccumulator += formattedChargingSessions[groupIndex - 1].sessions.length
		return (
			<div key={groupIndex}>
				{/* Date */}
				<div className="bg-grayscale-200 px-5 py-3">
					<p className="body-1-medium text-typography-secondary">{formatChargingSession.key}</p>
				</div>
				{/* History Items */}
				<div className="flex flex-col">
					{formatChargingSession.sessions.map((chargingSession, index): JSX.Element => {
						return (
							<button
								data-testid={`hs-hlc-btn-navigate-history-detail-${index}`}
								key={index}
								ref={sessionAccumulator + index === totalSessions - THRESHOLD_INDEX ? ref : null}
								className="border-divider-primary [&:not(:last-child)]:border-b"
								onClick={(): void => {
									navigate([ScreenRoutePath.HistoryDetail, chargingSession._id ?? ''])
								}}
							>
								<HistoryItem
									dataTestIdPrefix={`hs-hlc-item-${index}`}
									chargingSession={chargingSession}
								/>
							</button>
						)
					})}
				</div>
			</div>
		)
	})
}

export default HistoryListController
