import dayjs from 'dayjs'
import { memo, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface CheckInTimeCountdownProps {
	/**
	 * In Epoch Unix Timestamp (milliseconds)
	 */
	startTime: number
}

const formatCountdown = (value: number): string => {
	const difference = Math.max(0, value - dayjs().valueOf())
	return dayjs.duration(difference, 'milliseconds').format('DD:HH:mm:ss')
}

const CheckInTimeCountdown = ({ startTime }: CheckInTimeCountdownProps): JSX.Element => {
	const [isCountdownDone, setIsCountdownDone] = useState<boolean>(
		dayjs().isSameOrAfter(dayjs(startTime))
	)

	const countdownTimeRef = useRef<HTMLSpanElement | null>(null)

	useEffect((): (() => void) | undefined => {
		if (!isCountdownDone) {
			const intervalId = setInterval((): void => {
				if (dayjs().isSameOrAfter(dayjs(startTime))) {
					setIsCountdownDone(true)
				} else if (countdownTimeRef.current) {
					countdownTimeRef.current.innerText = formatCountdown(startTime)
				}
			}, 1000)
			return (): void => {
				clearInterval(intervalId)
			}
		}
	}, [isCountdownDone, startTime])

	return (
		<div className="flex flex-col space-y-1 rounded-lg bg-grayscale-200 p-4">
			{isCountdownDone ? (
				<>
					<span className="body-2-semibold text-typography-primary">
						<FormattedMessage
							id="ReservationDetails.TitleReadyToCharge"
							defaultMessage="You're Ready To Charge!"
						/>
					</span>
					<span className="caption-3-normal text-typography-tertiary">
						<FormattedMessage
							id="ReservationDetails.DescriptionStartCharging"
							defaultMessage="You can now start charging your EV. Please connect your EV and initiate the charging session."
						/>
					</span>
				</>
			) : (
				<>
					<span className="caption-3-normal text-typography-tertiary">
						<FormattedMessage
							id="ReservationDetails.LabelCheckInTimeRemaining"
							defaultMessage="Check-In Time Remaining"
						/>
					</span>
					<span
						data-testid="rd-text-check-in-time-countdown"
						className="body-2-semibold text-typography-primary"
						ref={countdownTimeRef}
					>
						{formatCountdown(startTime)}
					</span>
				</>
			)}
		</div>
	)
}

const MemoisedCheckInTimeCountdown = memo(CheckInTimeCountdown)

export default MemoisedCheckInTimeCountdown
