import { QueryStatus, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import useDebounce from 'src/_shared/hooks/useDebounce'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import {
	LocationsQueryKey,
	ROOT_LOCATIONS_QUERY_KEY,
	useLocationsNearbyQuery,
	useLocationsQuery
} from 'src/_shared/queries/locations'
import { useUserFavouriteLocationsSummaryQuery } from 'src/_shared/queries/user'
import { Coordinates } from 'src/_shared/types/location'
import { OmniLocation } from 'src/_shared/types/omni'
import { filterLocationsWithAppliedFilters } from 'src/_shared/utils/filter'
import { getDistanceBetweenCoordinates } from 'src/_shared/utils/location'
import Supercluster, { ClusterProperties, PointFeature } from 'supercluster'
import useSupercluster from 'use-supercluster'

import { MAX_ZOOM, MIN_ZOOM } from './constants'
import { LocationPointDetails } from './types'
import { hasAvailableConnectorsWithAppliedFilters, mergeLocationsData } from './utils'

/**
 * Based on the width of the `LocationsClusterMarker`.
 */
const CLUSTER_RADIUS = 64 * 2

const DEFAULT_REFETCH_INTERVAL = 60000 // 1 Minute

const NODE_SIZE = 16

const QUERY_PARAMS_DEBOUNCE_DELAY = 500 // 0.5 Seconds

const QUERY_RADIUS_MAX = 50000 // Metres

interface UseLocationClustersArgs {
	coordinates: {
		center: Coordinates
		northEast: Coordinates
		southWest: Coordinates
	}
	/**
	 * If `true`, fetch and parse `locations` into `clusters` and `supercluster`.
	 */
	enabled: boolean
	zoom: number
}

interface LocationClusters {
	locationsNearbyQueryStatus: QueryStatus
	pointFeatures: (PointFeature<LocationPointDetails> | PointFeature<ClusterProperties>)[]
	supercluster?: Supercluster<LocationPointDetails>
}

/**
 * If `enabled`, handles the fetching, sanitising, and parsing of Charger Locations
 * into point features which are to be rendered accordingly in the Map.
 */
export const useLocationClusters = ({
	coordinates,
	enabled: isMapLoaded,
	zoom
}: UseLocationClustersArgs): LocationClusters => {
	const queryClient = useQueryClient()

	const { locationFilters } = useLocationFilters()

	const { user } = useAuthContext()

	const { subscribedCpoEntities = [] } = user ?? {}

	const { data: favouriteLocationsUids = [] } = useUserFavouriteLocationsSummaryQuery<string[]>(
		{},
		{
			staleTime: Infinity, // Cache indefinitely
			refetchOnMount: 'always',
			select: (data): string[] => {
				return data.map(({ locationUid }): string => locationUid)
			}
		}
	)

	/**
	 * Debounced to prevent `useLocationsNearbyQuery` from re-executing excessively.
	 */
	const locationsNearbyQueryParams = useDebounce(
		{
			// Use up to 6 d.p. for sufficient accuracy
			latitude: Number(coordinates.center.latitude.toFixed(6)),
			longitude: Number(coordinates.center.longitude.toFixed(6)),
			radius: Math.min(
				Math.round(
					getDistanceBetweenCoordinates(coordinates.center, coordinates.northEast) *
						// Account for locations slightly beyond the viewing bounds
						Math.max(zoom / 8, 1)
				),
				QUERY_RADIUS_MAX
			),
			fetchAll: true,
			publish: true
		},
		QUERY_PARAMS_DEBOUNCE_DELAY
	)

	const { data: locationsNearby = [], status: locationsNearbyQueryStatus } =
		useLocationsNearbyQuery<OmniLocation[]>(locationsNearbyQueryParams, {
			enabled: isMapLoaded && locationsNearbyQueryParams.radius > 0,
			refetchInterval: DEFAULT_REFETCH_INTERVAL,
			staleTime: Infinity,
			select: (data): OmniLocation[] => {
				return data.map(({ location = {} }): OmniLocation => {
					return location
				})
			}
		})

	const { data: locations = [] } = useLocationsQuery(
		{
			fetchAll: true,
			publish: true
		},
		{
			staleTime: Infinity
		}
	)

	/**
	 * Reduce number of points provided to within map viewport boundaries (this is an optimisation).
	 */
	const pointsWithinMapBounds = useMemo((): Supercluster.PointFeature<LocationPointDetails>[] => {
		const points = locations
			// Filter out locations based on applied filters
			.filter((location): boolean => {
				return filterLocationsWithAppliedFilters(
					location,
					subscribedCpoEntities,
					favouriteLocationsUids,
					locationFilters
				)
			})
			// Transform locations into points
			.reduce<PointFeature<LocationPointDetails>[]>(
				(formattedLocations, location): PointFeature<LocationPointDetails>[] => {
					const locationUid = location.uid ? location.uid : null

					const longitude = location.coordinates?.longitude
						? Number(location.coordinates.longitude)
						: null

					const latitude = location.coordinates?.latitude
						? Number(location.coordinates.latitude)
						: null

					if (!!locationUid && longitude !== null && latitude !== null) {
						const hasAvailableConnector = hasAvailableConnectorsWithAppliedFilters(
							location,
							locationFilters
						)
						formattedLocations.push({
							id: locationUid,
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [longitude, latitude]
							},
							properties: {
								cluster: false,
								location,
								hasAvailableConnector
							}
						})
					}
					return formattedLocations
				},
				[]
			)
		// Ensure that points are within the map viewport
		return points.filter((point): boolean => {
			const { longitude, latitude } = point.properties.location.coordinates ?? {}
			if ([longitude, latitude].every((value): boolean => !isNaN(Number(value)))) {
				const lng = Number(longitude)
				const lat = Number(latitude)
				return (
					coordinates.southWest.latitude <= lat &&
					lat <= coordinates.northEast.latitude &&
					coordinates.southWest.longitude <= lng &&
					lng <= coordinates.northEast.longitude
				)
			}
			return false
		})
	}, [coordinates, favouriteLocationsUids, locationFilters, locations, subscribedCpoEntities])

	const { clusters: pointFeatures, supercluster } = useSupercluster({
		bounds: [
			coordinates.southWest.longitude,
			coordinates.southWest.latitude,
			coordinates.northEast.longitude,
			coordinates.northEast.latitude
		],
		options: {
			minZoom: MIN_ZOOM,
			maxZoom: MAX_ZOOM - 1,
			nodeSize: NODE_SIZE,
			radius: CLUSTER_RADIUS
		},
		points: pointsWithinMapBounds,
		zoom
	})

	/**
	 * Merge `locationsNearby` with `locations`.
	 */
	useEffect((): void => {
		if (locationsNearbyQueryStatus === 'success') {
			queryClient.setQueryData<OmniLocation[]>(
				[ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.Locations, { fetchAll: true, publish: true }],
				(oldData = []): OmniLocation[] => {
					return mergeLocationsData(oldData, locationsNearby)
				}
			)
		}
	}, [locationsNearby, locationsNearbyQueryStatus, queryClient])

	return { locationsNearbyQueryStatus, pointFeatures, supercluster }
}
