import { MutationStatus } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRouterNavigate } from 'src/App/router/hooks'
import { ScreenRoutePath } from 'src/App/router/types'
import { NoticeProps } from 'src/_shared/components/Notice'
import PaymentMethodsSelectionList from 'src/_shared/components/PaymentMethodsSelectionList'
import { PaymentMethod } from 'src/_shared/types/payments'
import { delay } from 'src/_shared/utils/time'

import { ReservationChargerScreenViewItemKey } from '../enums'
import { ReservationChargerScreenCommonViewProps } from '../types'
import ConfirmationModal from './ConfirmationModal'
import FooterButton from './FooterButton'

type PaymentMethodsViewProps = Omit<ReservationChargerScreenCommonViewProps, 'onNext'>

const PaymentMethodsView = ({ updateView }: PaymentMethodsViewProps): JSX.Element => {
	// FUTURE TODO: Use mutation status
	const [createReservationStatus, setCreateReservationStatus] = useState<MutationStatus>('idle')

	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod | null>(null)

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null)

	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

	const intl = useIntl()

	const navigate = useRouterNavigate()

	const isCreateReservationPending = createReservationStatus === 'pending'

	const isCreateReservationSuccessful = createReservationStatus === 'success'

	const handleNextClick = useCallback((): void => {
		setIsConfirmationModalOpen(true)
	}, [])

	// FUTURE TODO: Handle normal payment flow
	const handleBookNowClick = useCallback((): void => {
		if (selectedPaymentMethod) {
			// FUTURE TODO: Use mutation
			const createReservation = async (): Promise<void> => {
				setCreateReservationStatus('pending')
				await delay(2500)
				setCreateReservationStatus('success')
				await delay(2500)
				navigate([ScreenRoutePath.ReservationDetail, ':sessionId'])
			}
			void createReservation()
		} else {
			// FUTURE TODO: Handle mutation errors (this else-block is a placeholder)
			const errorViewProps: NoticeProps = {
				type: 'error',
				header: intl.formatMessage({
					id: 'Common.GenericErrorTitle',
					defaultMessage: 'Oops! Something Went Wrong'
				})
			}
			updateView(ReservationChargerScreenViewItemKey.ErrorView, errorViewProps)
		}
	}, [intl, selectedPaymentMethod, navigate, updateView])

	return (
		<>
			<PaymentMethodsSelectionList
				dataTestIdPrefix="rcs-pmv"
				defaultPaymentMethod={defaultPaymentMethod}
				selectedPaymentMethod={selectedPaymentMethod}
				isAddOrReplacePaymentMethodDisabled={isCreateReservationPending}
				onDefaultPaymentMethodChange={setDefaultPaymentMethod}
				onSelectedPaymentMethodChange={setSelectedPaymentMethod}
			/>
			<FooterButton
				className="w-full"
				onClick={handleNextClick}
				disabled={
					isCreateReservationPending || isCreateReservationSuccessful || !selectedPaymentMethod
				}
			>
				<FormattedMessage id="PaymentMethodsView.ButtonTextNext" defaultMessage="Next" />
			</FooterButton>
			<ConfirmationModal
				open={isConfirmationModalOpen}
				isLoading={isCreateReservationPending}
				isSuccessful={isCreateReservationSuccessful}
				setModalOpen={setIsConfirmationModalOpen}
				onBookNowClick={handleBookNowClick}
			/>
		</>
	)
}

export default PaymentMethodsView
