import dayjs from 'dayjs'
import { memo, ReactNode, useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ScreenRoutePath } from 'src/App/router/types'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import useAddTngPaymentMethod from 'src/_shared/hooks/useAddTngPaymentMethod'
import { usePaymentMethodsQuery } from 'src/_shared/queries/payments'
import { PaymentMethod } from 'src/_shared/types/payments'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import Button from '../Button'
import PaymentMethodsList from '../PaymentMethodsList'
import Spinner from '../Spinner'
import CheckCircleIcon from '../_icons/CheckCircleIcon'

interface PaymentMethodsSelectionListProps {
	className?: string
	dataTestIdPrefix?: string
	defaultPaymentMethod: PaymentMethod | null
	selectedPaymentMethod: PaymentMethod | null
	isAddOrReplacePaymentMethodDisabled?: boolean
	/**
	 * Callback to execute when the default payment method is fetched or has changed.
	 * @param {PaymentMethod|null} paymentMethod The new default payment method.
	 */
	onDefaultPaymentMethodChange: (paymentMethod: PaymentMethod | null) => void
	/**
	 * Callback to execute when the selected payment method is fetched or has changed.
	 * @param {PaymentMethod|null} paymentMethod The new default payment method.
	 */
	onSelectedPaymentMethodChange: (paymentMethod: PaymentMethod | null) => void
}

/**
 * Used in `ChargerScreen` and `ReservationChargerScreen` flows
 */
const PaymentMethodsSelectionList = ({
	className,
	dataTestIdPrefix,
	defaultPaymentMethod,
	selectedPaymentMethod,
	isAddOrReplacePaymentMethodDisabled,
	onDefaultPaymentMethodChange: handleDefaultPaymentMethodChange,
	onSelectedPaymentMethodChange: handleSelectedPaymentMethodChange
}: PaymentMethodsSelectionListProps): JSX.Element => {
	const { data: paymentMethods = [], isLoading: isPaymentMethodsLoading } = usePaymentMethodsQuery()

	const { addTngPaymentMethod, isAddTngPaymentMethodPending, isTngSetupIntentLoading } =
		useAddTngPaymentMethod()

	const listItemRightRender = useCallback(
		(paymentMethod: PaymentMethod): JSX.Element => {
			const isExpiredPaymentMethod =
				!!paymentMethod.tokenExpiresAt && dayjs().isSameOrAfter(paymentMethod.tokenExpiresAt)

			const isDefaultPaymentMethod = paymentMethod._id === defaultPaymentMethod?._id

			const isSelectedPaymentMethod = paymentMethod._id === selectedPaymentMethod?._id

			return (
				<div className="flex items-center space-x-2">
					{isDefaultPaymentMethod && (
						<div className="flex items-center rounded-full border border-primary-800 bg-primary-800 bg-opacity-10 px-2 py-0.5">
							<span
								data-testid={formatDataTestId([
									dataTestIdPrefix,
									'pmsl-text-default-payment-method'
								])}
								className="caption-2-normal text-primary-800"
							>
								<FormattedMessage
									id="PaymentMethodsSelectionList.TagDefault"
									defaultMessage="Default"
								/>
							</span>
						</div>
					)}
					{isExpiredPaymentMethod && (
						<div className="flex items-center rounded-full border border-error-300 bg-error-300 bg-opacity-10 px-2 py-0.5">
							<span
								data-testid={formatDataTestId([
									dataTestIdPrefix,
									'pmsl-text-expired-payment-method',
									paymentMethod._id ?? ''
								])}
								className="caption-2-normal text-error-300"
							>
								<FormattedMessage
									id="PaymentMethodsSelectionList.TagExpired"
									defaultMessage="Expired"
								/>
							</span>
						</div>
					)}
					{isSelectedPaymentMethod ? (
						<CheckCircleIcon
							className="w-5 text-success-400"
							data-testid={formatDataTestId([
								dataTestIdPrefix,
								'pmsl-icon-selected-payment-method'
							])}
						/>
					) : (
						<div
							className="h-5 w-5 rounded-full border border-divider-primary"
							data-testid={formatDataTestId([
								dataTestIdPrefix,
								'pmsl-radio-select-payment-method',
								paymentMethod._id ?? ''
							])}
						/>
					)}
				</div>
			)
		},
		[dataTestIdPrefix, defaultPaymentMethod, selectedPaymentMethod]
	)

	/**
	 * Initialise Default Payment Method
	 * Note: Currently assumes that we only have 1 payment method, hence it's also the default payment.
	 * In the future, we should be handling multiple payment methods.
	 */
	useEffect((): void => {
		if (!defaultPaymentMethod && !selectedPaymentMethod && paymentMethods[0]) {
			handleDefaultPaymentMethodChange(paymentMethods[0])
			handleSelectedPaymentMethodChange(paymentMethods[0])
		}
	}, [
		defaultPaymentMethod,
		paymentMethods,
		selectedPaymentMethod,
		handleDefaultPaymentMethodChange,
		handleSelectedPaymentMethodChange
	])

	return (
		<div
			className={classNames(
				'flex-grow',
				isPaymentMethodsLoading ? 'flex flex-col items-center justify-center pb-6' : null,
				className
			)}
		>
			{isPaymentMethodsLoading ? (
				<Spinner />
			) : (
				<>
					{/* Payment Methods List */}
					<PaymentMethodsList
						className="mb-5"
						dataTestIdPrefix={formatDataTestId([dataTestIdPrefix, 'pmv'])}
						paymentMethods={paymentMethods}
						listItemRightRender={listItemRightRender}
						onPaymentMethodClick={handleSelectedPaymentMethodChange}
					/>
					{/* Add/Replace Payment Method Button */}
					{((): ReactNode => {
						switch (BRAND) {
							// TNG eWallet
							case Brand.TouchNGo: {
								return (
									paymentMethods.length === 0 && (
										<Button
											data-testid={formatDataTestId([
												dataTestIdPrefix,
												'pmsl-btn-add-payment-method'
											])}
											variant="dashed"
											className="w-full"
											disabled={isTngSetupIntentLoading || isAddTngPaymentMethodPending}
											loading={isAddTngPaymentMethodPending}
											onClick={addTngPaymentMethod}
										>
											+{' '}
											<FormattedMessage
												id="PaymentMethodsSelectionList.ButtonTextAddPaymentMethod"
												defaultMessage="Add Payment Method"
											/>
										</Button>
									)
								)
							}
							// Card Payment Method
							// FUTURE TODO: Should look into shifting the add/replacement flow inside here so that
							// users do not have to go back all the way out to the start of the `ChargerScreen`
							// or the `ReservationChargerScreen`.
							default: {
								return (
									<Button
										data-testid={formatDataTestId([dataTestIdPrefix, 'pmsl-btn-add-card'])}
										variant="dashed"
										className="w-full"
										disabled={isAddOrReplacePaymentMethodDisabled}
										linkProps={{ to: ScreenRoutePath.AccountPaymentMethodsNew }}
									>
										+{' '}
										{paymentMethods.length === 0 ? (
											<FormattedMessage
												id="PaymentMethodsSelectionList.ButtonTextAddCard"
												defaultMessage="Add Card"
											/>
										) : (
											<FormattedMessage
												id="PaymentMethodsSelectionList.ButtonTextReplaceCard"
												defaultMessage="Replace Card"
											/>
										)}
									</Button>
								)
							}
						}
					})()}
				</>
			)}
		</div>
	)
}

const MemoisedPaymentMethodsSelectionList = memo(PaymentMethodsSelectionList)

export default MemoisedPaymentMethodsSelectionList
