import { useRouteParams } from 'src/App/router/hooks'
import ScreenContainer from 'src/_shared/components/ScreenContainer'

interface ReservationDetailScreenRouteParams {
	[key: string]: string
	sessionId: string
}

// FUTURE TODO: Implement Reservation Details
const ReservationDetailScreen = (): JSX.Element => {
	const params = useRouteParams<ReservationDetailScreenRouteParams>()
	return (
		<ScreenContainer contentViewProps={{ className: 'px-5 py-6' }} hideBottomBar>
			<h1 className="mb-2 text-typography-primary">Reservation Detail Screen</h1>
			<code>{JSON.stringify(params, null, 2)}</code>
		</ScreenContainer>
	)
}

export default ReservationDetailScreen
