import { memo } from 'react'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'

interface FavouriteErrorModalProps {
	open: boolean
	onClose: () => void
	message: string | null
}

const FavouriteErrorModal = ({ open, onClose: handleClose, message }: FavouriteErrorModalProps) => {
	return (
		<Modal open={open} onClose={handleClose}>
			<ModalCard className="flex flex-col items-center">
				<h1 className="mb-2 text-center text-modal-typography-primary">
					Oops! Something Went Wrong
				</h1>
				<p
					data-testid="fem-text-error-message"
					className="body-2-normal mb-8 text-center text-modal-typography-primary"
				>
					{message ?? 'Please try again later.'}
				</p>
				<div className="flex w-full flex-col space-y-4">
					<Button onClick={handleClose} data-testid="fem-btn-close" variant="primary">
						Close
					</Button>
				</div>
			</ModalCard>
		</Modal>
	)
}

const MemoisedFavouriteErrorModal = memo(FavouriteErrorModal)

export default MemoisedFavouriteErrorModal
