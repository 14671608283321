import { memo, useMemo } from 'react'
import LocationDetailsClockIcon from 'src/_shared/components/_icons/LocationDetailsClockIcon'
import { ConnectorPowerType } from 'src/_shared/enums/filter'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { OmniLocation } from 'src/_shared/types/omni'
import { getHighestTariffPriceValue } from 'src/_shared/utils/charging'
import { classNames } from 'src/_shared/utils/elements'

import PowerCostBox from './components/PowerCostBox'

interface LocationDetailsBoxProps {
	location: OmniLocation
	className?: string
}

const LocationDetailsBox = ({ location, className }: LocationDetailsBoxProps) => {
	const { isAuthenticated } = useAuthContext()

	const [highestAcTariffPrice, highestDcTariffPrice] = useMemo((): [
		string | null,
		string | null
	] => {
		return [
			getHighestTariffPriceValue(location.evses, ConnectorPowerType.Ac, isAuthenticated),
			getHighestTariffPriceValue(location.evses, ConnectorPowerType.Dc, isAuthenticated)
		]
	}, [isAuthenticated, location.evses])

	return (
		<div className={classNames(className)}>
			{highestAcTariffPrice && (
				<PowerCostBox powerType={ConnectorPowerType.Ac} price={highestAcTariffPrice} />
			)}
			{highestDcTariffPrice && (
				<PowerCostBox powerType={ConnectorPowerType.Dc} price={highestDcTariffPrice} />
			)}
			{location.opening_times?.twentyfourseven && (
				<div className="flex flex-row items-center justify-center space-x-1">
					<LocationDetailsClockIcon className="h-3.5 w-3.5 text-typography-tertiary" />
					<p className="caption-3-normal text-typography-primary">24 Hours</p>
				</div>
			)}
		</div>
	)
}

const MemoisedLocationDetailsBox = memo(LocationDetailsBox)

export default MemoisedLocationDetailsBox
