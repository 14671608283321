import { useCallback, useEffect, useState } from 'react'
import { useQueryParams } from 'src/App/router/hooks'
import EnterOtpView from 'src/_shared/components/EnterOtpView'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'
import {
	useUserEmailOtpRequestMutation,
	useUserEmailOtpVerifyMutation
} from 'src/_shared/mutations/auth'

import useCountdownTimer from '../../../_shared/hooks/useCountdownTimer'
import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps, ChargerScreenQueryParams } from '../types'

type TransientEnterOtpViewProps = ChargerScreenCommonViewProps

const TransientEnterOtpView = ({
	routeParams,
	onNext: handleNext
}: TransientEnterOtpViewProps): JSX.Element => {
	const [shouldSendOtp, setShouldSendOtp] = useState<boolean>(true)

	const [otp, setOtp] = useState<string>('')

	const [{ email = '' }] = useQueryParams<ChargerScreenQueryParams>()

	const [seconds, resetCountdownTimer] = useCountdownTimer()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession } = useChargerSessionDetails(routeParams)

	const {
		error: otpResendError,
		isError: isOtpResendError,
		isPending: isRequestOtpPending,
		mutateAsync: requestOtp,
		reset: resetUserOtpRequestMutation
	} = useUserEmailOtpRequestMutation()

	const {
		error: otpVerifyError,
		isError: isOtpVerifyError,
		isPending: isOtpVerifyPending,
		mutateAsync: verifyOtp,
		reset: resetUserOtpVerifyMutation
	} = useUserEmailOtpVerifyMutation()

	const errorMessage =
		otpVerifyError?.response?.data.message ?? otpResendError?.response?.data.message

	const handleOtpChange = useCallback(
		(value: string): void => {
			setOtp(value)
			if (isOtpVerifyError && value !== otp) {
				resetUserOtpVerifyMutation()
			}
		},
		[isOtpVerifyError, otp, resetUserOtpVerifyMutation]
	)

	const handleRequestOtpClick = useCallback((): void => {
		void requestOtp(
			{
				email
			},
			{
				onSuccess: (): void => {
					resetUserOtpRequestMutation()
					resetCountdownTimer()
				}
			}
		)
	}, [email, requestOtp, resetCountdownTimer, resetUserOtpRequestMutation])

	const handleOtpFilled = useCallback(
		(value: string): void => {
			void verifyOtp(
				{
					email,
					code: value
				},
				{
					onSuccess: (): void => {
						resetUserOtpVerifyMutation()
						handleNext?.()
					}
				}
			)
		},
		[email, handleNext, resetUserOtpVerifyMutation, verifyOtp]
	)

	/**
	 * In the event that we came from the Transient Email Screen,
	 * check if we should send an otp immediately upon entering this page.
	 */
	useEffect(() => {
		if (shouldSendOtp) {
			setShouldSendOtp(false)
			handleRequestOtpClick()
		}
	}, [handleRequestOtpClick, shouldSendOtp])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.TransientEnterOtpView
	})

	return (
		<EnterOtpView
			className="pb-6"
			dataTestIdPrefix="cs-teov"
			otpRecipient={
				<span
					data-testid="cs-teov-text-email"
					className="body-1-semibold mt-1 block text-primary-800"
				>
					{email}
				</span>
			}
			secondsCountdown={seconds}
			isOtpVerifyPending={isOtpVerifyPending}
			isRequestOtpPending={isRequestOtpPending}
			inputOtpProps={{
				value: otp,
				disabled: isOtpVerifyPending,
				error: isOtpVerifyError || isOtpResendError,
				description: errorMessage,
				autoFocus: true,
				onChange: handleOtpChange,
				onFilled: handleOtpFilled
			}}
			onRequestOtpClick={handleRequestOtpClick}
		/>
	)
}

export default TransientEnterOtpView
