import dayjs from 'dayjs'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQueryParams, useRouterLocation, useRouterNavigate } from 'src/App/router/hooks'
import { ScreenRoutePath } from 'src/App/router/types'
import Alert from 'src/_shared/components/Alert'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import Notice from 'src/_shared/components/Notice'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import Skeleton from 'src/_shared/components/Skeleton'
import Spinner from 'src/_shared/components/Spinner'
import ChargerConnectorImage from 'src/_shared/components/_images/ChargerConnectorImage'
import EvCarImage from 'src/_shared/components/_images/EvCarImage'
import { APP_MODE, BACK_LINK, BRAND, TIME_FORMAT } from 'src/_shared/constants/env'
import { TRANSIENT_SESSION_ID_KEY } from 'src/_shared/constants/storage'
import { AppMode, Brand } from 'src/_shared/enums/env'
import {
	OmniConnectorPowerType,
	OmniConnectorStatus,
	OmniSessionChargingPeriodDimensionType,
	OmniSessionStatus
} from 'src/_shared/enums/omni'
import { PaymentMethodCode, PaymentTypeCode } from 'src/_shared/enums/payments'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'
import { useIsUserSubscribed } from 'src/_shared/hooks/useIsUserSubscribed'
import { useLocalStorageItem } from 'src/_shared/hooks/useStorageItem'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import useTimeFormatter from 'src/_shared/hooks/useTimeFormatter'
import {
	StartChargingSessionMutationParams,
	useStartChargingSessionMutation
} from 'src/_shared/mutations/sessions'
import {
	ChargerScreenPreChargingViewFeatureToggles,
	ChargerScreenTransientFlowFeatureToggles
} from 'src/_shared/queries/strapi'
import { classNames } from 'src/_shared/utils/elements'
import { formatCurrencyValue } from 'src/_shared/utils/string'

import { PERIOD_TIME_FORMAT, DAY_NUMBER_TO_STRING } from '../constants'
import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerItemLists } from '../hooks/useChargerItemLists'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps, ChargerScreenQueryParams } from '../types'
import { getLatestChargingPeriodDimension, getTimeFromSeconds, isLocationOpen } from '../utils'
import ChargingHeader from './ChargingHeader'
import DetailsGrid from './DetailsGrid'
import Footer from './Footer'
import OpeningHoursModal from './OpeningHoursModal'
import StateOfChargeCircle from './StateOfChargeCircle'
import TariffModal from './TariffModal'
import TermsAndConditionsBottomRender from './TermsAndConditionsBottomRender'

type PreChargingViewProps = Omit<ChargerScreenCommonViewProps, 'onNext'>

interface IntermediateModalRender {
	content?: ReactNode
	nextButtonText?: ReactNode
}

interface FooterRenders {
	topRender?: ReactNode
	buttonVariant?: 'primary' | 'ghost'
	buttonText?: ReactNode
	isButtonTextNext?: boolean
	onButtonClick?: () => void
}

const PreChargingView = ({
	isAuthenticated = false,
	routeParams,
	updateView
}: PreChargingViewProps): JSX.Element => {
	const [isLocationClosed, setIsLocationClosed] = useState<boolean>(false)

	const [intermediateModalRenderIndex, setIntermediateModalRenderIndex] = useState<number>(
		-1 // If index is negative, then the intermediate modal is closed.
	)

	const [isTariffModalOpen, setIsTariffModalOpen] = useState<boolean>(false)

	const [isOpeningHoursModalOpen, setIsOpeningHoursModalOpen] = useState<boolean>(false)

	const intl = useIntl()

	const [{ payment_success: paymentSuccess = 'false', vehicle_id: vehicleId }, setQueryParams] =
		useQueryParams<ChargerScreenQueryParams>()

	const { key: historyKey, pathname } = useRouterLocation()

	const navigate = useRouterNavigate()

	const [transientSessionId, setTransientSessionId] = useLocalStorageItem(TRANSIENT_SESSION_ID_KEY)

	const isUserSubscribed = useIsUserSubscribed(routeParams.cpoEntityCode)

	const { getFeatureToggles } = useStrapiContext()

	const { formatTimeToString } = useTimeFormatter()

	const {
		location,
		evse,
		connector,
		activeTariff,
		locationError,
		connectorError,
		locationQueryStatus,
		...chargerDetails
	} = useChargerDetails({
		...routeParams,
		enableActiveTariffQuery: true
	})

	const {
		session,
		isUserChargingSession,
		isUserActiveChargingSession,
		isUserActiveChargingSessionElsewhere,
		isUserReservedChargingSession,
		isUserReservedChargingSessionElsewhere,
		chargerSessionDetailsQueryStatus,
		resetChargerSessionDetailsQueries
	} = useChargerSessionDetails(routeParams)

	const {
		isPending: isStartChargingSessionPending,
		isSuccess: isStartChargingSessionSuccess,
		mutateAsync: startChargingSession
	} = useStartChargingSessionMutation()

	const isStartChargingSessionLoading =
		isStartChargingSessionPending || isStartChargingSessionSuccess

	const isTransientFlow = APP_MODE === AppMode.Transient

	const isChargerSessionDetailsSuccess = chargerSessionDetailsQueryStatus === 'success'

	const isLocationPending = locationQueryStatus === 'pending'

	const { isEmailOptional, showIntermediateModalConnectReminder, skipIntermediateModal } =
		useMemo((): ChargerScreenPreChargingViewFeatureToggles &
			ChargerScreenTransientFlowFeatureToggles => {
			const preChargingViewFeatureToggles =
				getFeatureToggles('chargerScreen')?.preChargingView ?? {}
			const transientFlowFeatureToggles = getFeatureToggles('chargerScreen')?.transientFlow ?? {}

			return {
				...preChargingViewFeatureToggles,
				...transientFlowFeatureToggles
			}
		}, [getFeatureToggles])

	const showIntermediateModal = useCallback((): void => {
		setIntermediateModalRenderIndex(0)
	}, [])

	const closeIntermediateModal = useCallback((): void => {
		if (!isStartChargingSessionLoading) {
			setIntermediateModalRenderIndex(-1)
		}
	}, [isStartChargingSessionLoading])

	const toggleTariffModal = useCallback((): void => {
		setIsTariffModalOpen((isOpen): boolean => !isOpen)
	}, [])

	const toggleOpeningHoursModal = useCallback((): void => {
		setIsOpeningHoursModalOpen((isOpen): boolean => !isOpen)
	}, [])

	const { gridItemList, tariffInformationItemList } = useChargerItemLists({
		location,
		connector,
		activeTariff,
		session: null,
		isAuthenticated,
		isUserSubscribed,
		toggleTariffModal
	})

	const handleNext = useCallback((): void => {
		// Handle Transient Flow
		if (isTransientFlow) {
			if (isEmailOptional) {
				updateView?.(ChargerScreenViewItemKey.TransientEmailPromptView)
			} else {
				updateView?.(ChargerScreenViewItemKey.TransientEmailView)
			}
		}
		// Handle Default and SSO Flows
		else {
			const isBrandExternalPayment = [Brand.Evme].includes(BRAND)

			// Attempt to start the charging session if:
			// 1. The user is subscribed.
			// 2. There are no costs for using the charger.
			// 3. The current `BRAND` has their own payment handling, e.g. EVme.
			if (
				isUserSubscribed ||
				!activeTariff ||
				(!activeTariff.price_per_kwh && !activeTariff.price_per_min) ||
				isBrandExternalPayment
			) {
				const params = ((): StartChargingSessionMutationParams => {
					if (isBrandExternalPayment) {
						return {
							...routeParams,
							paymentMethodCode: ((): PaymentMethodCode => {
								switch (BRAND) {
									case Brand.Evme:
										return PaymentMethodCode.Evme
									default:
										return PaymentMethodCode.Unknown
								}
							})(),
							paymentTypeCode: PaymentTypeCode.Redirect,
							paymentBackUrl: [window.location.origin, ScreenRoutePath.SessionCancel].join(''),
							vehicleId
						}
					}
					return routeParams
				})()

				void startChargingSession(params, {
					onError: (): void => {
						updateView?.(ChargerScreenViewItemKey.ErrorView, {
							type: 'error',
							header: intl.formatMessage({
								id: 'PreChargingView.TitleStartChargeFailure',
								defaultMessage: 'Failed To Start Charge'
							}),
							description: intl.formatMessage({
								id: 'PreChargingView.DescriptionStartChargeFailure',
								defaultMessage: 'Please try again later'
							})
						})
					},
					onSuccess: (response): void => {
						const handleStartChargingSuccess = async (): Promise<void> => {
							const paymentRedirectUrl = response.data.data?.payment_url
							if (paymentRedirectUrl) {
								console.debug(
									`[${ChargerScreenViewItemKey.PreChargingView}] Redirecting to:`,
									paymentRedirectUrl
								)
								window.location.href = paymentRedirectUrl
							} else {
								await resetChargerSessionDetailsQueries()
								updateView?.(ChargerScreenViewItemKey.ChargingView)
							}
						}
						void handleStartChargingSuccess()
					}
				})
			} else {
				updateView?.(ChargerScreenViewItemKey.PaymentMethodsView)
			}
		}
	}, [
		activeTariff,
		intl,
		isEmailOptional,
		isTransientFlow,
		isUserSubscribed,
		routeParams,
		vehicleId,
		resetChargerSessionDetailsQueries,
		startChargingSession,
		updateView
	])

	/**
	 * Where applicable, renders the content displayed inside of the intermediate modal.
	 * If this evaluates to `null`, then the intermediate modal should not open up.
	 */
	const intermediateModalRenders = useMemo((): IntermediateModalRender[] => {
		// Feature Toggle to skip the display of intermediate modal
		if (skipIntermediateModal) {
			return []
		}

		// Display connect reminder
		const connectReminderRender = ((): IntermediateModalRender | null => {
			if (showIntermediateModalConnectReminder) {
				return {
					content: (
						<>
							<div className="mb-5 flex items-center justify-center space-x-1">
								<ChargerConnectorImage
									className="h-9 w-9"
									variant={((): 'light' | 'dark' => {
										switch (BRAND) {
											case Brand.Evme:
												return 'light'
											default:
												return 'dark'
										}
									})()}
								/>
								<EvCarImage
									className="h-auto w-28"
									fills={((): Record<string, string> | null => {
										switch (BRAND) {
											case Brand.Evme:
												return {
													fillBody: '#9CFF90',
													fillBodyLines: '#39A574',
													fillFrontLightsBottom: '#20952E',
													fillWheelArch: '#39A574'
												}
											default:
												return null
										}
									})()}
								/>
							</div>
							<h1 className="mb-2 text-center text-modal-typography-primary">
								<FormattedMessage
									id="PreChargingView.ModalTitleConnectReminder"
									defaultMessage="Connect The Charging Cable"
								/>
							</h1>
							<p className="body-2-light mb-8 text-center text-modal-typography-primary">
								<FormattedMessage
									id="PreChargingView.ModalDescriptionConnectReminder"
									defaultMessage="Please <b>connect</b> in the cable securely and <b>lock</b> your EV to begin charging."
									values={{
										b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>
									}}
								/>
							</p>
						</>
					),
					nextButtonText: (
						<FormattedMessage
							id="PreChargingView.ModalButtonTextConnectConfirm"
							defaultMessage="Cable is Connected"
						/>
					)
				}
			}
			return null
		})()

		// Handle Transient Flow
		if (isTransientFlow) {
			// Display provided `tariff_alt_text`.
			const tariffAltTextRender = ((): IntermediateModalRender | null => {
				if (activeTariff?.tariff_alt_text?.some(({ text }): boolean => !!text?.trim().length)) {
					return {
						content: (
							<>
								<h1 className="mb-2 text-center text-modal-typography-primary">
									<FormattedMessage id="PreChargingView.ModalTitleNotice" defaultMessage="Notice" />
								</h1>
								{activeTariff.tariff_alt_text.map(({ text }, index): JSX.Element | null => {
									const displayText = text?.trim()
									if (displayText) {
										return (
											<p
												key={index}
												className="body-2-light mb-8 text-center text-modal-typography-primary"
											>
												{displayText}
											</p>
										)
									}
									return null
								})}
							</>
						),
						nextButtonText: (
							<FormattedMessage
								id="PreChargingView.ModalButtonTextUnderstand"
								defaultMessage="I Understand"
							/>
						)
					}
				}
				return null
			})()

			return [connectReminderRender, tariffAltTextRender].filter(
				Boolean
			) as IntermediateModalRender[]
		}
		// Handle Default and SSO Flows
		else {
			// Idle Fees Reminder
			const idleFeesReminderRender = ((): IntermediateModalRender | null => {
				if (activeTariff?.price_per_min_parked) {
					const today = DAY_NUMBER_TO_STRING[dayjs().get('day')]

					const hasBlackoutPeriodToday =
						activeTariff.idle_blackout_period?.day_of_week?.includes(today)

					const blackoutStartTime = dayjs(
						activeTariff.idle_blackout_period?.start_time_of_day,
						PERIOD_TIME_FORMAT
					).format(TIME_FORMAT)

					const blackoutEndTime = dayjs(
						activeTariff.idle_blackout_period?.end_time_of_day,
						PERIOD_TIME_FORMAT
					).format(TIME_FORMAT)

					const gracePeriodSeconds = activeTariff.grace_period_seconds ?? 0

					const graceDuration = formatTimeToString(getTimeFromSeconds(gracePeriodSeconds))

					const idleCostMaximum = activeTariff.max_price_per_min_parked

					const idleCostRate = activeTariff.price_per_min_parked.incl_vat

					return {
						content: (
							<>
								<h1 className="mb-2 text-center text-modal-typography-primary">
									<FormattedMessage
										id="PreChargingView.ModalTitleReminder"
										defaultMessage="Reminder"
									/>
								</h1>
								<p
									className={classNames(
										'body-2-light text-center text-modal-typography-primary',
										hasBlackoutPeriodToday ? 'mb-2' : 'mb-8'
									)}
								>
									<FormattedMessage
										id="PreChargingView.ModalDescriptionIdleFeesRate"
										defaultMessage="Idling fees may apply at a rate of <b>{pricePerMin}/min</b> at a cap of <b>{maxPrice}</b> after a grace period of <b>{graceDuration}</b>. Please vacate the lot when charging is complete."
										values={{
											b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>,
											pricePerMin: formatCurrencyValue(idleCostRate, activeTariff.currency),
											maxPrice: formatCurrencyValue(idleCostMaximum, activeTariff.currency, 2),
											graceDuration: graceDuration
										}}
									/>
								</p>
								{hasBlackoutPeriodToday && (
									<p className="body-2-light mb-8 text-center text-modal-typography-primary">
										<FormattedMessage
											id="PreChargingView.ModalDescriptionIdleFeesBlackoutPeriod"
											defaultMessage="Idling fees will not be applied from <b>{blackoutStartTime}</b> to <b>{blackoutEndTime}</b>."
											values={{
												b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>,
												blackoutStartTime,
												blackoutEndTime
											}}
										/>
									</p>
								)}
							</>
						),
						nextButtonText: (
							<FormattedMessage
								id="PreChargingView.ModalButtonTextUnderstand"
								defaultMessage="I Understand"
							/>
						)
					}
				}

				return null
			})()

			return [connectReminderRender, idleFeesReminderRender].filter(
				Boolean
			) as IntermediateModalRender[]
		}
	}, [
		activeTariff,
		isTransientFlow,
		showIntermediateModalConnectReminder,
		skipIntermediateModal,
		formatTimeToString
	])

	const handleIntermediateModalNextClick = useCallback((): void => {
		// Show next modal content
		if (intermediateModalRenderIndex < intermediateModalRenders.length - 1) {
			setIntermediateModalRenderIndex(intermediateModalRenderIndex + 1)
		}
		// Conclude Pre-Charging View
		else {
			handleNext()
		}
	}, [intermediateModalRenderIndex, intermediateModalRenders.length, handleNext])

	const footerRenders = useMemo((): FooterRenders => {
		/**
		 * Returns the user to the previous page found in history.
		 * If there is no history, this defaults to Map screen.
		 */
		const handleBackClick = (): void => {
			const hasPreviousHistory = historyKey !== 'default'
			if (hasPreviousHistory) {
				navigate(-1)
			} else {
				if (BACK_LINK) {
					window.location.href = BACK_LINK
				} else {
					navigate(ScreenRoutePath.Root)
				}
			}
		}

		const handleLoginClick = (): void => {
			navigate({
				pathname: ScreenRoutePath.AccountLogin,
				search: `redirect=${pathname}`
			})
		}

		const handleBookNowClick = (): void => {
			navigate([
				ScreenRoutePath.ReservationCharger,
				routeParams.cpoEntityCode,
				routeParams.locationUid,
				routeParams.evseUid,
				routeParams.connectorUid
			])
		}

		// User has an Active session elsewhere
		if (isUserActiveChargingSessionElsewhere) {
			return {
				topRender: (
					<>
						<h1 className="mb-6 max-w-64 text-center text-error-300">
							<FormattedMessage
								id="PreChargingView.ConnectorStatusTextOngoing"
								defaultMessage="You have an ongoing charge session. Please end the ongoing session to start a new session."
							/>
						</h1>
						{chargerDetails.isReservationCapable && (
							<Button
								className="mb-4 w-full"
								data-testid="cs-pcv-book-now-disabled"
								disabled={true}
							>
								<FormattedMessage
									id="PreChargingView.ButtonTextBookNow"
									defaultMessage="Book Now"
								/>
							</Button>
						)}
					</>
				),
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextViewChargingSession',
					defaultMessage: 'View Charging Session'
				}),
				onButtonClick: (): void => {
					const sessionId = session?._id ?? ''
					navigate([ScreenRoutePath.Session, sessionId])
				}
			}
		}
		// User has a Reserved session elsewhere
		else if (isUserReservedChargingSessionElsewhere) {
			return {
				topRender: (
					<>
						<Alert className="mb-4">
							<FormattedMessage
								id="PreChargingView.InformationDescriptionBookingElsewhere"
								defaultMessage="You have an upcoming session reserved at {locationName} from {startTime} to {endTime}. View your booking for further details."
								values={{
									// FUTURE TODO: Provide Reservation Details
									locationName: '123 Ele Road',
									startTime: dayjs().startOf('day').format(TIME_FORMAT),
									endTime: dayjs().endOf('day').format(TIME_FORMAT)
								}}
							/>
						</Alert>
						{chargerDetails.isReservationCapable && (
							<Button
								className="mb-4 w-full"
								data-testid="cs-pcv-book-now-disabled"
								disabled={true}
							>
								<FormattedMessage
									id="PreChargingView.ButtonTextBookNow"
									defaultMessage="Book Now"
								/>
							</Button>
						)}
					</>
				),
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextViewYourBooking',
					defaultMessage: 'View Your Booking'
				}),
				onButtonClick: (): void => {
					const sessionId = session?._id ?? ''
					navigate([ScreenRoutePath.ReservationDetail, sessionId])
				}
			}
		}
		// Connector is offline
		else if (connector?.online === false) {
			return {
				topRender: (
					<h1 className="mb-6 max-w-64 text-center text-error-300">
						<FormattedMessage
							id="PreChargingView.ConnectorStatusTextOffline"
							defaultMessage="Charger Offline"
						/>
					</h1>
				),
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextBack',
					defaultMessage: 'Back'
				}),
				onButtonClick: handleBackClick
			}
		}
		// Connector is disabled
		else if (connector?.is_enabled === false) {
			return {
				topRender: (
					<h1 className="mb-6 max-w-64 text-center text-error-300">
						<FormattedMessage
							id="PreChargingView.ConnectorStatusTextUnavailable"
							defaultMessage="Charger Unavailable"
						/>
					</h1>
				),
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextBack',
					defaultMessage: 'Back'
				}),
				onButtonClick: handleBackClick
			}
		}
		// Location is closed
		else if (isLocationClosed) {
			return {
				topRender: (
					<>
						<h1 className="mb-6 max-w-64 text-center text-error-300">
							<FormattedMessage
								id="PreChargingView.ConnectorStatusTextClosed"
								defaultMessage="Charger Closed. Please refer to Opening Hours for more information."
							/>
						</h1>
						{chargerDetails.isReservationCapable && (
							<Button
								className="mb-4 w-full"
								data-testid="cs-pcv-conditional-book-now"
								disabled={!chargerDetails.reservationType}
								onClick={handleBookNowClick}
							>
								<FormattedMessage
									id="PreChargingView.ButtonTextBookNow"
									defaultMessage="Book Now"
								/>
							</Button>
						)}
					</>
				),
				buttonVariant:
					chargerDetails.isReservationCapable && !!chargerDetails.reservationType
						? 'ghost'
						: 'primary',
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextBack',
					defaultMessage: 'Back'
				}),
				onButtonClick: handleBackClick
			}
		}
		// Missing Tariff
		else if (connector && !activeTariff) {
			return {
				topRender: (
					<h1 className="mb-6 max-w-64 text-center text-error-300">
						<FormattedMessage
							id="PreChargingView.ConnectorStatusTextMissingPrice"
							defaultMessage="Pricing for this charger is currently missing. Please try another charger nearby."
						/>
					</h1>
				),
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextBack',
					defaultMessage: 'Back'
				}),
				onButtonClick: handleBackClick
			}
		}
		// Reservation Capable
		if (chargerDetails.isReservationCapable) {
			// Unhandled Reservation Flow
			if (!chargerDetails.reservationType) {
				return {
					topRender: (
						<h1 className="mb-6 max-w-64 text-center text-error-300">
							<FormattedMessage
								id="PreChargingView.ConnectorStatusTextReservationComingSoon"
								defaultMessage="This charger requires a reservation (coming soon). Please try another charger nearby."
							/>
						</h1>
					),
					buttonText: intl.formatMessage({
						id: 'PreChargingView.ButtonTextBack',
						defaultMessage: 'Back'
					}),
					onButtonClick: handleBackClick
				}
			}
			// User without Reservation
			else if (!isUserReservedChargingSession) {
				return {
					buttonText: isAuthenticated
						? intl.formatMessage({
								id: 'PreChargingView.ButtonTextBookNow',
								defaultMessage: 'Book Now'
							})
						: intl.formatMessage({
								id: 'PreChargingView.ButtonTextLogin',
								defaultMessage: 'Login'
							}),
					onButtonClick: isAuthenticated ? handleBookNowClick : handleLoginClick
				}
			}
		}
		// Connector Status Text
		if (connector?.status) {
			switch (connector.status) {
				case OmniConnectorStatus.Available:
					return {
						topRender: (
							<h1 className="mb-6 max-w-64 text-center text-error-300">
								<FormattedMessage
									id="PreChargingView.ConnectorStatusTextAvailable"
									defaultMessage="Please Connect Charger"
								/>
							</h1>
						),
						buttonText:
							isAuthenticated || isTransientFlow
								? intl.formatMessage({
										id: 'PreChargingView.ButtonTextNext',
										defaultMessage: 'Next'
									})
								: intl.formatMessage({
										id: 'PreChargingView.ButtonTextLogin',
										defaultMessage: 'Login'
									}),
						isButtonTextNext: isAuthenticated || isTransientFlow,
						onButtonClick: !isAuthenticated && !isTransientFlow ? handleLoginClick : undefined
					}
				case OmniConnectorStatus.Preparing:
					return {
						topRender: (
							<h1 className="mb-6 max-w-64 text-center text-primary-800">
								<FormattedMessage
									id="PreChargingView.ConnectorStatusTextReady"
									defaultMessage="Charger Ready!"
								/>
							</h1>
						),
						...(isAuthenticated || isTransientFlow
							? // Proceed to payment method selection and
								// start charging after successful payment.
								{
									buttonText: intl.formatMessage({
										id: 'PreChargingView.ButtonTextNext',
										defaultMessage: 'Next'
									}),
									isButtonTextNext: true,
									onButtonClick:
										intermediateModalRenders.length > 0 ? showIntermediateModal : handleNext
								}
							: // User has to login before they are able to start charging.
								{
									buttonText: intl.formatMessage({
										id: 'PreChargingView.ButtonTextLogin',
										defaultMessage: 'Login'
									}),
									onButtonClick: handleLoginClick
								})
					}
				default:
					return {
						topRender: (
							<h1 className="mb-6 max-w-64 text-center text-error-300">
								<FormattedMessage
									id="PreChargingView.ConnectorStatusTextUnavailable"
									defaultMessage="Charger Unavailable"
								/>
							</h1>
						),
						buttonText: intl.formatMessage({
							id: 'PreChargingView.ButtonTextBack',
							defaultMessage: 'Back'
						}),
						onButtonClick: handleBackClick
					}
			}
		}
		// Connector is still loading (or does not exist)
		return {}
	}, [
		activeTariff,
		chargerDetails,
		connector,
		historyKey,
		intl,
		isAuthenticated,
		isLocationClosed,
		isTransientFlow,
		isUserActiveChargingSessionElsewhere,
		isUserReservedChargingSession,
		isUserReservedChargingSessionElsewhere,
		intermediateModalRenders.length,
		pathname,
		routeParams,
		session?._id,
		handleNext,
		navigate,
		showIntermediateModal
	])

	/**
	 * Keep track of Location's opening hours to determine connector's correct status text.
	 */
	useEffect((): (() => void) | undefined => {
		if (location?.opening_times) {
			const intervalId = setInterval(
				(): void => {
					setIsLocationClosed(!isLocationOpen(location.opening_times ?? {}))
				},
				1000 // 1 Second
			)
			setIsLocationClosed(!isLocationOpen(location.opening_times ?? {}))
			return (): void => {
				clearInterval(intervalId)
			}
		}
	}, [location?.opening_times])

	/**
	 * Clear previous Transient Session ID if that session has already concluded or if payment was unsuccessful.
	 */
	useEffect((): void => {
		if (
			isTransientFlow &&
			!!transientSessionId &&
			!!session?.status &&
			// Session is no longer ongoing
			([
				OmniSessionStatus.Completed,
				OmniSessionStatus.StartFailure,
				OmniSessionStatus.StopFailure,
				OmniSessionStatus.Stopped
			].includes(session.status) ||
				// Session payment did not succeed
				(session.status === OmniSessionStatus.PendingPayment && paymentSuccess === 'false'))
		) {
			console.debug(
				`[${ChargerScreenViewItemKey.PreChargingView}] Cleared previous Transient Session ID:`,
				{
					transientSessionId,
					paymentSuccess,
					sessionStatus: session.status
				}
			)
			setTransientSessionId(null)
			setQueryParams(
				(queryParams): URLSearchParams => {
					if (queryParams.has('payment_success')) {
						queryParams.delete('payment_success')
					}
					return queryParams
				},
				{
					replace: true
				}
			)
		}
	}, [
		isTransientFlow,
		paymentSuccess,
		session?.status,
		transientSessionId,
		setQueryParams,
		setTransientSessionId
	])

	/**
	 * If the user has an active or finishing session on the charger,
	 * jump to the required view. This check should only execute once.
	 */
	useEffect((): void => {
		if (!!connector?.status && isChargerSessionDetailsSuccess) {
			// Session is active; jump to Charging View.
			if (isUserActiveChargingSession) {
				// If the `PARKING_TIME` dimension is present, then the session should be done with charging and is finishing.
				const hasParkingTimeDimension = !!getLatestChargingPeriodDimension(
					session?.charging_periods,
					OmniSessionChargingPeriodDimensionType.ParkingTime
				)
				if (hasParkingTimeDimension) {
					console.debug(
						`[${ChargerScreenViewItemKey.PreChargingView}] User has a finishing session with parking time on this charger`
					)
					updateView?.(ChargerScreenViewItemKey.PostChargingView)
				} else {
					console.debug(
						`[${ChargerScreenViewItemKey.PreChargingView}] User has an active session on this charger`
					)
					updateView?.(ChargerScreenViewItemKey.ChargingView)
				}
			} else if (isUserChargingSession) {
				if (
					session?.status &&
					[OmniSessionStatus.Pending, OmniSessionStatus.PendingPayment].includes(session.status)
				) {
					console.debug(
						`[${ChargerScreenViewItemKey.PreChargingView}] User has a pending session on this charger`
					)
					updateView?.(ChargerScreenViewItemKey.ChargingView)
				}
				// Session is complete but connector is still in finishing; jump to Post-Charging View.
				else if (
					session?.status &&
					// Session has not failed
					![OmniSessionStatus.StartFailure, OmniSessionStatus.StopFailure].includes(
						session.status
					) &&
					// Note: Unable to account for `OUTOFORDER` or `UNKNOWN`.
					connector.status === OmniConnectorStatus.Finishing
				) {
					console.debug(
						`[${ChargerScreenViewItemKey.PreChargingView}] User has a finishing session on this charger`
					)
					updateView?.(ChargerScreenViewItemKey.PostChargingView)
				}
			}
			// No active or finishing session.
			else {
				console.debug(
					`[${ChargerScreenViewItemKey.PreChargingView}] User does not have any active or finishing session on this charger`
				)
			}
		}
	}, [
		connector?.status,
		isChargerSessionDetailsSuccess,
		isUserActiveChargingSession,
		isUserChargingSession,
		session?.charging_periods,
		session?.status,
		updateView
	])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.PreChargingView
	})

	// Loader (to hide view jumping)
	if (
		(isAuthenticated && !isChargerSessionDetailsSuccess) ||
		(isTransientFlow && !!transientSessionId)
	) {
		return (
			<div className="flex flex-grow flex-col items-center justify-center pb-6">
				<Spinner />
			</div>
		)
	}
	// Error Display
	// Note: Do not use `updateView` for this error because the Back Button
	// should return the user to the last screen prior to the Charger Screen.
	else if (!!locationError || !!connectorError) {
		const statusCode = locationError?.response?.status ?? connectorError?.response?.status
		return (
			<Notice
				type="error"
				header={
					<h1 className="text-center text-typography-primary">
						{statusCode === 404 ? (
							<FormattedMessage
								id="PreChargingView.TitleChargerNotFound"
								defaultMessage="Charger Not Found"
							/>
						) : (
							<FormattedMessage
								id="PreChargingView.TitleOopsFailure"
								defaultMessage="Oops! Something Went Wrong"
							/>
						)}
					</h1>
				}
			/>
		)
	}
	// Pre-Charging View
	return (
		<>
			{/* Header and Content Body */}
			<div className="flex-grow">
				<ChargingHeader
					className="mb-1"
					loading={isLocationPending}
					location={location}
					evse={evse}
					connector={connector}
					topRender={((): JSX.Element | null => {
						switch (BRAND) {
							case Brand.Kineta: {
								return <StateOfChargeCircle className="mb-3" />
							}
							default: {
								// Show State of Charge only for DC Chargers
								if (connector?.power_type === OmniConnectorPowerType.Dc) {
									return <StateOfChargeCircle className="mb-3" />
								}
								return null
							}
						}
					})()}
					bottomRender={
						<Skeleton loading={isLocationPending}>
							<div className="space-x-1.5">
								<button data-testid="cs-pcv-btn-toggle-tariff-modal" onClick={toggleTariffModal}>
									<span className="body-1-normal text-typography-primary underline">
										<FormattedMessage
											id="PreChargingView.ButtonTextFeeDetails"
											defaultMessage="Fee Details"
										/>
									</span>
								</button>
								<span className="text-typography-tertiary">&#x2022;</span>
								<button
									data-testid="cs-pcv-btn-toggle-opening-hours-modal"
									onClick={toggleOpeningHoursModal}
								>
									<span className="body-1-normal text-typography-primary underline">
										<FormattedMessage
											id="PreChargingView.ButtonTextOpeningHours"
											defaultMessage="Opening Hours"
										/>
									</span>
								</button>
							</div>
						</Skeleton>
					}
				/>
				<DetailsGrid location={location} itemList={gridItemList} loading={isLocationPending} />
			</div>
			{/* Footer */}
			<Footer
				buttonProps={{
					variant: footerRenders.buttonVariant,
					children: footerRenders.buttonText,
					className: 'w-full',
					disabled:
						isLocationPending || isStartChargingSessionLoading || !footerRenders.onButtonClick,
					loading: isStartChargingSessionLoading,
					onClick: footerRenders.onButtonClick
				}}
				topRender={
					<Skeleton className="mb-6 h-5 w-36" loading={isLocationPending}>
						{footerRenders.topRender}
					</Skeleton>
				}
				bottomRender={((): JSX.Element | null => {
					if ((isAuthenticated || isTransientFlow) && footerRenders.isButtonTextNext) {
						return (
							<TermsAndConditionsBottomRender
								className="mt-6"
								prefixRender={
									<FormattedMessage
										id="PreChargingView.FooterTextTermsAndConditionsPrefix"
										defaultMessage='By clicking "Next", you agree to our '
									/>
								}
							/>
						)
					}
					return null
				})()}
			/>
			<PoweredByFooter className="pb-6" />
			{/* Intermediate Modal */}
			<Modal open={intermediateModalRenderIndex >= 0} onClose={closeIntermediateModal}>
				<ModalCard className="flex flex-col items-center">
					{/* Content */}
					{intermediateModalRenders[intermediateModalRenderIndex]?.content}
					{/* Button Controls */}
					<div className="flex w-full flex-col space-y-4">
						<Button
							data-testid="cs-pcv-im-btn-next"
							variant="primary"
							loading={isStartChargingSessionLoading}
							disabled={isStartChargingSessionLoading}
							onClick={handleIntermediateModalNextClick}
						>
							{intermediateModalRenders[intermediateModalRenderIndex]?.nextButtonText}
						</Button>
						<Button
							data-testid="cs-pcv-im-btn-cancel"
							variant="secondary"
							disabled={isStartChargingSessionLoading}
							onClick={closeIntermediateModal}
						>
							<FormattedMessage
								id="PreChargingView.ModalButtonTextCancel"
								defaultMessage="Cancel"
							/>
						</Button>
					</div>
				</ModalCard>
			</Modal>
			{/* Tariff Modal */}
			<TariffModal
				location={location}
				activeTariff={activeTariff}
				open={isTariffModalOpen}
				itemList={tariffInformationItemList}
				onClose={toggleTariffModal}
			/>
			{/* Opening Hours Modal */}
			<OpeningHoursModal
				location={location}
				open={isOpeningHoursModalOpen}
				onClose={toggleOpeningHoursModal}
			/>
		</>
	)
}

export default PreChargingView
