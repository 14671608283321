import { ConnectorPowerType, SubscriptionStatus } from 'src/_shared/enums/filter'
import { LocationFilters } from 'src/_shared/types/filter'
import { OmniEvse, OmniLocation } from 'src/_shared/types/omni'
import { getConnectorPowerType } from 'src/_shared/utils/charging'

/**
 * Filters EVSEs based on the presence of connectors and an optional power type filter.
 * @param {OmniEvse[]} evses The list of EVSEs to filter.
 * @param {ConnectorPowerType|null} powerTypeFilter The power type filter to apply. If `null`, no power type filtering is applied.
 * @returns {OmniEvse[]} The filtered list of EVSEs that have connectors and match the power type filter.
 */
export const filterEvsesByPowerType = (
	evses: OmniEvse[] = [],
	powerTypeFilter: ConnectorPowerType | null
): OmniEvse[] => {
	return evses.filter((evse): boolean => {
		if (powerTypeFilter) {
			return (
				!!evse.connectors &&
				evse.connectors.some(
					(connector): boolean => getConnectorPowerType(connector) === powerTypeFilter
				)
			)
		}
		return true
	})
}

/**
 * Filters Locations based on applied filters.
 * @param {OmniLocation} location The Location to be filtered.
 * @param {string[]} subscribedCpoEntities A list of subscribed CPO entity codes.
 * @param {string[]} favouriteLocations A list of favourite location UIDs.
 * @param {LocationFilters} locationFilters The filters to apply on the location.
 * @returns {boolean} A boolean indicating whether the location passes the filters.
 */
export const filterLocationsWithAppliedFilters = (
	location: OmniLocation,
	subscribedCpoEntities: string[] = [],
	favouriteLocations: string[],
	locationFilters: LocationFilters
): boolean => {
	// 1. Check if the location has connectors based on the current power type filter
	if (filterEvsesByPowerType(location.evses, locationFilters.powerType).length === 0) {
		return false
	}
	// 2. Check if the location is matching with the applied CPO
	if (
		locationFilters.cpoEntityCodes !== null &&
		!!location.entity_code &&
		!locationFilters.cpoEntityCodes.includes(location.entity_code)
	) {
		return false
	}
	// 3. Check if the location is matching with a subscribed CPO
	if (locationFilters.subscriptionStatus !== null) {
		const isLocationSubscribed =
			location.entity_code && subscribedCpoEntities.includes(location.entity_code)
				? SubscriptionStatus.Subscribed
				: SubscriptionStatus.NotSubscribed
		if (locationFilters.subscriptionStatus !== isLocationSubscribed) {
			return false
		}
	}
	// 4. Check if the location is a favourite
	if (
		locationFilters.favouritesOnly &&
		location.uid &&
		!favouriteLocations.includes(location.uid)
	) {
		return false
	}
	return true
}
