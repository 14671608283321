import { SVGProps, memo } from 'react'

const CheckCircleOutlinedGradientIcon = ({
	fill = 'none',
	stroke = 'none',
	viewBox = '8 8 64 64',
	xmlns = 'http://www.w3.org/2000/svg',
	gradientFrom = '#FFFFFF',
	gradientTo = '#000000',
	...props
}: SVGProps<SVGSVGElement> & {
	gradientFrom?: string
	gradientTo?: string
}) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70Z"
				stroke="url(#linearGradientA)"
				strokeWidth="4"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M24 43.5L33.5 53L56.5 30"
				stroke="url(#linearGradientB)"
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="linearGradientA"
					x1="-51.2903"
					y1="40"
					x2="70"
					y2="40"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={gradientFrom} />
					<stop offset="1" stopColor={gradientTo} />
				</linearGradient>
				<linearGradient
					id="linearGradientB"
					x1="-9.19893"
					y1="41.5"
					x2="56.5"
					y2="41.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={gradientFrom} />
					<stop offset="1" stopColor={gradientTo} />
				</linearGradient>
			</defs>
		</svg>
	)
}

const MemoisedCheckCircleOutlinedGradientIcon = memo(CheckCircleOutlinedGradientIcon)

export default MemoisedCheckCircleOutlinedGradientIcon
