import { ReactNode, memo } from 'react'
import Skeleton from 'src/_shared/components/Skeleton'
import { OmniConnector, OmniEvse, OmniLocation } from 'src/_shared/types/omni'
import { classNames } from 'src/_shared/utils/elements'

interface ChargingHeaderProps {
	className?: string
	loading?: boolean
	location: OmniLocation | null
	evse: OmniEvse | null
	connector: OmniConnector | null
	/**
	 * Overrides the default image logo render.
	 */
	topRender?: ReactNode
	/**
	 * Primarily used for the Tariff Information `button`.
	 */
	bottomRender?: ReactNode
}

const ChargingHeader = ({
	className,
	loading,
	location,
	evse,
	connector,
	topRender,
	bottomRender
}: ChargingHeaderProps): JSX.Element => {
	const { logo: logoUrl } = location?.operator ?? {}

	const connectorName = ((): string => {
		const connectorReference = connector?.physical_reference
			? `[${connector.physical_reference}] `
			: ''
		// Display the physical reference if available, else fallback to the evse_id.
		if (evse?.physical_reference) {
			return connectorReference + evse.physical_reference
		} else if (evse?.evse_id) {
			return connectorReference + evse.evse_id
		}
		return '-'
	})()

	return (
		<div className={classNames('flex flex-col items-center py-4', className)}>
			<div className="flex w-full flex-col items-center">
				{topRender ? (
					topRender
				) : (
					<Skeleton loading={loading}>
						<img className="mb-2 h-10 min-w-10 max-w-36" src={logoUrl} />
					</Skeleton>
				)}
				<Skeleton className="h-5 w-32" loading={loading}>
					<h1
						data-testid="cs-ch-text-connector-name"
						className="max-w-64 text-center text-typography-primary"
					>
						{connectorName}
					</h1>
				</Skeleton>
			</div>
			{bottomRender}
		</div>
	)
}

const MemoisedChargingHeader = memo(ChargingHeader)

export default MemoisedChargingHeader
