import { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQueryParams } from 'src/App/router/hooks'
import Alert from 'src/_shared/components/Alert'
import Button from 'src/_shared/components/Button'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import { useChargerDetails } from 'src/_shared/hooks/useChargerDetails'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps, ChargerScreenQueryParams } from '../types'
import Footer from './Footer'

type TransientEmailPromptViewProps = ChargerScreenCommonViewProps

const TransientEmailPromptView = ({
	routeParams,
	updateView
}: TransientEmailPromptViewProps): JSX.Element => {
	const [, setQueryParams] = useQueryParams<ChargerScreenQueryParams>()

	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession } = useChargerSessionDetails(routeParams)

	const handleEmailPromptAcceptClick = useCallback((): void => {
		updateView?.(ChargerScreenViewItemKey.TransientEmailView)
	}, [updateView])

	const handleEmailPromptRejectClick = useCallback((): void => {
		setQueryParams((queryParams): URLSearchParams => {
			queryParams.delete('email')
			return queryParams
		})
		updateView?.(ChargerScreenViewItemKey.TransientPaymentMethodsView)
	}, [setQueryParams, updateView])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.TransientEmailPromptView
	})

	return (
		<>
			<div className="flex-grow">
				<p className="body-3-normal mb-5 text-center text-typography-primary">
					<FormattedMessage
						id="TransientEmailPromptView.DescriptionEmailPrompt"
						defaultMessage="Would you like to provide your email address to receive updates on your charging session?"
					/>
				</p>
				<Alert>
					<FormattedMessage
						id="TransientEmailPromptView.InformationDescriptionEmailPrompt"
						defaultMessage="If you choose not to provide your email address, upon starting your charging session please avoid closing this window as you may not be able to stop your charging session afterwards."
					/>
				</Alert>
			</div>
			{/* Footer */}
			<Footer
				buttonProps={{
					children: intl.formatMessage({
						id: 'TransientEmailPromptView.ButtonTextAcceptEmailPrompt',
						defaultMessage: 'Yes, Send Me Updates'
					}),
					className: 'w-full mb-4',
					onClick: handleEmailPromptAcceptClick
				}}
				bottomRender={
					<Button
						className="w-full"
						variant="ghost"
						data-testid=""
						onClick={handleEmailPromptRejectClick}
					>
						<FormattedMessage
							id="TransientEmailPromptView.ButtonTextRejectEmailPrompt"
							defaultMessage="No Thanks"
						/>
					</Button>
				}
			/>
			<PoweredByFooter className="pb-6" />
		</>
	)
}

export default TransientEmailPromptView
