import { useCallback, useMemo, useState } from 'react'
import { useRouteParams } from 'src/App/router/hooks'
import Notice, { NoticeProps } from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import ArrowLeftIcon from 'src/_shared/components/_icons/ArrowLeftIcon'

import ConfirmationView from './components/ConfirmationView'
import PaymentMethodsView from './components/PaymentMethodsView'
import PreReservationView from './components/PreReservationView'
import ReservationView from './components/ReservationView'
import { FormFieldKey, ReservationChargerScreenViewItemKey } from './enums'
import {
	ReservationChargerScreenRouteParams,
	ReservationChargerScreenUpdateViewFunction,
	ReservationChargerScreenViewItem
} from './types'

const DEFAULT_FORM_VALUES: Record<FormFieldKey, number> = {
	[FormFieldKey.Duration]: 0,
	[FormFieldKey.StartTime]: 0
}

const ReservationChargerScreen = (): JSX.Element => {
	const [viewItemKey, setViewItemKey] = useState<ReservationChargerScreenViewItemKey>(
		ReservationChargerScreenViewItemKey.PreReservationView
	)

	const [errorViewProps, setErrorViewProps] = useState<NoticeProps>({})

	const [formValues, setFormValues] = useState<Record<FormFieldKey, number>>(DEFAULT_FORM_VALUES)

	const params = useRouteParams<ReservationChargerScreenRouteParams>()

	const updateView: ReservationChargerScreenUpdateViewFunction = useCallback((...args): void => {
		const nextKey = args[0]
		if (nextKey === ReservationChargerScreenViewItemKey.ErrorView) {
			const nextErrorViewProps = args[1]
			if (nextErrorViewProps) {
				setErrorViewProps(nextErrorViewProps)
			}
		}
		setViewItemKey((previousKey): ReservationChargerScreenViewItemKey => {
			console.debug(`[ReservationChargerScreen] Update View: "${previousKey}" > "${nextKey}"`)
			return nextKey
		})
	}, [])

	const viewItemsMap = useMemo((): Record<
		ReservationChargerScreenViewItemKey,
		ReservationChargerScreenViewItem
	> => {
		return {
			[ReservationChargerScreenViewItemKey.PreReservationView]: {
				view: (
					<PreReservationView
						routeParams={params}
						formValues={formValues}
						setFormValues={setFormValues}
						updateView={updateView}
					/>
				)
			},
			[ReservationChargerScreenViewItemKey.ReservationView]: {
				view: (
					<ReservationView
						routeParams={params}
						formValues={formValues}
						setFormValues={setFormValues}
						updateView={updateView}
						onNext={(): void => {
							setViewItemKey(ReservationChargerScreenViewItemKey.ConfirmationView)
						}}
					/>
				)
			},
			[ReservationChargerScreenViewItemKey.ConfirmationView]: {
				view: (
					<ConfirmationView
						routeParams={params}
						formValues={formValues}
						setFormValues={setFormValues}
						updateView={updateView}
						onNext={(): void => {
							setViewItemKey(ReservationChargerScreenViewItemKey.PaymentMethodsView)
						}}
					/>
				),
				onBack: (): void => {
					setViewItemKey(ReservationChargerScreenViewItemKey.ReservationView)
				}
			},
			[ReservationChargerScreenViewItemKey.PaymentMethodsView]: {
				view: (
					<PaymentMethodsView
						routeParams={params}
						formValues={formValues}
						setFormValues={setFormValues}
						updateView={updateView}
					/>
				),
				onBack: (): void => {
					setViewItemKey(ReservationChargerScreenViewItemKey.ConfirmationView)
				}
			},
			[ReservationChargerScreenViewItemKey.ErrorView]: {
				view: <Notice {...errorViewProps} />,
				onBack: (): void => {
					updateView(ReservationChargerScreenViewItemKey.PreReservationView)
					setErrorViewProps({})
				}
			}
		}
	}, [errorViewProps, formValues, params, updateView])

	const currentViewItem = viewItemsMap[viewItemKey]

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 pt-6'
			}}
			topBarProps={{
				leftRender: currentViewItem.onBack && (
					<TopBarButton data-testid="rcs-btn-back" onClick={currentViewItem.onBack}>
						<ArrowLeftIcon className="h-4" />
					</TopBarButton>
				)
			}}
			hideBottomBar
		>
			{currentViewItem.view}
		</ScreenContainer>
	)
}

export default ReservationChargerScreen
