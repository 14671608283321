import { memo } from 'react'
import { OmniLocation } from 'src/_shared/types/omni'

import Button from '../Button'
import Modal from '../Modal'
import ModalCard from '../Modal/components/ModalCard'
import LetterIcon from '../_icons/LetterIcon'

interface CarparkRatesModalButtonProps {
	className?: string
	location: OmniLocation | null
	open: boolean
	onClose: () => void
}

const CarparkRatesModal = ({
	className,
	location,
	open,
	onClose: handleClose
}: CarparkRatesModalButtonProps): JSX.Element => {
	return (
		<Modal className={className} open={open} onClose={handleClose}>
			<ModalCard>
				<div className="mb-2 flex items-center space-x-1.5">
					<LetterIcon value="P" />
					<h1 className="text-modal-typography-primary">Car Park Rates</h1>
				</div>
				<div className="mb-2">
					<p className="body-2-bold mb-2 text-modal-typography-primary">{location?.name ?? ''}</p>
					<p className="caption-3-normal text-modal block items-center text-modal-typography-primary">
						{location?.address ?? ''}
					</p>
				</div>
				<div className="mb-2 border-b border-divider-primary" />
				{/* TODO: Display Car Park Rates based on `location`; best to have API support for this somehow */}
				<p className="body-2-normal mb-6 text-modal-typography-secondary">
					Car park rates for this location coming soon!
				</p>
				<Button className="w-full" onClick={handleClose}>
					Close
				</Button>
			</ModalCard>
		</Modal>
	)
}

const MemoisedCarparkRatesModal = memo(CarparkRatesModal)

export default MemoisedCarparkRatesModal
