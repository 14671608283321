import { SVGProps, memo } from 'react'

const CalendarIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	strokeWidth = '2',
	viewBox = '0 0 19 21',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			viewBox={viewBox}
			xmlns={xmlns}
			{...props}
		>
			<path
				d="M13 1.75V5.25M6 1.75V5.25M1.625 8.75H17.375M3.375 3.5H15.625C16.5915 3.5 17.375 4.2835 17.375 5.25V17.5C17.375 18.4665 16.5915 19.25 15.625 19.25H3.375C2.4085 19.25 1.625 18.4665 1.625 17.5V5.25C1.625 4.2835 2.4085 3.5 3.375 3.5Z"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const MemoisedCalendarIcon = memo(CalendarIcon)

export default MemoisedCalendarIcon
