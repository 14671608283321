import { memo, useMemo } from 'react'
import Select, { SelectOption, SelectProps } from 'src/_shared/components/Select'
import { OmniCountryCode } from 'src/_shared/enums/omni'

type SelectFavouritesCountryProps<T> = Omit<SelectProps<T>, 'options' | 'ref'>

const SelectFavouritesCountry = <T extends string | number>(
	props: SelectFavouritesCountryProps<T>
): JSX.Element => {
	const options = useMemo((): SelectOption<T>[] | SelectOption<string>[] => {
		const countryOptions = Object.entries(OmniCountryCode).map(
			([key, value]): SelectOption<string> => {
				return {
					label: key,
					dropdownLabel: key,
					value
				}
			}
		)

		// Include "All countries" option
		return [{ label: 'All', dropdownLabel: 'All', value: '' }, ...countryOptions]
	}, [])

	return (
		<Select
			variant="outlined"
			data-testid="fs-select-country"
			{...props}
			options={options}
			mainClassName="w-full"
		/>
	)
}

const MemoisedSelectFavouritesCountry = memo(SelectFavouritesCountry)

export default MemoisedSelectFavouritesCountry
